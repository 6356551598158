import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React from "react";

 function TabsParentComponent({ items, handleChange, value }) {
  return (
    // <Box >
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          sx: {
            display: "none",
            overflow:"auto"
          },
        }}
        allowScrollButtonsMobile
        scrollButtons={true}
        variant="scrollable"
        sx={(theme) => ({
          alignItems : "center",
          "& .Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            padding: "3px",
            borderRadius: "20px",
            color: `${theme.palette.dark.white} !important`,
          },
          "& .MuiTab-root": {
            textTransform: "none",
            fontSize: "13px",
            padding: "6px 12px",
            minHeight: "12px",
            color: theme.palette.primary.main,
          },
          "& .Mui-disabled": {
            color: theme.palette.light.fontGreyLight,
          },
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        })}
      >
        {/* <Tab
          value=""
          label="Select Category"
          disabled
          sx={{
            textTransform: "none",
          }}
        /> */}
        {items.map((item, index) => (
          <Tab key={index} value={item.key} label={item.title} />
        ))}
      </Tabs>
    // </Box>
  );
}

export default React.memo(TabsParentComponent)
