import Lottie from "react-lottie";
import animationData from "../../../lottiesJSON/noData.json";
import { Typography, useTheme } from "@mui/material";

const NoData = ({ height, width }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const theme = useTheme();

  return (
    <>
      <Lottie
        options={defaultOptions}
        height={height || 400}
        width={width || 400}
      />
      <Typography sx={{fontWeight: "800", color: theme.palette.primary.main}}>No Results Found</Typography>
    </>
  );
};

export default NoData;
