import { Box, Dialog, DialogTitle, IconButton } from "@mui/material";
import "../../../css/StepperComponent.css";
import PlInput from "../PlInput";
import { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import { linkedinurlTypes, questionTypes } from "../../../utils/constants";
import { isValidUrl } from "../../../utils/validations";

import {
  addMessageToCurrentList,
  resetCurrentChatList,
  updateIsFetchingInsights,
  updateNewChatActiveStatus,
} from "../../../redux/insights";
import { generateAutomaticMsg } from "../../../services/automatedMessages";
import { updatecalledPostAndCommentsForSeedApi } from "../../../redux/customMessage";
import { extractComment, extractPosts, extractProfile } from "../../../services/extractServices";
import { makePostRestCall } from "../../../services/httpServices";

const AutomatedMsgModal = ({
  open,
  setOpen,
  setLoadingMessage,
  setShowQuestions,
  setShowCustomMessageLoader,
}) => {
  const dispatch = useDispatch();

  const { currentUrl, currentQuestion, currentUrlType } = useSelector(
    (state) => state.insightReducer
  );

  const [inputValues, setInputValues] = useState({});
  const [errors, setErrors] = useState({});
  const [showNextButton, setShowNextButton] = useState(true);


  useEffect(() => {
    const mappedInputValues = {
      "Person Linkedin URL":
        currentUrlType === linkedinurlTypes.PROFILE && currentUrl,
    };
    setInputValues(mappedInputValues);
    return () => {
      setInputValues({});
    };
  }, [currentUrlType, currentUrl]);

  useEffect(() => {
    if (currentUrlType === linkedinurlTypes.PROFILE)
      setInputValues((prev) => ({
        ...prev,
        "Person Linkedin URL": currentUrl,
      }));
  }, [currentUrl]);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setInputValues({});
      setErrors({});
      setOpen(false);
    }
  };

  const validateField = (name, value) => {
    let errorMsg = "";
    if (!value || !isValidUrl(value)) {
      errorMsg = "Invalid URL";
    }
    setErrors((prev) => ({ ...prev, [name]: errorMsg }));
    return errorMsg === "";
  };

  const handleChange = (name, value) => {
    setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
    const isValid = validateField(name, value);

    if (isValid) {
      setShowNextButton(true); // Enable button if valid
    } else {
      setShowNextButton(false); // Disable button if invalid
    }
  };


  const callSeedApiForPeople= async (url) => {
    const response = await makePostRestCall(
      `/v1/seed/extract/people`,
      {
        linkedin_url: url,
      },
      process.env.REACT_APP_API_EXTRACT
    );
  
    if (!response.extracted) {
     const profileResponse = await extractProfile("people", url, response.uid);
      
       await Promise.all([
        extractPosts("people", url, response.uid),
        extractComment("people",url, response.uid)
      ]);
  
      return profileResponse;
    }
    return response;
  };



 const  callSeedApiForCompany= async (url) => {
    const response = await makePostRestCall(
      `/v1/seed/extract/company`,
      {
        linkedin_url: url,
      },
      process.env.REACT_APP_API_EXTRACT
    );
  
    if (!response.extracted) {
     const profileResponse = await extractProfile("company", url, response.uid);
      
       await Promise.all([
        extractPosts("company", url, response.uid),

        // extractComment("company",url, response.uid)
      ]);
  
      return profileResponse;
    }
    return response;
  };



  const callGenerateMessage = async () => {
    initializeState();
    let dataObj = {
      person_uuid: "",
      company_uuid: "",
    };

    dispatch(updatecalledPostAndCommentsForSeedApi(false));
    // dispatch(updatecalledPostAndCommentsForSeedApiCompany(false));

    try {

      const linkedInUrl = inputValues["Person Linkedin URL"];
      const res = await callSeedApiForPeople(linkedInUrl);

      if (!res.uid || !res.company_urls || res?.company_urls?.length === 0) {
        throw new Error();
      }
      dataObj.person_uuid = res.uid;
      const seedApiRes = await callSeedApiForCompany(
        res.company_urls[0].LinkedinURL
      );
      dataObj.company_uuid = seedApiRes?.uid;

      let messageData = {};

      
        messageData = await generateAutomaticMsg(
          currentQuestion.key ===  questionTypes.AUTOMATED_EMAIL ? "email" : "linkedin",
          dataObj
        );
      
      let messages = [];

      if (currentQuestion.key === questionTypes.AUTOMATED_EMAIL) {
        messages = messageData.response.email.map((data) => {
          return {
            question: `${data.subject}`,
            answer: [data.body],
            variant: data?.variant,
            h_id: messageData?.h_id,
          };
        });
      }

      if (currentQuestion.key === questionTypes.AUTOMATED_MESSAGE) {
        messages = messageData.response?.message?.map((data, index) => {
          return {
            question: `Message Template ${index + 1}`,
            answer: [data.message],
            variant: data?.variant,
            h_id: messageData?.h_id,
          };
        });
      }

      dispatch(
        addMessageToCurrentList({
          text: "Please find the information below",
          sender: "bot",
          data: messages,
          type: currentQuestion.key ===  questionTypes.AUTOMATED_EMAIL ? "email" : "linkedin",
        })
      );
    } catch (error) {
      console.log(error, "error in generating messages");
      dispatch(
        addMessageToCurrentList({
          text: "Couldn't generate message",
          sender: "bot",
        })
      );
    } finally {
      dispatch(updateIsFetchingInsights(false));
      setShowQuestions(true);
      setShowCustomMessageLoader(false);
      setLoadingMessage("");
      dispatch(updateNewChatActiveStatus(false));
      localStorage.setItem("isFetching", false);
    }
  };

  const initializeState = () => {
    dispatch(updateIsFetchingInsights(true));
    localStorage.setItem("isFetching", true);

    localStorage.setItem("isActiveUser", true);
    dispatch(resetCurrentChatList());
    setShowQuestions(false);
    setShowCustomMessageLoader(true);
    setLoadingMessage("Fetching details, just a moment...");
    dispatch(
      addMessageToCurrentList({
        // data: messageObject,
        sender: "user",
        type: questionTypes.AUTOMATED_EMAIL ? "email" : "linkedin",
        text: `I want to generate ${
         currentQuestion.key === questionTypes.AUTOMATED_EMAIL ? "an Email" : "a Linkedin Message"
        }`,
        currentMessageType: questionTypes.AUTOMATED_EMAIL
          ? "email"
          : "linkedin",
      })
    );
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: { backgroundColor: "#1F1651", opacity: ".8" },
      }}
      className="stepper-dialog"
      sx={{
        "& .MuiDialog-paper": {
          margin: "-20px 10px 0px 10px",
          width: "100%",
          height: "100%",
          position: "relative",
          background: "transparent",
          overflowY: "hidden",
        },
      }}
    >
      <div className="cross_btn_container">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            background: "transparent",
            zIndex: "1200",
            color: "white",
            marginLeft: "auto",
            padding: 0,
          }}
        >
          <ClearIcon />
        </IconButton>
      </div>

      <div
        className="dialog_content_container"
        style={{ height: "300px", margin: "auto 2rem" }}
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid #dedcdc",
            textTransform: "uppercase",
            color: "#2e2e2e",
            fontWeight: "600",
            fontSize: "15px",
            letterSpacing: "1px",
            textTransform: "none",
          }}
        >
          Generate {currentQuestion?.name}
        </DialogTitle>
        <div className="stepper-box" style={{ justifyContent: "center" }}>
          <PlInput
            name={"Person Linkedin URL"}
            label={"Person Linkedin URL"}
            placeholder={"Person Linkedin URL"}
            type={"text"}
            value={inputValues["Person Linkedin URL"] || ""}
            onChange={(e) =>
              handleChange("Person Linkedin URL", e.target.value)
            }
            required={true}
            showError={true}
            isValid={!errors["Person Linkedin URL"]}
            errorMsg={errors["Person Linkedin URL"]}
          />
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            padding: "10px",
            mb: 4,
          }}
        >
          <PrimaryButton
            disabled={!showNextButton}
            onClick={callGenerateMessage}
          >
            SUBMIT
          </PrimaryButton>
        </Box>
      </div>
    </Dialog>
  );
};

export default AutomatedMsgModal;
