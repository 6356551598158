import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  messages: [],
  inputVales: [],
  currentQuestion: {},
  currentChatList: [],
  currentPersonUid: null,
  currentCompanyUid: null,
  chatHistoryData: {data: [], paginationData: {}},
  selectedHistory: {},
  isNewChatActive: false,
  isFetchingInsights: false,
  currentUrl: null,
  currentUrlType: null
};

export const insightSlice = createSlice({
  name: "insightSlice",
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setInputValues: (state, action) => {
      state.inputVales = action.payload;
    },
    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload;
    },
    addMessageToCurrentList: (state, action) => {
      state.currentChatList.push(action.payload);
    },
    resetCurrentChatList: (state, action) =>{
      state.currentChatList = []
    },
    updateUid: (state, action) => {
      // {type:person || company
      //  uid:uid
      // }
      let type = action.payload.type;
      if (type === "people") {
        state.currentPersonUid = action.payload.uid;
      }
      if (type === "company") {
        state.currentCompanyUid = action.payload.uid;
      }
    },
    updateAnswer: (state, action) => {
      const { questionType, type, key, answer } = action.payload;
      let currentChatList = state.currentChatList;
      let lastMsg = currentChatList[currentChatList.length - 1];
      if (lastMsg) {
        if (lastMsg.sender === "bot" && lastMsg.type === questionType) {
          lastMsg.data.forEach((questionData, index) => {
            if (questionData.type === type && questionData.key === key) {
              lastMsg.data[index].answer = answer;
              lastMsg.data[index].isLoading = false;
            }
          });
        }
      }
      state.currentChatList[state.currentChatList.length - 1] = lastMsg;
    },
    updateChatHistoryPaginationData: (state, action)=>{
      state.chatHistoryData.paginationData = action.payload
    },
    addHistoryData: (state, action)=>{
      state.chatHistoryData.data = [...state.chatHistoryData.data, ...action.payload]
    },
    updateSelectedHistory: (state, action)=>{
      state.selectedHistory = action.payload
    },
    updateNewChatActiveStatus: (state, action)=>{
      state.isNewChatActive = action.payload
    },
    updateIsFetchingInsights: (state, action)=>{
      state.isFetchingInsights = action.payload
    },
    updateCurrentUrl: (state, action)=>{
      state.currentUrl = action.payload
    },
    updateUrlType: (state, action)=>{
      state.currentUrlType = action.payload
    }
  },
});

export const {
  setMessages,
  setInputValues,
  setCurrentQuestion,
  addMessageToCurrentList,
  updateUid,
  updateAnswer,
  resetCurrentChatList,
  updateChatHistoryPaginationData,
  updateSelectedHistory,
  addHistoryData,
  updateNewChatActiveStatus,
  updateIsFetchingInsights,
  updateCurrentUrl,
  updateUrlType
} = insightSlice.actions;

export default insightSlice.reducer;
