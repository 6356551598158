import { Grid } from "@mui/material";
import ClearableSingleSelect from "../../../common/ClearableSingleSelect";
import { useEffect, useState } from "react";

const MultiDropDownSingleSelect = ({ items, dropdownOptions, title ,handleParentDataChange, erroMsg, values}) => {
  
  const [selectedItems, setSelectedItems] = useState(values);

  const onChange= (key) =>(e,newValue)=>{

    setSelectedItems((prev)=>{
      return{
        ...prev,
        [key] : newValue
      }
    })
  }

  useEffect(()=>{
    handleParentDataChange(selectedItems)
  }, [selectedItems])

  return (
    <>
       <div className="flex_title">
      <span className="title_lable">{title}</span>
</div>
      {items.map((data, index) => (
        <Grid
          container
          spacing={2}
          xs={12}
          key={index}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px 0 0 25px",

          }}
        >
          <Grid item xs={3} sx={{ fontSize: "14px", fontWeight: "600", paddingTop: "0!important" }}>
            <span style={{padding: 0, fontWeight: "500"}}> {data}</span>
          </Grid>
          <Grid item xs={9} sx={{marginBottom: "10px"}}>
            <ClearableSingleSelect
              options={dropdownOptions}
              placeholder={"choose Your Option"}
              onChange={onChange(data)}
              value={selectedItems[data]}
            />
          </Grid>
        </Grid>
      ))}
      <span className="error_msg">{erroMsg}</span>

    </>
  );
};

export default MultiDropDownSingleSelect;
