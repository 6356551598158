import React, { memo, useCallback, useEffect, useState } from "react";
import { Autocomplete, Chip, Paper, TextField, styled } from "@mui/material";
import DynamicCheckBox from "../DynamicCheckBox";
import { debounceTimerValue } from "../../../utils/constants";
import api from "../../../services/api";

const AutoCompleteMultiSelect = ({
  placeholder,
  onChange,
  value,
  currentKey
}) => {
  const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    width: "100%",
    borderRadius: "7px",
    border: "1px solid #D0C9D6",
    boxShadow: "0px 1px 9px -5px gray !important",
    padding: "0",
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input ": {
      minWidth: 0,
    },
  }));

  const StyledTextField = styled(TextField)(({ theme }) => ({
    fontSize: "12px",
    "& .MuiInputBase-root": {
      height: "auto",
      fontSize: "12px",
      padding: "4px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      top: 0,
      border: "none",
      boxShadow: "0 7px 64px 0 rgba(0, 0, 0, 0.07)",
    },
  }));

  const CustomPaper = styled(Paper)(({ theme }) => ({
    border: `1px solid ${theme.palette.light.borderColor}`,
    maxHeight: "250px",
    overflowY: "scroll",
    "& .MuiAutocomplete-option": {
      fontSize: "12px",
      padding: "0 10px",
      minHeight: "30px",
    },
  }));

  const [dropdownItems, setDropdownItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchDropdownItems = setTimeout(async () => {
      if (searchQuery) {
        try {
          const res = await api.post("https://api.pintel.ai/apis/v1/data/autocomplete",{
            data: searchQuery,
            data_type: currentKey,
          });
          setDropdownItems(res.data || []);
        } catch (e) {
          console.log(e, "error");
          setDropdownItems([]);
        }
      }
    }, debounceTimerValue);

    return () => {
      clearTimeout(fetchDropdownItems);
    };
  }, [searchQuery]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const res = await api.post("https://api.pintel.ai/apis/v1/data/autocomplete", {
                data: "",
                data_type: currentKey,
            });
            setDropdownItems(res.data || []);
        } catch (e) {
            console.log(e, "error");
            setDropdownItems([]);
        }
    };

    fetchData();
}, []); 


  const handleSearch = async (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSelectOption = (event, newValue) => {
    onChange(event, newValue);
    setSearchQuery("");
  }

  const checkValueSelected = (option) => {
    return value.some((element) => element.data === option.data);
  };
  return (
    <StyledAutocomplete
      multiple
      openOnFocus
      disableCloseOnSelect
      id="size-small-filled-multi"
      size="small"
      options={dropdownItems}
      getOptionLabel={(option) => option.data}
      limitTags={2}
      value={value}
      onChange={handleSelectOption}
      filterOptions={(value)=> value}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              variant="outlined"
              color="primary"
              label={
                option.data.length <= 12
                  ? option.data
                  : option.data.slice(0, 9) + "..."
              }
              size="small"
              {...tagProps}
              sx={{
                fontSize: "10px",
                color: "black",
              }}
            />
          );
        })
      }
      inputValue={searchQuery}
      PaperComponent={(props) => <CustomPaper {...props} />}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
         <DynamicCheckBox
         label={option.data}
         checked={checkValueSelected(option)}
         />
        </li>
      )}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          placeholder={placeholder}
          onChange={handleSearch}
          autoFocus={searchQuery ? true: false}
        />
      )}
    />
  );
};

export default memo(AutoCompleteMultiSelect);
