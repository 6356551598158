import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import "../css/Accordian.css";
import { CircularProgress } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useSelector } from "react-redux";
import CopyIcon from "../assets/icons/copy.svg";
import { sendCopyMessageEventData } from "../utils/eventHandler/eventHandler";
import {copyfunction} from "../utils/eventHandler/copyEvent";

const AccordianComponent = ({ faqData }) => {
  const [expandedId, setExpandedId] = useState(null);
  const [tooltipText, setTooltipText] = useState("Copy");
  const toggleExpansion = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };
  const metaData = useSelector((state) => state.questionReducer.metaData);
  const questions = useSelector((state) => state.questionReducer.questions);

  // Helper function to determine and format data based on its structure
  const formatFaqData = () => {
    if (!faqData || !Array.isArray(faqData)) {
      return [];
    }

    let formattedData = [];
    faqData.map((item) => {
      const showCustomMessage =
        metaData.custom_internal_fields &&
        metaData.custom_internal_fields[item.type]?.includes(item.key);
      if ("question" in item && "answer" in item) {
        formattedData.push({
          questions: item.question,
          answer: item.answer,
          isLoading: item.isLoading,
          key: item.key,
          type: item.type,
          showCustomMessage: showCustomMessage,
          custom_message: showCustomMessage ? metaData.custom_message : "",
          ...item
        });
      } else {
        // Assuming the structure is for email variants
        Object.keys(item).forEach((variantKey) => {
          const variant = item[variantKey];
          const markdownText = `${variant.body} \n\n **Reasoning**:\n    ${variant.reasoning}`;
          formattedData.push({
            subject: variant.subject,
            body: markdownText.replace(/\n/g, "<br />"),
            ...item
          });
        });
      }
    });
    return formattedData;
  };

  const copyToClipboard = (data) => {
    let content = "";
    if (data.subject) {
      content = `${data.subject}
      ${data.body}`;
    }
    if (data.questions) {
      content = `${data.questions}
      ${data.answer}`;
    }

    const questionData = {
     variant: data?.variant,
      h_id: data?.h_id
    }
    
    sendCopyMessageEventData(questionData)

    copyfunction(setTooltipText, content)
  
  };

  const formattedFaqData = formatFaqData();
  function LinkRenderer(props) {
    return (
      <a href={props.href} target="_blank">
        {props.children}
      </a>
    );
  }

  const handleOnCopy = (data) =>{
    
    const questionData = {
      variant: data?.variant,
       h_id: data?.h_id
     }
     
     sendCopyMessageEventData(questionData)
  }

  return (
    <>
      <div className="faq-container">
        {formattedFaqData.map(
          (item, index) =>
            item && ( // Check if item is defined
              <div
                key={index}
                className={`question-container ${
                  expandedId === index ? "expanded" : ""
                }`}
              >
                <div
                  className="question"
                  onClick={() => toggleExpansion(index)}
                >
                  {item.subject ? (
                    `Subject: ${item.subject}`
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CircleIcon
                        style={{
                          color: item.isLoading ? "#d9dded" : "#7771fd",
                          fontSize: "12px",
                          marginRight: "10px",
                        }}
                      />
                      <span style={{ marginRight: "auto" }}>
                        {item.questions}
                      </span>
                      {item.showCustomMessage && (
                        <div
                          className="custom_message_block"
                          style={{ alignSelf: "flex-start" }}
                        >
                          {item.custom_message}
                        </div>
                      )}
                    </div>
                  )}

                  <span className="question-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </div>
                <div
                  className="answer-container"
                  style={{ maxHeight: expandedId === index ? "100%" : "0" }}
                >
                  {item.body ? (
                    <div className="answer" onCopy={()=>handleOnCopy(item)}>
                      <ReactMarkdown
                        children={item.body}
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[remarkGfm]}
                        components={{ a: LinkRenderer }}
                      />
                    </div>
                  ) : item.isLoading ? (
                    <>
                      Fetching details, just a moment…
                      <CircularProgress
                        size="10px"
                        style={{ marginLeft: "10px" }}
                      />
                    </>
                  ) : item.answer && item.answer.length ? (
                    item.answer.map((answer, ansIndex) => (
                      <div key={ansIndex} className="answer" onCopy={()=>handleOnCopy(item)}>
                        <ReactMarkdown
                          children={answer}
                          rehypePlugins={[rehypeRaw]}
                          remarkPlugins={[remarkGfm]}
                          components={{ a: LinkRenderer }}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="no-data-message">No Data Available</div>
                  )}

                  {!item.isLoading && (item.answer || item.subject) && (
                    <div className="copy-icon-container">
                      <div className="copy_icon">
                      <img
                        src={CopyIcon}
                        alt="copy"
                        onClick={() => copyToClipboard(item)}
                        height={18}
                      />
                      <span className="tooltip">{tooltipText}</span>
                      </div>
                    
                    </div>
                  )}
                </div>
              </div>
            )
        )}
      </div>
    </>
  );
};

export default AccordianComponent;
