import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    styled,
  } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const ButtonComponent = styled(LoadingButton)(({ theme }) => ({
   "&": {
    backgroundColor: theme.palette.light.white,
    color: theme.palette.light.primary,
    minWidth: "100px",
    borderRadius: "5px",
    textTransform: "capitalize",
   },
   "&:hover":{
    backgroundColor: theme.palette.light.primary100,
   },
   "&:disabled":{
    backgroundColor: theme.palette.light.disableColor,
    color: theme.palette.dark.white,
    cursor: "not-allowed"
   }
  }));


function WhiteButton(props) {
  return (
   <ButtonComponent disabled={props.disabled} onClick={props.onClick} loading={props.loading} sx={props.sx}>
        {props.children}
  </ButtonComponent>   
  );
}

WhiteButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default WhiteButton;
