import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { TextField, MenuItem } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  plTextField: {
    background: "#fff",
    borderRadius: "6px",
    marginTop: "20px!important",
    marginBottom:"20px !important",
    width: "100%",
    borderRadius: '7px',
    border: "1px solid #D0C9D6",
    boxShadow: '0px 1px 9px -5px gray !important',
    "& .MuiInputLabel-root": {
      left: "-9px",
      top: "-15px",
      color: "black",
      // fontWeight: 600,
      fontSize:'16px'
    },
    "& .MuiFormHelperText-root":{
        fontSize:'0.6rem !important',
        marginLeft:'10px'
    },
    "& .MuiInputBase-input": {
      height: "auto",
      padding: "8px",
      fontSize:"12px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      top: 0,
      border: "none",
      boxShadow: "0 7px 64px 0 rgba(0, 0, 0, 0.07)",
    },
    "& legend": {
      display: "none",
    },
    "& input::placeholder": {
      fontSize: "12px",
      // fontWeight: 600,
      color: theme.palette.light.gray,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
  },
  '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
  },
  '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
  }
  },
  disabled: {
    opacity: 0.5, 
    pointerEvents: "none", 
  },
}));

function PlInput(props) {
  const classes = useStyles();
  const inputProps = {};
  if (props.maxLength) {
    inputProps.maxLength = props.maxLength;
  }
  if(props.max){
inputProps.max = props.max
  }
  if(props.min){
    inputProps.min = props.min
  }
  return (
    <TextField
      type={props.type ? props.type : "text"}
      className={`${classes.plTextField} ${props.disabled ? classes.disabled : ''}`} 
      id={props.id}
      name={props.id}
      label={props.label}
      variant={props.variant ? props.variant : "outlined"}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: props.icon,
      }}
      placeholder={props.placeholder}
      select={props.isSelect}
      sx={{
        boxShadow: props.showShadow ? "0 2px 10px 0 #c1c8ff" : "none",
        border: props.showBorder ? "1px solid #8097B1" : "none",
        height: props.height ? props.height : "none",
        width: props.width ? props.width : "100%",
        ...props.sx,
      }}
      value={props.value}
      onChange={props.onChange}
      required={props.required}
      error={props.required && !props.isValid && props.showError}
      helperText={
        props.required && !props.isValid && props.showError
          ? props.errorMsg
          : ""
      }
      multiline={props.multiline}
      rows={props.rows}
      maxRows={props.maxRows}
      disabled={props.disabled}
      SelectProps={{
        displayEmpty: false,
      }}
      defaultValue={props.defaultValue}
      inputProps={inputProps}
    >
      {props.isSelect &&
        props.list &&
        props.list.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );
}

PlInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  errorMsg: PropTypes.string,
  required: PropTypes.bool,
  isValid: PropTypes.bool,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.any,
  isSelect: PropTypes.bool,
  list: PropTypes.array,
  showShadow: PropTypes.bool,
  showBorder: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  maxRows: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.any,
  width: PropTypes.any,
  maxLength: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number
};

export default PlInput;
