import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questions: [],
  metaData: {},  
};

export const questionSlice = createSlice({
  name: "questionSlice",
  initialState,
  reducers: {
    setQuestions: (state,action) => {
    state.questions = action.payload;
    },
    setMetaData: (state,action) => {
    state.metaData = action.payload;
    },
  },
});

export const { setQuestions, setMetaData } = questionSlice.actions;

export default questionSlice.reducer;