import { Box, Grid, Typography } from "@mui/material";
import CustomInput from "../../common/CustomInput";
import TabsParentComponent from "../../Tabs/TabParentComponent";
import MultiSelect from "../../common/MultiSelect";
import { memo, useEffect, useState } from "react";
import SearchIcon from "../../../assets/search.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  updateInsightsData,
  updateInsightsDataScrollHeight,
  updateInsightsSearch,
  updateStep2SelectedTab,
} from "../../../redux/customMessage";
import ErrorAlert from "../../common/Alert/ErrorAlert";
import ComingSoon from "../../common/LottieAnimations/ComingSoon";

const InsightsData = ({ sequence, insights, maxLimit }) => {
  // const newSequence = [
  //   {
  //     title: "All",
  //     value: "All",
  //    },
  //   ...sequence,
  // ];
  // const [searchInput, setSearchInput] = useState("");
  const newSequence = sequence.filter(
    (item) => item.key !== "similar_customers" || insights[item.key].length > 0
  );

  const step2SelectedTab = useSelector(
    (state) => state.customMessageReducer.step2SelectedTab
  );

  const [selectedItems, setSelectedItems] = useState([]);
  const [showAlert, setShowAlert] = useState(false)

  const { formData, insightsDataScrollHeight, insightsSearch } = useSelector(
    (state) => state.customMessageReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const element = document.getElementById("insightsDiv");
    if (element) {
      element.scrollTop = insightsDataScrollHeight;
    }
  }, []);

  // useEffect(() => {
  //   const initialSelectedItems = [];
  //   console.log(formData.insights, "formData.insights");
  //   for (const key in formData.insights) {
  //     if (Array.isArray(formData.insights[key])) {
  //       initialSelectedItems.push(...formData.insights[key]);
  //     }
  //   }
  //   setSelectedItems(initialSelectedItems);
  //   console.log(initialSelectedItems, "initialSelectedItems");
  // }, []);

  useEffect(() => {
    const initialSelectedItems = [];
    for (const key in formData.insights) {
      if (Array.isArray(formData.insights[key])) {
        initialSelectedItems.push(...formData.insights[key]);
      }
    }
    setSelectedItems(initialSelectedItems);
  }, []);

  const handleSearch = (e) => {
    // setSearchInput(e.target.value);
    dispatch(updateInsightsSearch(e.target.value));
  };

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    dispatch(updateStep2SelectedTab(newValue));
  };

  const returnItems = () => {
    if (step2SelectedTab === "posts") {
      return insightsSearch
        ? insights.posts?.filter((item) =>
            item?.post_text
              ?.toLowerCase()
              .includes(insightsSearch.toLowerCase())
          )
        : insights.posts;
    }
    if (step2SelectedTab === "case_study") {
      return insightsSearch
        ? {
            ...insights[step2SelectedTab],
            data: insights[step2SelectedTab].data?.filter((item) =>
              item.title?.toLowerCase().includes(insightsSearch.toLowerCase())
            ),
          }
        : insights[step2SelectedTab];
    }
    return insightsSearch
      ? insights?.[step2SelectedTab]?.filter((item) =>
          item.title?.toLowerCase().includes(insightsSearch.toLowerCase())
        )
      : insights[step2SelectedTab];
  };

  const handleMultiSelectChange = (data) => {
    const updatedSelection = selectedItems.includes(data)
      ? selectedItems.filter((item) => item !== data)
      : [...selectedItems, data];

    if (updatedSelection.length < maxLimit + 1) {
      setSelectedItems(updatedSelection);
      const currentType = data.type ? data.type : "person_posts";
      dispatch(updateInsightsData({ key: currentType, data: data }));
    } else {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
  };

  const showComingSoon = () => {
    if (step2SelectedTab === "news" || step2SelectedTab === "annual_reports") {
      return true;
    }
    return false;
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12}>
          <CustomInput
            id="searchInsights"
            name="searchInsights"
            placeholder="Search AI generated insights"
            value={insightsSearch}
            onChange={handleSearch}
            icon={SearchIcon}
          />
        </Grid>
        <Grid item xs={12}>
          <TabsParentComponent
            items={newSequence}
            handleChange={handleChange}
            value={step2SelectedTab}
          />

          <div
            style={{ maxHeight: "300px", overflow: "auto" }}
            id="insightsDiv"
            onScroll={(e) =>
              dispatch(updateInsightsDataScrollHeight(e.target.scrollTop))
            }
          >
            {showComingSoon() ? (
            <ComingSoon height={200} width={200}/>
            ) : (
              <MultiSelect
                handleChange={handleMultiSelectChange}
                items={returnItems()}
                // showMandateSwitch={true}
                selectedItems={selectedItems}
              />
            )}
          </div>
        </Grid>
      </Grid>
      {showAlert && (
       <ErrorAlert errorMsg={"You have exceeded the selection limit of 3 items"}/>
      )}
    </>
    // {step2SelectedTab == "All" && (
    //   <Box sx={{ maxHeight: "220px", overflow: "auto" }}>
    //     {Object.keys(insights).map((key) => (
    //       <>
    //         {/* <Typography>{key}</Typography> */}

    //         <MultiSelect
    //           handleChange={handleMultiSelectChange}
    //           items={insights[key]}
    //           showMandateSwitch={true}
    //           selectedItems={selectedItems}
    //         />
    //       </>
    //     ))}
    //   </Box>
    // )}
  );
};

export default memo(InsightsData);
