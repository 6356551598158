import { updatecalledPostAndCommentsForSeedApi,  } from "../redux/customMessage";
import { linkedinurlTypes } from "../utils/constants";
import { makePostRestCall } from "./httpServices";
import {store} from "../redux/store";
/**
 * Extracts LinkedIn data based on the specified type and URL.
 *
 * @param {string} type - The type of LinkedIn entity ("person" or "company").
 * @param {string} linkedInUrl - The LinkedIn URL to extract data from.
 * @returns {Promise<any>} - A promise that resolves with the response data.
 *
 */
export const extractLinkedInUID = async (type, url) => {
   const res = await makePostRestCall(`/v1/${type}/linkedin/extract`, { linkedin_url: url });
return res.uid
};

export const extractCompanyUrls = async (url)=>{
  return makePostRestCall("/v1/people/linkedin/extract",{linkedin_url: url})
}

export const summarizeWeb = async (uid) => {
  return makePostRestCall("/v1/summarize/web", { uid: uid });
};

export const fetchCompanyWinStories = async (uid, competition) => {
  return makePostRestCall("/v1/competition/winstory", {
    uid: uid,
    competition: competition,
  });
};

export const fetchSimilarCustomer = async (uid) => {
  return makePostRestCall("/v1/similar/customers", { uid: uid });
};

export const callSeedApiForPeople= async (url) => {
  const response = await makePostRestCall(
    `/v1/seed/extract/people`,
    {
      linkedin_url: url,
    },
    process.env.REACT_APP_API_EXTRACT
  );

  if (!response.extracted) {
   const profileResponse = await extractProfile("people", url, response.uid);
    
     Promise.all([
      extractPosts("people", url, response.uid),
      extractComment("people",url, response.uid)
    ]);
      store.dispatch(updatecalledPostAndCommentsForSeedApi(true))

    return profileResponse;
  }
  store.dispatch(updatecalledPostAndCommentsForSeedApi(true))
  return response;
};

export const callSeedApiForCompany= async (url) => {
  const response = await makePostRestCall(
    `/v1/seed/extract/company`,
    {
      linkedin_url: url,
    },
    process.env.REACT_APP_API_EXTRACT
  );

  if (!response.extracted) {
   const profileResponse = await extractProfile("company", url, response.uid);
    
     Promise.all([
      extractPosts("company", url, response.uid),
      //extractComment("company",url, response.uid)
    ]);
    store.dispatch(updatecalledPostAndCommentsForSeedApi(true))

    return profileResponse;
  }
  store.dispatch(updatecalledPostAndCommentsForSeedApi(true))
  return response;
};

export const extractProfile = async (type, url, uid) => {
  const response = await makePostRestCall(
    `/v1/extract/${type}/profile`,
    {
      linkedin_url: url,
      uid: uid,
    },
    process.env.REACT_APP_API_EXTRACT

  );
  return response;
};

export const extractPosts = async (type, url, uid) => {
  const response = await makePostRestCall(
    `/v1/extract/${type}/posts`,
    {
      linkedin_url: url,
      uid: uid,
    },
    process.env.REACT_APP_API_EXTRACT

  );
  if (type === linkedinurlTypes.PEOPLE) {
    return response?.company_urls || [];
  }
};

export const extractComment = async (type, url, uid) => {
  const response = await makePostRestCall(
    `/v1/extract/${type}/comments`,
    {
      linkedin_url: url,
      uid: uid,
    },
    process.env.REACT_APP_API_EXTRACT

  );
  if (type === linkedinurlTypes.PEOPLE) {
    return response?.company_urls || [];
  }
};