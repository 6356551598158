import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import { loaderLabels } from "./constants";
import DotPulseLoader from "../DotPulseLoader/DotPulseLoader";

export default function CustomMessageLoader() {
  const [steps, setSteps] = React.useState([
    { label: "Getting user profile data" },
  ]);

  const [activeStep, setActiveStep] = React.useState(0);

  React.useEffect(() => {
    const stepTimer = setInterval(() => {
      if (activeStep === loaderLabels.length) {
        clearInterval(stepTimer);
        return;
      }
      setSteps((prevSteps) => {
        const nextStep = { label: loaderLabels[prevSteps.length - 1] };
        return [...prevSteps, nextStep];
      });

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, 5000);

    return () => clearInterval(stepTimer);
  }, [activeStep]);

  const CustomStepper = styled(Stepper)(({ theme }) => ({
    "& .MuiSvgIcon-root": {
      color: "#0AB011",
      transition: "opacity 1s ease-in-out",
    },
    "& .MuiStepLabel-root": {
      padding: 0,
    },
    "& .MuiStepConnector-line, & .MuiStepConnector-root.Mui-completed .MuiStepConnector-line":
      {
        borderLeft: "2px dashed #0AB011",
        transition: "2s ease-in",
        height: "5px",
      },
    "& .MuiStepConnector-root.Mui-disabled .MuiStepConnector-line, .MuiStepConnector-line":
      {
        borderLeft: "2px dashed grey",
      },
    "& .MuiStepLabel-label.Mui-active": {
      color: "#979797 !important",
    },
  }));

  return (
    <Box sx={{ maxWidth: 400, margin: "5px" }}>
      <CustomStepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              {step.label} {activeStep === index && <DotPulseLoader />}
            </StepLabel>
          </Step>
        ))}
      </CustomStepper>
    </Box>
  );
}
