import React, { useState } from "react";
import "../css/Sidebar.css";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  useTheme,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import {
  addHistoryData,
  updateChatHistoryPaginationData,
  updateNewChatActiveStatus,
  updateSelectedHistory,
} from "../redux/insights";
import { logout } from "../services/auth";
import { getInsightHistory } from "../services/insightServices";
import { tagTypes } from "../utils/constants";
import WhiteButton from "./common/Buttons/WhiteButton";
import Logout from "../assets/icons/logout.svg";
import ExitDialog from "./common/Dialogs/ExitDialog";

const Sidebar = ({ toggleSidebar, isOpen }) => {
  const { chatHistoryData, isFetchingInsights } = useSelector(
    (state) => state.insightReducer
  );
  const dispatch = useDispatch();

  const [historyLoader, setHistoryLoader] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLoadNextHistory = async () => {
    if (chatHistoryData.paginationData.next_url) {
      try {
        const response = await getInsightHistory(
          chatHistoryData.paginationData.next_url
        );
        dispatch(addHistoryData(response.data));
        dispatch(updateChatHistoryPaginationData(response.page));
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          logout();
        }
        console.log("Error fetching history data:", error);
      } finally {
        setHistoryLoader(false);
      }
    }
  };

  const initiateNewChat = () => {
    dispatch(updateNewChatActiveStatus(true));
    toggleSidebar();
  };

  const formatTitle = (tag, url, companyUrl) => {
    const namePart = url?.split("/");
    // const companyNamePart = companyUrl?.split("/");
    let title = tag; // Default to the raw tag if no specific formatting is needed
    if (namePart && namePart.length > 4) {
      if (tag === tagTypes.PEOPLE_COMPANY_INSIGHTS) {
        title = `Persona & Company Insights of ${namePart[4]
          .replace(/-/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())}`;
      } else if (tag === tagTypes.PEOPLE_INSIGHTS) {
        title = `Profile Insights of ${namePart[4]
          .replace(/-/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())}`;
      } else if (tag === tagTypes.COMPANY_INSIGHTS) {
        title = `Company Insights for ${namePart[4]
          .replace(/-/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())}`;
      } else if (tag === tagTypes.SIMILAR_CUSTOMERS) {
        title = `Similar Customers for ${namePart[4]
          .replace(/-/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())}`;
      } else if (tag === tagTypes.WIN_STORY) {
        title = `Win Story Insights for ${namePart[4]
          .replace(/-/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())}`;
      } else if (tag === tagTypes.POST_EVENT_EMAIL) {
        title = "Post Event Email";
      }
    } else if (tag === tagTypes.EMAIL) {
      title = "Email Prompt";
    } else if (tag === tagTypes.LinkedIn) {
      title = "Linkedin Message";
    } else if (tag === tagTypes.POST_EVENT_EMAIL) {
      title = "Post Event Email";
    } 
    else if (tag === tagTypes.ON_DEMAND) {
      title = "On Demand Insights";
    } 
    else {
      title = "Insight for ...";
    }

    if (title.length > 30) {
      title = title?.substring(0, 30) + "..."; // Truncate long titles
    }

    return title;
  };

  const handleViewHistory = (history) => {
    dispatch(updateSelectedHistory(history));
    toggleSidebar();
  };

  const handleClose = (event, reason)=>{
    if (reason !== "backdropClick") {
      setShowLogoutModal(false)
    }
  }
  return (
    <>
    <ExitDialog open={showLogoutModal} handleClose={handleClose} />
      <Backdrop
        sx={{
          zIndex: 100,
          backgroundColor: "#1F1651",
        }}
        style={{ opacity: ".8" }}
        open={isOpen}
      />
      <div className={`sidebar ${isOpen ? "open" : ""}`} id="scrollableDiv">
        <> 
          <IconButton
            aria-label="close"
            onClick={toggleSidebar}
            sx={{
              background: "transparent",
              marginLeft: "auto",
            }}
          >
            <ClearIcon />
          </IconButton>
          <p className="chat_head">Previous Insights</p>
          <div
            className="menu_check"
            id="menu_check"
            style={{ overflowY: "hidden", height: "64vh" }}
          >
            <InfiniteScroll
              dataLength={chatHistoryData.data.length} // This is important field to render the next data
              next={handleLoadNextHistory}
              hasMore={chatHistoryData.next_url !== null} // Check if nextUrl is present
              loader={
                historyLoader && (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress size="30px" />
                  </div>
                )
              }
              scrollableTarget="menu"
              scrollThreshold={0.9}
              endMessage={<p>No more data to load.</p>}
            >
              <ul className="menu" id="menu">
                {chatHistoryData.data?.map((item, index) => (
                  <li key={index} onClick={() => handleViewHistory(item)}>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      {formatTitle(item.tag, item.l_url, item.c_url)}
                    </a>
                  </li>
                ))}
              </ul>
            </InfiniteScroll>
          </div>
          <div className="sidebar_button_container">
            <Box sx={{ width: "100%" }}>
              <WhiteButton
                width={"100%"}
                sx={{
                  width: "100%",
                  justifyContent: "flex-start",
                  gap: "10px",
                  padding: "8px",
                 
                }}
                onClick={()=>setShowLogoutModal(true)}
              >
                <img src={Logout} />
                Logout
              </WhiteButton>
            </Box>
          </div>
        </>
      </div>
    </>
  );
};

export default Sidebar;
