import React, { useEffect, useState, useMemo } from "react";
import "./App.css";
import PintelChat from "./PintelChat";
import TopNav from "./components/TopNav";
import LogIn from "./components/Login";
import { useLocation } from "react-router-dom";
import { isAuth, logout } from "./services/auth";
import {
  addHistoryData,
  updateChatHistoryPaginationData,
  updateCurrentUrl,
  updateUrlType,
} from "./redux/insights";
import { useDispatch, useSelector } from "react-redux";
import { getInsightHistory } from "./services/insightServices";
import { linkedinurlTypes } from "./utils/constants";
import { updateFormData } from "./redux/customMessage";
import mixpanel from "mixpanel-browser";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  const dispatch = useDispatch();
  const isToken = localStorage.getItem("pintel_access_token");
  const query = useQuery();
  const [isLoggedIn, setIsLoggedIn] = useState(isToken ? true : false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [type, setType] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isNewChatActive, setIsNewChatActive] = useState(false);
  const [loadingInitialHistory, setLoadingInitialHistory] = useState(false);
  const [historyLoader, setHistoryLoader] = useState(false);
  const [isActiveUser, setIsActiveUser] = useState(
    localStorage.getItem("isActiveUser")
      ? localStorage.getItem("isActiveUser")
      : false
  );

  const extractCompanyUrl = (linkedinUrl) => {
    const pattern =
      /^(https:\/\/www\.linkedin\.com\/(?:company|in|school)\/[^/]+\/).*/;
    const match = linkedinUrl.match(pattern);
    if (match) {
      return match[1];
    } else {
      return linkedinUrl;
    }
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data.url) {
        setCurrentUrl(extractCompanyUrl(decodeURIComponent(event.data.url)));
        dispatch(updateCurrentUrl(event.data.url));
      }
      if (event.data.type) {
        setType(event.data.type);
        dispatch(updateUrlType(event.data.type));
      }
      if (event.data.type === linkedinurlTypes.PROFILE) {
        dispatch(
          updateFormData({ key: "person_linkedin_url", value: event.data.url })
        );
      }
    });
    window.top.postMessage("getParentURL", "*");
  });

  useEffect(() => {
    if (!isAuth() && isLoggedIn) {
      logout();
      toast.error(
        "Please re-login to ensure security",
        "Error",
        3000
      );
    }
    setIsLoggedIn(isToken ? true : false);
  }, [isNewChatActive]);

  const fetchInitialHistoryData = async () => {
    if (!isLoggedIn) return;
    setLoadingInitialHistory(true);
    try {
      const response = await getInsightHistory();
      if (response?.data) {
        dispatch(addHistoryData(response.data));
        dispatch(updateChatHistoryPaginationData(response.page));
        // dispatch(updateSelectedHistory(response.data[0]));
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        console.log("logout condition is true");
        logout();
      }
      console.error("Error fetching history data:", error);
    } finally {
      setLoadingInitialHistory(false);
    }
  };

  useEffect(() => {
    fetchInitialHistoryData();
  }, [isLoggedIn]);

  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  }, []);

  const questions = useSelector((state) => state.questionReducer.questions);


  return (
    <div className="App_container" id="pintel_app_container">
    <div className="App" >
      {/* {
        isLoggedIn && 
        <>
        <Header/>
        <Home/>
        </>
      } */}
    	<ToastContainer
				position="top-left"
				autoClose={4000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
				stacked
        style={{
          width: "80%",
          margin: "60px 0 0 60px",
//           background: "#FFBABA",
// borderRadius: "5px",
boxShadow: "none"
        }}
			/>
      {isLoggedIn ? (
        <>
          {/* {loadingInitialHistory ? (
            <CircularProgress className="loader" size="30px" />
          ) : ( */}
            <>
            {questions.length >0 &&  <div className="navBorder">
                <TopNav
                  isOpen={isOpen}
                  historyData={historyData}
                  isLoading={isLoading}
                  toggleSidebar={() => setIsOpen(!isOpen)}
                  removeSidebar={() => setIsOpen(false)}
                  historyLoader={historyLoader}
                />

              </div>}
              <PintelChat
                setIsLoggedIn={setIsLoggedIn}
                isNewChatActive={isNewChatActive}
                setIsNewChatActive={setIsNewChatActive}
                currentUrl={currentUrl}
                urlType={type}
                historyData={historyData}
                setHistoryData={setHistoryData}
                setIsLoading={setIsLoading}
                isActiveUser={isActiveUser}
                setIsActiveUser={setIsActiveUser}
                setHistoryLoader={setHistoryLoader}
                isSidebarOpen={isOpen}
              />
            </>
          {/* )} */}
        </>
      ) : (
        <LogIn
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setType={setType}
          setCurrentUrl={setCurrentUrl}
          setLoadingUrl={setLoadingInitialHistory}
        />
      )}
    </div>
   </div>
  );
}

export default App;
