// export const dataObj = [
//   {
//     title: "Create personalised message",
//     messageTypes: [
//       { name: "Message Builder" },
//       { name: "Personalised Triggers" },
//       { name: "Personalised Triggers" },
//     ],
//   },
//   {
//     title: "To find high intent prospect and accounts",
//     messageTypes: [{ name: "Find Companies" }],
//   },
//   {
//     title: "For call preparation and on-call talking points",
//     messageTypes: [{ name: "Company Insights", name :"Persona Insights" }],
//   },
// ];

export const lambdatestBtns = [
  {
    name: "Automated Email",
    outcome: "Automated Email",
    key: "automated_email",
  },
  {
    name: "Automated Linkedin Message",
    outcome: "Automated Linkedin Message",
    key: "automated_message",
  },
];
