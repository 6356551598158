import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API || "http://localhost:3333",
  // baseURL:  "https://api.pintel.ai/ext",
});

api.interceptors.request.use(async (config) => {
  const token = getToken() ;
  const configuration = config;
  if (token) {
    configuration.headers.Authorization = token;
  }
  return configuration;
});

axios.interceptors.response.use(
  (response) => {
    // const loading = false;
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
