import React, { useEffect, useState } from "react";
import "./css/Chat.css";

import { Grid, Snackbar } from "@mui/material";
import ChatFeedback from "./components/ChatFeedback";
import api from "./services/api";

import InsightsForm from "./components/InsightsForm";
import { v4 as uuidv4 } from "uuid";
import { logout } from "./services/auth";
import { useDispatch, useSelector } from "react-redux";
import { setMetaData, setQuestions } from "./redux/questionSlice";
import AccordianContainer from "./components/AcccordionContainer";
import { extractLinkedInUID, summarizeWeb } from "./services/extractServices";
import { questionTypes, tagTypes } from "./utils/constants";
import { fetchNewInsight } from "./services/insightServices";
import {
  addMessageToCurrentList,
  resetCurrentChatList,
  setCurrentQuestion,
  updateAnswer,
  updateIsFetchingInsights,
  updateNewChatActiveStatus,
  updateUid,
} from "./redux/insights";
import { formatTagtype } from "./utils/util";
import EmailBuilderModal from "./components/EmailBuilderStepper/EmailBuilderModal";
import { setScreens } from "./redux/customMessage";
import OnDemandInsights from "./components/OnDemandInsights";
import { resetOnDemandState, updateStep1Data } from "./redux/onDemand";
import Home from "./components/chat/home/home";
import PintelRoundLogo from "./assets/logo_round.svg";
import { toast } from "react-toastify";
import AutomatedMsgModal from "./components/common/AutomatedMessages/AutomatedMsgModal";

const PintelChat = ({
  setIsLoggedIn,
  currentUrl,
  urlType,
  isActiveUser,
  setIsActiveUser,
}) => {
  const [open, setOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState();
  const [snacbarMsg, setSnackbarMsg] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showQuestions, setShowQuestions] = useState(true);
  const [showLoader, setLoader] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [insightType, setInsightType] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isPeoplCompanyInsights, setIsPeopleCompanyInsights] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [onDemandInsightsOpen, setOnDemandInsightsOpen] = useState(false);
  const [showCustomMessageLoader, setShowCustomMessageLoader] = useState(false);
  const [showAutomatedMsgModal, setShowAutomatedMsgModal] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();
  const questions = useSelector((state) => state.questionReducer.questions);
  const metaData = useSelector((state) => state.questionReducer.metaData);

  const {
    currentQuestion,
    currentChatList,
    currentPersonUid,
    currentCompanyUid,
    chatHistoryData,
    selectedHistory,
    isNewChatActive,
    isFetchingInsights,
  } = useSelector((state) => state.insightReducer);

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const openFeedback = (e, type) => {
    e.preventDefault();
    setOpen(true);
    setFeedbackType(type);
  };

  const closeFeedback = () => {
    setOpen(false);
    setFeedbackType(null);
  };

  const submitFeedback = (data) => {
    // e.preventDefault();
    closeFeedback();
    setLoader(true);
    api
      .post(`/v1/insights/feedback`, { ...data, h_id: selectedHistory.h_id })
      .then(function (res) {
        setLoader(false);
        setSnackbarMsg("Submitted Successfully");
        setShowSnackbar(true);
      })
      .catch(function (error) {
        handleFetchError(error);
        setLoader(false);
      });
  };

  const handleDialogOpen = (question) => {
    
    window.top.postMessage("getParentURL", "*");

    const appContainer = document.getElementById("pintel_app_container");
    appContainer.style.marginTop = "2rem";

    dispatch(setCurrentQuestion(question));
    if (question.key === questionTypes.CUSTOM_MESSAGE) {
      dispatch(setScreens({ ...question.screens }));
      setEmailModalOpen(true);
    } else if (question.key === questionTypes.ON_DEMAND_INSIGHTS) {
      dispatch(resetOnDemandState());
      dispatch(updateStep1Data(question.fields));
      setOnDemandInsightsOpen(true);
    } else if (
      question.key === questionTypes.AUTOMATED_MESSAGE ||
      question.key === questionTypes.AUTOMATED_EMAIL
    ) {
      setShowAutomatedMsgModal(true);
    } else {
      setFormData(question);
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setShowDropdown(false);
    setFormData();
  };

  const fetchQuestions = () => {
    // e.preventDefault();
    setLoader(true);
    api
      .get(`/v2/extension/home`)
      .then(function (res) {
        setLoader(false);
        dispatch(setQuestions(res.data.form));
        setFormData(res.data.form);
        dispatch(setMetaData(res.data.metadata));
      })
      .catch(function (error) {
        handleFetchError(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const handleQuestionClick = async (inputValue) => {
    initializeState();
    const question = currentQuestion;

    handleDialogClose();
    startLoading("Fetching details, just a moment...");

    if (question.key !== questionTypes.PERSONA_COMPANY_INSIGHTS) {
      handleNonPersonaCompanyInsights(inputValue);
    } else {
      handlePersonaCompanyInsights(inputValue);
    }
  };

  const initializeState = () => {
    dispatch(updateIsFetchingInsights(true));
    localStorage.setItem("isFetching", true);
    setIsActiveUser(true);
    localStorage.setItem("isActiveUser", true);
    dispatch(resetCurrentChatList());
    dispatch(updateUid({ type: "people", uid: null }));
    dispatch(updateUid({ type: "company", uid: null }));
  };

  const startLoading = (message) => {
    setLoader(true);
    setLoadingMessage(message);
  };

  const stopLoading = () => {
    setLoader(false);
    setLoadingMessage("");
    dispatch(updateIsFetchingInsights(false));
  };

  const fetchAndUpdateAnswerForPersonAndCompany = () => {
    const promiseList = [];
    const h_id = uuidv4();
    Object.keys(currentQuestion.params["person"]).forEach((key) => {
      let requestId = uuidv4();
      const currentPromise = fetchNewInsight(
        "people",
        currentPersonUid,
        key,
        h_id,
        requestId,
        tagTypes.PEOPLE_COMPANY_INSIGHTS
      ).then((res) => {
        // { questionType, type, key, answer }
        let answer = [];
        if (res.response.length > 0) {
          answer = [res.response[0].content];
        }
        dispatch(
          updateAnswer({
            questionType: questionTypes.PERSONA_COMPANY_INSIGHTS,
            type: questionTypes.PERSONA_INSIGHTS,
            key: res.param,
            answer: answer,
          })
        );
      });
      promiseList.push(currentPromise);
    });

    Object.keys(currentQuestion.params["company"]).forEach((key) => {
      let requestId = uuidv4();
      const currentPromise = fetchNewInsight(
        "company",
        currentCompanyUid,
        key,
        h_id,
        requestId,
        tagTypes.PEOPLE_COMPANY_INSIGHTS
      ).then((res) => {
        // { questionType, type, key, answer }
        let answer = [];
        if (res.response.length > 0) {
          answer = [res.response[0].content];
        }
        dispatch(
          updateAnswer({
            questionType: questionTypes.PERSONA_COMPANY_INSIGHTS,
            type: questionTypes.COMPANY_INSIGHTS,
            key: res.param,
            answer: answer,
          })
        );
      });

      promiseList.push(currentPromise);
    });

    Promise.all(promiseList)
      .then((res) => {
        stopLoading();
      })
      .catch((e) => {
        stopLoading();
      });
  };

  const fetchAndUpdateAnswerForNonPersonAndCompany = () => {
    let promiseList = [];
    const h_id = uuidv4();
    const uid =
      currentQuestion.key === questionTypes.COMPANY_INSIGHTS
        ? currentCompanyUid
        : currentPersonUid;
    Object.keys(currentQuestion.params).forEach((key) => {
      let requestId = uuidv4();
      const insightType =
        currentQuestion.key === questionTypes.COMPANY_INSIGHTS
          ? "company"
          : "people";
      const currenttag =
        currentQuestion.key === questionTypes.COMPANY_INSIGHTS
          ? tagTypes.COMPANY_INSIGHTS
          : tagTypes.PEOPLE_INSIGHTS;
      const currentPromise = fetchNewInsight(
        insightType,
        uid,
        key,
        h_id,
        requestId,
        currenttag
      ).then((res) => {
        let answer = [];
        if (res.response.length > 0) {
          answer = [res.response[0].content];
        }
        dispatch(
          updateAnswer({
            questionType: currentQuestion.key,
            type: currentQuestion.key,
            key: res.param,
            answer: answer,
          })
        );
      });
      promiseList.push(currentPromise);
    });

    Promise.all(promiseList)
      .then((res) => {
        stopLoading();
      })
      .catch((e) => {
        stopLoading();
      });
  };

  useEffect(() => {
    if (currentChatList.length < 1) {
      return;
    }
    let lastMsg = currentChatList[currentChatList.length - 1];
    if (lastMsg.sender === "bot") {
      if (
        lastMsg.type === questionTypes.PERSONA_COMPANY_INSIGHTS &&
        currentCompanyUid != null &&
        currentPersonUid != null
      ) {
        fetchAndUpdateAnswerForPersonAndCompany();
      } else if (
        (lastMsg.type === questionTypes.COMPANY_INSIGHTS &&
          currentCompanyUid) ||
        (lastMsg.type === questionTypes.PERSONA_INSIGHTS && currentPersonUid)
      ) {
        fetchAndUpdateAnswerForNonPersonAndCompany();
      }
    }
  }, [currentChatList.length, currentPersonUid, currentCompanyUid]);

  const generateMessageWithQuestionsForPersonAndCompany = () => {
    const personQuestion = Object.keys(currentQuestion.params["person"]).map(
      (key) => {
        return {
          question: currentQuestion.params["person"][key],
          answer: [],
          type: questionTypes.PERSONA_INSIGHTS,
          isLoading: true,
          key: key,
        };
      }
    );

    const companyQuestion = Object.keys(currentQuestion.params["company"]).map(
      (key) => {
        return {
          question: currentQuestion.params["company"][key],
          answer: [],
          type: questionTypes.COMPANY_INSIGHTS,
          isLoading: true,
          key: key,
        };
      }
    );

    let questionList = [...personQuestion, ...companyQuestion];
    const messageWithQuestion = {
      sender: "bot",
      data: questionList,
      type: questionTypes.PERSONA_COMPANY_INSIGHTS,
    };
    return messageWithQuestion;
  };

  const generateMessageWithQuestionsForNonPersonAndCompany = () => {
    const questionList = Object.keys(currentQuestion.params).map((key) => {
      return {
        question: currentQuestion.params[key],
        answer: [],
        type: currentQuestion.key,
        isLoading: true,
        key: key,
      };
    });
    const messageWithQuestion = {
      sender: "bot",
      data: questionList,
      type: currentQuestion.key,
    };
    return messageWithQuestion;
  };

  const handlePersonaCompanyInsights = async (inputValue) => {
    try {
      const messageText = `${currentQuestion.outcome} of ${
        inputValue["Company Linkedin URL"] || inputValue["Person Linkedin URL"]
      }`;
      dispatch(addMessageToCurrentList({ text: messageText, sender: "user" }));
      let msg = generateMessageWithQuestionsForPersonAndCompany();
      dispatch(addMessageToCurrentList(msg));

      const uidCompany = await extractLinkedInUID(
        "company",
        inputValue["Company Linkedin URL"]
      );
      const uidPerson = await extractLinkedInUID(
        "people",
        inputValue["Person Linkedin URL"]
      );
      setLoadingMessage("Fetching data from the website...");
      await fetchWebSummary(uidCompany);
      setLoadingMessage("Profile/Company UID fetched, fetching insights...");

      dispatch(updateUid({ type: "people", uid: uidPerson }));
      dispatch(updateUid({ type: "company", uid: uidCompany }));
    } catch (error) {
      handleFetchError(error);
      dispatch(
        addMessageToCurrentList({
          text: `Failed to retrieve ${currentQuestion.outcome}.`,
          sender: "bot",
        })
      );
      setLoader(false);
      setLoadingMessage("");
    }
  };

  const handleNonPersonaCompanyInsights = async (inputValue) => {
    const h_id = uuidv4();
    let apiUrlPart, linkedInUrl;
    const messageText = `${currentQuestion.outcome} of ${
      inputValue["Company Linkedin URL"] || inputValue["Person Linkedin URL"]
    }`;
    dispatch(addMessageToCurrentList({ text: messageText, sender: "user" }));

    if (
      [questionTypes.PERSONA_INSIGHTS, questionTypes.COMPANY_INSIGHTS].includes(
        currentQuestion.key
      )
    ) {
      let msg = generateMessageWithQuestionsForNonPersonAndCompany();
      dispatch(addMessageToCurrentList(msg));
    }
    if (currentQuestion.key === questionTypes.PERSONA_INSIGHTS) {
      apiUrlPart = "people";
      linkedInUrl = inputValue["Person Linkedin URL"];
    } else {
      apiUrlPart = "company";
      linkedInUrl = inputValue["Company Linkedin URL"];
    }
    let uid = "";
    try {
      uid = await extractLinkedInUID(apiUrlPart, linkedInUrl);
      dispatch(updateUid({ type: apiUrlPart, uid: uid }));
      await extractApi(apiUrlPart, linkedInUrl, inputValue, h_id);
    } catch (error) {
      handleFetchError(error);
      dispatch(
        addMessageToCurrentList({
          text: `Failed to retrieve ${currentQuestion.outcome}`,
          sender: "bot",
        })
      );

      setLoader(false);
      setLoadingMessage("");
    }
  };

  const fetchWebSummary = async (uidCompany) => {
    try {
      await summarizeWeb(uidCompany);
    } catch (error) {
      console.log("Web summary status: error");
    }
  };

  const handleFetchError = (error) => {
    if (error.status == 401) {
      logout();
      toast.error("Please re-login to ensure security", "Error", 3000);
      setIsLoggedIn(false);
    }
  };

  const extractApi = async (apiUrlPart, linkedInUrl, inputValue) => {
    try {
      const uid = await extractLinkedInUID(apiUrlPart, linkedInUrl);

      if (currentQuestion.key === questionTypes.SIMILAR_CUSTOMERS) {
        processSimilarCustomers(uid);
      } else if (currentQuestion.key === questionTypes.WIN_STORY) {
        processStories(uid, inputValue);
      } else if (currentQuestion.key === questionTypes.POST_EVENT_EMAIL) {
        handleEmailInsights(inputValue, currentQuestion.piggyback);
      } else {
        processInsights(uid);
      }
      return true;
    } catch (error) {
      handleFetchError(error);
      dispatch(
        addMessageToCurrentList({
          text: `Failed to retrieve ${currentQuestion.outcome} UID.`,
          sender: "bot",
        })
      );

      setLoader(false);
      setLoadingMessage("");
    }
  };

  function processStories(uid, inputValue) {
    setLoadingMessage("Fetching details, just a moment...");

    api
      .post(`/v1/competition/winstory`, {
        uid: uid,
        competition: inputValue["Competition"],
      })
      .then((response) => {
        const winStories = response.data.response;
        const messages = winStories.map((customer, index) => {
          const winReasons =
            customer.Result.find((result) => result.win_reasons)?.win_reasons ||
            "No Win Reasons Available";
          const productSold =
            customer.Result.find((result) => result.product_sold)
              ?.product_sold || "No Product Information Available";
          const successCriteria =
            customer.Result.find((result) => result.success_criteria)
              ?.success_criteria || "No Success Criteria Available";

          const reasonList = winReasons
            .split(".")
            .filter((item) => item.trim() !== "")
            .map((item) => `<li> ${item.trim()}</li>`)
            .join("");

          const content =
            `**Customer:** ${customer.Customer}\n` +
            `<br/> **CRM Link:** [CRM Link](${customer["CRM Link"]})\n` +
            `<br/>**Selection Criteria:** ${customer["Selection Criteria"]}\n\n` +
            `<br/>**Win Reasons:**\n ${reasonList}\n\n` +
            `**Products Sold:**<li>${productSold}</li>\n\n` +
            `**Success Criteria:**<li>${successCriteria}</li>`;

          return {
            question: `Customer ${index + 1} - ${customer.Customer}`,
            answer: [content],
            type: questionTypes.CUSTOM_MESSAGE,
          };
        });

        dispatch(
          addMessageToCurrentList({
            text: "Win stories found:",
            sender: "bot",
            data: messages,
            type: currentQuestion.key,
          })
        );
        setLoader(false);
        setLoadingMessage("");
        setShowQuestions(true);
        localStorage.setItem("isFetching", false);
        dispatch(updateIsFetchingInsights(false));
      })
      .catch((error) => {
        // handleFetchError(error);
        console.error("Error finding win stories:", error);
        dispatch(
          addMessageToCurrentList({
            text: `Failed to find win stories.`,
            sender: "bot",
          })
        );
        setLoader(false);
        setLoadingMessage("");
        localStorage.setItem("isFetching", false);
        dispatch(updateIsFetchingInsights(false));
      });
  }

  function processSimilarCustomers(uid) {
    setLoadingMessage("Finding similar customers...");
    api
      .post(`/v1/similar/customers`, { uid: uid })
      .then((response) => {
        const similarCustomers = response.data.response;
        const messages = similarCustomers.map((customer, index) => {
          const dealNotes = customer.notes.deal_notes
            .map((note) => `<li> ${note}</li>`)
            .join("\n");
          const data = `<li> Website: ${customer.customer_website}</li>
                  <li> Country: ${customer.customer_country}</li>
                  <li> LinkedIn: ${customer.customer_linkedin_url}</li>
                  <li> Industry: ${customer.customer_industry}</li>
                  <li> Company Size: ${customer.customer_company_size}</li>`;
          const content = `\n\n **Basic Info:**\n${data}
                  \n\n **Deal Notes:**\n ${dealNotes}
                  `;
          return {
            question: `Customer ${index + 1} - ${customer.customer_website}`,
            answer: [content],
          };
        });

        dispatch(
          addMessageToCurrentList({
            text: "Similar customers found:",
            sender: "bot",
            data: messages,
            type: currentQuestion.key,
          })
        );
        setLoader(false);
        setLoadingMessage("");
        setShowQuestions(true);
        localStorage.setItem("isFetching", false);
        dispatch(updateIsFetchingInsights(false));
      })
      .catch((error) => {
        handleFetchError(error);

        console.error("Error finding similar customers:", error);
        dispatch(
          addMessageToCurrentList({
            text: `Failed to find similar customers.`,
            sender: "bot",
          })
        );

        setLoader(false);
        setLoadingMessage("");
        localStorage.setItem("isFetching", false);
        dispatch(updateIsFetchingInsights(false));
      });
  }

  async function processInsights(uid) {
    if (currentQuestion.key === questionTypes.COMPANY_INSIGHTS) {
      setLoadingMessage("Fetching data from the website...");
      await fetchWebSummary(uid);
    }

    // setLoadingMessage("Profile/Company UID fetched, fetching insights...");
    setLoadingMessage("Fetching details, just a moment...");
  }

  const handleEmailInsights = (inputValue, piggyback) => {
    let sequenceIndex = 1; // Initialize sequence index

    // Extract UIDs for both person and company
    Promise.all([
      api.post("v1/people/linkedin/extract", {
        linkedin_url: inputValue["Person Linkedin URL"],
      }),
      api.post("v1/company/linkedin/extract", {
        linkedin_url: inputValue["Company Linkedin URL"],
      }),
    ])
      .then(([personResponse, companyResponse]) => {
        const personUid = personResponse.data.uid;
        const companyUid = companyResponse.data.uid;

        // Update loading message sequence based on piggyback value
        setLoadingMessage(`Generating Email Sequence - 1`);

        const sendInitialEmail = () => {
          let payload = {
            company_uid: companyUid,
            people_uid: personUid,
            event_name: inputValue["Event Name"],
            event_persona_type: inputValue["Event Persona Type"],
            event_notes: inputValue["Event Notes"],
            application_category: inputValue["Application Category"],
            uid: "",
            seq: sequenceIndex,
          };

          // Send initial email and handle sequence
          sendEmails(payload, piggyback);
        };

        // Call the function to send the initial email
        sendInitialEmail();
      })
      .catch((error) => {
        setLoader(false);
        setLoadingMessage("");
        console.error("Error fetching UIDs:", error);
        dispatch(
          addMessageToCurrentList({
            text: `Failed to retrieve UIDs.`,
            sender: "bot",
          })
        );
        setLoader(false);
        setLoadingMessage("");
        localStorage.setItem("isFetching", false);
        dispatch(updateIsFetchingInsights(false));
      });

    const sendEmails = (payload, piggyback, emailResults = null) => {
      if (emailResults) {
        payload.uid = emailResults.uid; // Update UID after the first email
      }

      api
        .post("v1/postevent/email", payload)
        .then((response) => {
          const emailResults = response.data;
          dispatch(
            addMessageToCurrentList({
              text: `Email sent successfully for sequence ${payload.seq}.`,
              sender: "bot",
              data: emailResults.email.email_body,
            })
          );

          if (payload.seq < piggyback) {
            localStorage.setItem("isFetching", true);
            payload.seq += 1;
            sequenceIndex += 1; // Increment sequence index
            setLoadingMessage(`Generating Email Sequence - ${sequenceIndex}`);
            sendEmails(payload, piggyback, emailResults); // Recursively send next email
          } else {
            setLoader(false);
            setLoadingMessage("");
            localStorage.setItem("isFetching", false);
            dispatch(updateIsFetchingInsights(false));
          }
        })
        .catch((error) => {
          handleFetchError(error);
          console.error(
            `Error sending email for sequence ${payload.seq}:`,
            error
          );
          dispatch(
            addMessageToCurrentList({
              text: `Failed to send email for sequence ${payload.seq}.`,
              sender: "bot",
            })
          );
          setLoader(false);
          setLoadingMessage("");
          localStorage.setItem("isFetching", false);
          dispatch(updateIsFetchingInsights(false));
        });
    };
  };
  const displaySelectedHistory = () => {
    // Determine the insight type based on the 'tag'
    dispatch(resetCurrentChatList());

    dispatch(updateNewChatActiveStatus(false));
    let insightType = "";

    const storyInsight = selectedHistory.tag === "win_story";
    const similarCustomers = selectedHistory.tag === "similar_customer";
    if (selectedHistory.tag === tagTypes.COMPANY_INSIGHTS) {
      insightType = "Company Insights";
    } else if (selectedHistory.tag === tagTypes.PEOPLE_INSIGHTS) {
      insightType = "Persona Insights";
    } else if (selectedHistory.tag === tagTypes.PEOPLE_COMPANY_INSIGHTS) {
      insightType = "Persona & Company Insights";
    } else if (selectedHistory.tag === tagTypes.SIMILAR_CUSTOMERS) {
      insightType = "Similar Customers";
    } else if (selectedHistory.tag === tagTypes.POST_EVENT_EMAIL) {
      insightType = "Post Event Email";
    } else if (selectedHistory.tag === tagTypes.WIN_STORY) {
      insightType = "Win Stories";
    } else if (selectedHistory.tag === tagTypes.EMAIL) {
      insightType = "Generate Email Messages";
    } else if (selectedHistory.tag === tagTypes.LinkedIn) {
      insightType = "Generate Linkedin Messages";
    } else if (selectedHistory.tag === tagTypes.ON_DEMAND) {
      insightType = "Generate Insights";
    }
    setInsightType(
      selectedHistory.tag === "people_insights"
        ? "persona_insights"
        : selectedHistory.tag === "people_company_insights"
        ? "people_company_insights"
        : "company_insights"
    );

    // This will check if the history data is for email insights or other insights
    const isEmailInsight = selectedHistory.response[0]?.insights?.email_body;

    const messageText =
      selectedHistory.l_url || selectedHistory.people_linkedin_url
        ? `${insightType} for ${
            selectedHistory.l_url || selectedHistory.people_linkedin_url || ""
          }`
        : `${insightType}`;
    // Create a message for the insight type
    let typeMessage = {
      sender: "user",
      text: messageText,
    };
    if (
      selectedHistory.tag === tagTypes.EMAIL ||
      selectedHistory.tag === tagTypes.LinkedIn
    ) {
      const messageObject = {
        "Person linkedin Url": selectedHistory?.input_data?.person_linkedin_url,
        "Company Linkedin Url":
          selectedHistory?.input_data?.company_linkedin_url,
        "Message Length": selectedHistory?.input_data?.message_length,
        "No. of Variations": selectedHistory?.input_data?.variations,
      };

      typeMessage = {
        sender: "user",
        text: messageText,
        data: messageObject,
        type: selectedHistory.tag,
      };
    }

    if (selectedHistory.tag === tagTypes.ON_DEMAND) {
      const messageObject = {
        "Person linkedin Url": selectedHistory?.input_data?.person_linkedin_url,
      };

      typeMessage = {
        sender: "user",
        text: messageText,
        data: messageObject,
        type: questionTypes.ON_DEMAND_INSIGHTS,
      };
    }

    dispatch(addMessageToCurrentList(typeMessage));

    if (isEmailInsight) {
      setInsightType("");
      // Flatten the email responses into a single array suitable for the Accordion
      const emailData = selectedHistory.response.reduce((acc, item) => {
        const sequenceEmails = item.insights.email_body.map((variant) => {
          const variantKey = Object.keys(variant)[0]; // Get the key of the first property (Variant A, B, C)
          const markdownText = `${variant[variantKey].body}, \n\n **Reasoning**: ${variant[variantKey].reasoning}`;
          return {
            question: `Seq ${item.seq} - ${variantKey}: ${variant[variantKey].subject}`,
            answer: [markdownText.replace(/\n/g, "<br />")],
            type: selectedHistory.tag,
          };
        });
        return [...acc, ...sequenceEmails];
      }, []);

      // Create a single message that includes all variants
      const emailMessage = {
        sender: "bot",
        data: emailData,
        h_id: selectedHistory.h_id,
        type: selectedHistory.tag,
      };

      dispatch(addMessageToCurrentList(emailMessage));
    } else if (storyInsight) {
      setInsightType("");
      const winStories = selectedHistory.response;
      const messages = winStories.map((customer, index) => {
        const winReasons =
          customer.Result.find((result) => result.win_reasons)?.win_reasons ||
          "No Win Reasons Available";
        const productSold =
          customer.Result.find((result) => result.product_sold)?.product_sold ||
          "No Product Information Available";
        const successCriteria =
          customer.Result.find((result) => result.success_criteria)
            ?.success_criteria || "No Success Criteria Available";

        const reasonList = winReasons
          .split(".")
          .filter((item) => item.trim() !== "")
          .map((item) => `<li> ${item.trim()}</li>`)
          .join("");

        const content =
          `**Customer:** ${customer.Customer}\n` +
          `<br/> **CRM Link:** [CRM Link](${customer["CRM Link"]})\n` +
          `<br/>**Selection Criteria:** ${customer["Selection Criteria"]}\n\n` +
          `<br/>**Win Reasons:**\n ${reasonList}\n\n` +
          `**Products Sold:**<li> ${productSold}</li>\n\n` +
          `**Success Criteria:**<li> ${successCriteria}</li>`;

        return {
          question: `Story Insight ${index + 1} - ${customer.Customer}`,
          answer: [content],
          type: selectedHistory.tag,
        };
      });
      dispatch(
        addMessageToCurrentList({
          text: "Win stories found:",
          sender: "bot",
          data: messages,
        })
      );
    } else if (similarCustomers) {
      setInsightType("");
      const winStories = selectedHistory.response;
      const messages = winStories.map((customer, index) => {
        const dealNotes = customer.notes.deal_notes
          .map((note) => `<li> ${note}</li>`)
          .join("\n");
        const data = `<li> Website: ${customer.customer_website}</li>
          <li> Country: ${customer.customer_country}</li>
          <li> LinkedIn: ${customer.customer_linkedin_url}</li>
          <li> Industry: ${customer.customer_industry}</li>
          <li> Company Size: ${customer.customer_company_size}</li>`;
        const content = `\n\n **Basic Info:**\n${data}
          \n\n **Deal Notes:**\n ${dealNotes}
          `;
        return {
          question: `Customer ${index + 1} - ${customer.customer_website}`,
          answer: [content],
          type: selectedHistory.tag,
        };
      });

      dispatch(
        addMessageToCurrentList({
          text: "Similar customers found:",
          sender: "bot",
          data: messages,
        })
      );
    } else {
      let detailsMessage;
      // Handle non-email insights
      if (selectedHistory.tag === tagTypes.EMAIL) {
        detailsMessage = {
          sender: "bot",
          type: selectedHistory.tag,
          data: selectedHistory?.response.email?.map((res) => ({
            question: res.subject,
            answer: [res.body],
            h_id: selectedHistory?.h_id,
            variant: res?.variant,
          })),
        };
      }
      if (selectedHistory.tag === tagTypes.LinkedIn) {
        detailsMessage = {
          sender: "bot",
          type: selectedHistory.tag,
          data: selectedHistory?.response.message.map((res, index) => ({
            question: `Message Template ${index + 1}`,
            answer: [res.message],
            h_id: selectedHistory?.h_id,
            variant: res?.variant,
          })),
        };
      }
      if (
        selectedHistory.tag === tagTypes.COMPANY_INSIGHTS ||
        selectedHistory.tag === tagTypes.PEOPLE_INSIGHTS
      ) {
        detailsMessage = {
          sender: "bot",
          data: selectedHistory?.response.map((res) => ({
            question: res.title,
            answer: [res.content],
            type: formatTagtype(selectedHistory.tag),
            key: res?.param,
          })),
          h_id: selectedHistory.h_id,
          type: formatTagtype(selectedHistory.tag),
        };
      }

      if (selectedHistory.tag === tagTypes.PEOPLE_COMPANY_INSIGHTS) {
        setIsPeopleCompanyInsights(true);
        const peopleResponse = selectedHistory.response.people.map((res) => ({
          question: res.title,
          answer: [res.content],
          type: "persona_insights",
          key: res.param,
        }));
        const companyResponse = selectedHistory.response.company.map((res) => ({
          question: res.title,
          answer: [res.content],
          type: "company_insights",
          key: res.param,
        }));

        detailsMessage = {
          sender: "bot",
          data: [...peopleResponse, ...companyResponse],
          h_id: selectedHistory.h_id,
          type: questionTypes.PERSONA_COMPANY_INSIGHTS,
        };
      }
      if (selectedHistory.tag === tagTypes.ON_DEMAND) {
        detailsMessage = {
          sender: "bot",
          data: selectedHistory.response,
          h_id: selectedHistory.h_id,
          type: questionTypes.ON_DEMAND_INSIGHTS,
        };
      }
      // Add both messages to the chat
      dispatch(addMessageToCurrentList(detailsMessage));
    }
  };

  useEffect(() => {
    if (isNewChatActive) {
      dispatch(resetCurrentChatList());
      setShowQuestions(true);
    }
  }, [isNewChatActive]);

  useEffect(() => {
    if (Object.keys(selectedHistory).length !== 0) {
      dispatch(updateUid({ type: "people", uid: null }));
      dispatch(updateUid({ type: "company", uid: null }));
      setCurrentQuestion({});
      displaySelectedHistory();
    }
  }, [selectedHistory]);

  const handlePersonaData = async (inputValue) => {
    const linkedInUrl = inputValue["Person Linkedin URL"];

    try {
      const extractResponse = await extractLinkedInUID("people", linkedInUrl);
      return extractResponse;
    } catch (error) {
      console.error("Error fetching UID:", error);

      if (error.status == 401) {
        handleFetchError(error);
      } else {
        toast.error(
          "Error fetching data, please try again later",
          "Error",
          3000
        );
      }
      return error;
    }
  };

  return (
    <>
      {questions.length > 0 && (
        <InsightsForm
          open={dialogOpen}
          onClose={handleDialogClose}
          formData={formData}
          handleQuestionClick={handleQuestionClick}
          currentUrl={currentUrl}
          urlType={urlType}
          handlePersonaInsights={handlePersonaData}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
      )}
      {emailModalOpen && (
        <EmailBuilderModal
          open={emailModalOpen}
          setOpen={setEmailModalOpen}
          setShowQuestions={setShowQuestions}
          // setShowloader={setLoader}
          setLoadingMessage={setLoadingMessage}
          currentUrl={currentUrl}
          setShowCustomMessageLoader={setShowCustomMessageLoader}
        />
      )}
      {onDemandInsightsOpen && (
        <OnDemandInsights
          open={onDemandInsightsOpen}
          setOpen={setOnDemandInsightsOpen}
          setShowQuestions={setShowQuestions}
          setShowloader={setLoader}
          setLoadingMessage={setLoadingMessage}
        />
      )}
      {showAutomatedMsgModal && (
        <AutomatedMsgModal
          open={showAutomatedMsgModal}
          setOpen={setShowAutomatedMsgModal}
          setLoadingMessage={setLoadingMessage}
          setShowCustomMessageLoader={setShowCustomMessageLoader}
          setShowQuestions={setShowQuestions}

        />
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showSnackbar}
        autoHideDuration={4000}
        message={snacbarMsg}
        onClose={handleSnackbarClose}
      />
      <ChatFeedback
        open={open}
        onClose={closeFeedback}
        type={feedbackType}
        onSubmit={submitFeedback}
      />

      {currentChatList.length > 0 ? (
        <AccordianContainer
          messages={currentChatList}
          showLoader={showLoader}
          showCustomMessageLoader={showCustomMessageLoader}
          insightType={insightType}
          isPeoplCompanyInsights={isPeoplCompanyInsights}
          showQuestions={showQuestions}
          openFeedback={openFeedback}
          questions={questions}
          handleDialogOpen={handleDialogOpen}
          loadingMessage={loadingMessage}
        />
      ) : // <NewUser questions={questions} handleDialogOpen={handleDialogOpen} />
      questions.length ? (
        <Home
          dataObj={metaData?.groups}
          questions={questions}
          handleQuestionClick={handleDialogOpen}
        />
      ) : (
        <Grid container sx={{ alignItems: "center", justifyContent: "center" }}>
          <div className="iframe-loader">
            <img src={PintelRoundLogo} alt="" width={40} height={40} />
          </div>
        </Grid>
      )}
    </>
  );
};

export default PintelChat;
