import { createSlice, } from "@reduxjs/toolkit";
import { fieldType } from "../utils/constants";
import {  isValidUrl } from "../utils/validations";

const initialState = {
  steps: {
    currentStep: 0,
    isProcessing: false,
    isValid: false,
    validationResult: {},
  },
  step1Data: [],
  step2Data: {},
  formData: {
    person_linkedin_url: "",
    person_uuid: "",
    outcomes: [],
    priorities: [],
  },
};

export const onDemandSlice = createSlice({
  name: "onDemandSlice",
  initialState,
  reducers: {
    resetOnDemandState: (state, action) => {
      state = initialState;
    },
    updateCurrentStep: (state, action) => {
      state.steps.currentStep = action.payload;
    },
    updateProcessingState: (state, action) => {
      state.steps.isProcessing = action.payload;
    },
    updateValidatedState: (state, action) => {
      state.steps.isValidated = action.payload;
    },
    updateStep1DataAndValidate: (state, action) => {
      state.step1Data = action.payload;
      let data = action.payload;
      let usedValues = new Set();

      const isEmpty = (value) => {
        if (value == null || value == undefined) return true;
        if (typeof value === "string" && value.trim() === "") return true;
        if (Array.isArray(value) && value.length === 0) return true;
        if (typeof value === "object" && !Array.isArray(value)) {
          return Object.keys(value).every((key) => isEmpty(value[key]));
        }
        return false;
      };

      const validationResult = data.reduce((result, item) => {
        let errorMsg = "";
        let isValid = true;
        if (item.reqired) {
          if (item.type === fieldType.CLICK_BOX_MULTI_SELECT) {
            if (isEmpty(item.values)) {
              isValid = false;
              errorMsg = "Please select a value";
            }
          } else if (item.type === fieldType.MULTI_DROP_DOWN_SINGLE_SELECT) {
            if (isEmpty(item.values)) {
              isValid = false;
              errorMsg = "Please select a value";
            } else {
              const valuesArray = Object.values(item.values);
              for (let value of valuesArray) {
                const uniqueIdentifier =
                  typeof value === "object" ? value.key : value;
                if (usedValues.has(uniqueIdentifier)) {
                  isValid = false;
                  errorMsg = "Please select unique values";
                  break;
                }
                usedValues.add(uniqueIdentifier);
              }
            }
          } else if (item.type === fieldType.URL) {
            if (!isValidUrl(item.values)) {
              isValid = false;
              errorMsg = "Invalid URL";
            }
          } else {
            if (isEmpty(item.values)) {
              isValid = false;
              errorMsg = "Please enter a valid url";
            }
          }
        }
        result[item.name] = { isValid, errorMsg };
        return result;
      }, {});

      const isFormValid = Object.values(validationResult).every(
        (validation) => validation.isValid
      );

      state.steps.validationResult = validationResult;
      state.steps.isValid = isFormValid;
      state.steps.isProcessing = false;
      if (isFormValid) {
        state.steps.currentStep += 1;
      }
    },
    updateStep1Data: (state, action) => {
      state.step1Data = action.payload;
    },

    updateStep2Data: (state, action) => {
      state.step2Data = action.payload;
    },
    updateValidationResult: (state, action) => {
      state.steps.validationResult = action.payload.validationResult;
      state.steps.isValid = action.payload.isFormValid;
    },
    updateOnDemandFormdata: (state, action) => {
      const { key, value } = action.payload;
      state.formData = { ...state.formData, [key]: value };
    },
  },
});

export const {
  resetOnDemandState,
  updateCurrentStep,
  updateProcessingState,
  updateValidatedState,
  updateStep1Data,
  updateStep2Data,
  updateStep1DataAndValidate,
  updateValidationResult,
  updateOnDemandFormdata
} = onDemandSlice.actions;

export default onDemandSlice.reducer;
