function isValidUrl(url) {
    try {
        new URL(url);
        return true;
    } catch (e) {
        return false;
    }
}

function isNotEmpty(text) {
    return text.trim() !== '';
}

const isEmptyValue = (value) => {
    if (value == null || value == undefined) return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    if (Array.isArray(value) && value.length === 0) return true;
    if (typeof value === 'object' && !Array.isArray(value)) {
        return Object.keys(value).every(key => isEmptyValue(value[key]));
    }
    return false;
};

export {isValidUrl,isNotEmpty, isEmptyValue}