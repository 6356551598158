import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme, styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import LinkRenderer from "../ReactMarkdown/LinkRenderer";
import "./MessageTemplateTabs.css";
import { questionTypes } from "../../../utils/constants";
import { Divider, Grid, Tooltip } from "@mui/material";
import CopyIcon from "../../../assets/icons/copy.svg";
import { sendCopyMessageEventData } from "../../../utils/eventHandler/eventHandler";
import { copyfunction } from "../../../utils/eventHandler/copyEvent";
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import { IconButton } from '@mui/material';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CustomTabs = styled(Tabs)(({ theme }) => ({
  borderRadius: "10px",
  backgroundColor: `${theme.palette.basic.grey100}`,
  color: `${theme.palette.basic.black}`,
  border: `1px solid ${theme.palette.grey[200]}`,
  minHeight: "40px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "4px",
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTab-root": {
    minHeight: "30px!important",
    padding: "4px",
    fontWeight: 600,
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  fontSize: "14px",
  textTransform: "none",
  "&.Mui-selected": {
    backgroundColor: "#ffffff",
    color: `${theme.palette.basic.black}`,
    boxShadow:
      "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    borderRadius: "6px",
  },
}));

export default function MessageTemplateTabs({ messages }) {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [tooltipText, setTooltipText] = useState("Copy");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const copyToClipboard = (data, item) => {
    console.log(item);

    let content = data;

    const questionData = {
      variant: item?.variant,
      h_id: item?.h_id,
    };

    sendCopyMessageEventData(questionData);

    copyfunction(setTooltipText, content);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <CustomTabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        aria-label="full width tabs example"
        scrollButtons
        // variant="scrollable"
      >
        {messages?.data?.map((item, index) => {
          return <CustomTab label={`Template ${index + 1}`} />;
        })}
        {/* <IconButton sx={{ padding: "6px" }}>
          <ArrowBackIosIcon sx={{ fontSize: "16px" }} />
        </IconButton>

<IconButton sx={{ padding: "6px" }}>
          <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
        </IconButton> */}
      </CustomTabs>
      <Box
        sx={{
          backgroundColor: `${theme.palette.basic.grey100}`,
          color: `${theme.palette.basic.black}`,
          border: `1px solid ${theme.palette.grey[200]}`,
          borderRadius: "10px",
          marginTop: "4px",
        }}
      >
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          style={{
            overflowX: "hidden",
            width: "100%",
          }}
        >
          {messages?.data.map((item, index) => {
            return (
              <TabPanel value={value} index={index} key={index}>
                {item.answer &&
                  item.answer.length &&
                  item.answer.map((answer, ansIndex) => {
                    return messages.type === questionTypes.LINKEDIN ? (
                      <div
                        className="template_body"
                        key={ansIndex}
                        onCopy={() =>
                          sendCopyMessageEventData({
                            variant: item?.variant,
                            h_id: item?.h_id,
                          })
                        }
                      >
                        <h1 className="template_body_header">
                          {item?.question}
                        </h1>

                        <ReactMarkdown
                          children={answer}
                          rehypePlugins={[rehypeRaw]}
                          remarkPlugins={[remarkGfm]}
                          components={{ a: LinkRenderer }}
                        />
                        <Tooltip title={tooltipText}>
                          <img
                            src={CopyIcon}
                            alt="copy"
                            onClick={() => copyToClipboard(item.answer)}
                            height={18}
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <div
                        className="template_body"
                        key={ansIndex}
                        onCopy={() =>
                          sendCopyMessageEventData({
                            variant: item?.variant,
                            h_id: item?.h_id,
                          })
                        }
                      >
                        <Grid
                          container
                          sx={{
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h1 className="template_body_header">Subject</h1>
                          <Tooltip title={tooltipText}>
                            <img
                              src={CopyIcon}
                              alt="copy"
                              onClick={() => copyToClipboard(item.question)}
                              height={18}
                            />
                          </Tooltip>
                        </Grid>
                        <span>{item?.question}</span>
                        <Divider sx={{ marginTop: "12px" }} />
                        <Grid
                          container
                          sx={{
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h1 className="template_body_header">Body</h1>
                          <Tooltip title={tooltipText}>
                            <img
                              src={CopyIcon}
                              alt="copy"
                              onClick={() => copyToClipboard(item.answer, item)}
                              height={18}
                            />
                          </Tooltip>
                        </Grid>
                        <ReactMarkdown
                          children={answer}
                          rehypePlugins={[rehypeRaw]}
                          remarkPlugins={[remarkGfm]}
                          components={{ a: LinkRenderer }}
                        />
                      </div>
                    );
                  })}
              </TabPanel>
            );
          })}
        </SwipeableViews>
      </Box>
    </Box>
  );
}
