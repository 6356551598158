import React from 'react';
import './index.css';
import App from './App';
import PlThemeProvider from "./theme/PlThemeProvider";
import { createRoot } from 'react-dom/client';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter } from 'react-router-dom';

import { Provider } from "react-redux";
import { store } from './redux/store.js';

const container = document.getElementById('root');
const root = createRoot(container);
const authKey = process.env.REACT_APP_GOOGLE_AUTH_KEY


root.render(
  <GoogleOAuthProvider clientId={authKey} source="extenion">
      <PlThemeProvider>
        <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        </Provider>
      </PlThemeProvider>
  </GoogleOAuthProvider>
);