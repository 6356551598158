import { Button, useTheme } from "@mui/material";

const PrimaryLightButton = (props) => {
  const theme = useTheme();

  return (
    <Button
    onClick={props.onClick }
      sx={{
        border: `1px solid ${theme.palette.light.primary200}`,
        color: `${theme.palette.light.primary}`,
        borderRadius: "8px",
        textTransform: "none",
        fontSize: "14px",
        fontWeight: "600",
        padding: "4px"
      }}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
};

export default PrimaryLightButton;
