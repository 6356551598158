import { createSlice, current } from "@reduxjs/toolkit";
import { generateKey } from "../utils/constants";

const initialState = {
  screens: {},
  valueProps: {},
  emailCTA: {},
  tonality: [],
  messageParams: [],
  formData: {},
  currentMessageType: "",
  showNextButton: false,
  step2SelectedTab: "Posts",
  activeStep: 0,
  selectedFiltersValueProps: {},
  valuePropScrollHeight: 0,
  insightsDataScrollHeight: 0,
  companyUrls: [],
  valuePropsSearch: "",
  insightsSearch: "",
  calledPostAndCommentsForSeedApi: false,
  nextButtonLoading: false,
  calledPostAndCommentsForSeedApiCompany: false
};

export const customMessageSlice = createSlice({
  name: "customMessageSlice",
  initialState,
  reducers: {
    setScreens: (state, action) => {
      state.screens = action.payload;
    },
    setValueProps: (state, action) => {
      state.valueProps = action.payload;
    },
    setEmailCTA: (state, action) => {
      state.emailCTA = action.payload;
    },
    updateTonality: (state, action) => {
      state.tonality = action.payload;
    },
    setMessageParams: (state, action) => {
      state.messageParams = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    updateFormData: (state, action) => {
      const { key, value } = action.payload;
      state.formData[key] = value;
    },
    resetFormData: (state, action) => {
      state.formData = {};
    },
    updateActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    updateSelectedFiltersValueProps: (state, action) => {
      state.selectedFiltersValueProps = action.payload;
    },
    updateValuePropScrollHeight: (state, action) => {
      state.valuePropScrollHeight = action.payload;
    },
    updateInsightsDataScrollHeight: (state, action) => {
      state.insightsDataScrollHeight = action.payload;
    },
    updateCompanyUrls: (state, action) => {
      state.companyUrls = action.payload;
    },
    updateValuePropsSearch: (state, action) => {
      state.valuePropsSearch = action.payload;
    },
    updateInsightsSearch: (state, action) => {
      state.insightsSearch = action.payload;
    },
    updatecalledPostAndCommentsForSeedApi: (state, action) => {
      state.calledPostAndCommentsForSeedApi = action.payload;
    },
    updateNextButtonLoading : (state, action)=>{
      state.nextButtonLoading = action.payload
    },
    // updateInsightsFormData: (state, action) => {
    //   const key = action.payload.type ? action.payload.type : "person_posts";

    //   if (action.payload) {
    //     const existingData = state.formData.insights?.[key] || [];

    //     const updatedData = [];
    //     let isFound = false;

    //     if (key === "person_posts") {
    //       console.log(existingData, "before update");
    //       existingData.forEach((element) => {
    //         if (element.post_url !== action.payload.post_url) {
    //           updatedData.push(element);
    //         }
    //       });
    //       console.log(updatedData, "after update")
    //     } else {
    //       existingData.forEach((element) => {
    //         if (element.key !== action.payload.key) {
    //           updatedData.push(element);
    //         } else {
    //           isFound = true;
    //         }
    //       });
    //     }
    //     if (!isFound) updatedData.push(action.payload);

    //     state.formData = {
    //       ...state.formData,
    //       insights: {
    //         ...state.formData.insights,
    //         [key]: updatedData,
    //       },
    //     };
    //   }
    // },

    updateInsightsData: (state, action) => {
      const { key, data } = action.payload;
      let tempInsights = state.formData.insights;

      if (!Array.isArray(tempInsights[key])) {
        tempInsights[key] = [];
      }

      function containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
          if (JSON.stringify(list[i]) === JSON.stringify(obj)) {
            return true;
          }
        }

        return false;
      }

      if (containsObject(data, tempInsights[key])) {
        tempInsights[key] = tempInsights[key].filter(
          (item) => JSON.stringify(item) !== JSON.stringify(data)
        );
      } else {
        tempInsights[key].push(data);
      }

      state.formData.insights = tempInsights;
    },

    updateCurrentMessageType: (state, action) => {
      state.currentMessageType = action.payload;
    },

    updateEmailCTA: (state, action) => {
      // const emailCTA = state.formData.email_cta;
      // let isFound = false;

      // let updatedData = [];
      // emailCTA.forEach((item) => {
      //   if (item !== action.payload) {
      //     updatedData.push(item);
      //   } else {
      //     isFound = true;
      //   }
      // });

      // if (!isFound) {
      //   updatedData.push(action.payload);
      // }
      // state.formData["email_cta"] = updatedData;
      state.formData["email_cta"] = action.payload;
    },

    updateWordLimit: (state, action) => {
      const min = state.messageParams.message_length.min;
      const max = state.messageParams.message_length.max;
      const numberPayload = Number(action.payload);

      // if (numberPayload <= max || numberPayload >= min) {
      state.formData.message_length = numberPayload;
      // }
    },
    updateVariant: (state, action) => {
      state.formData.variations = action.payload;
    },
    updateShowNextButton: (state, action) => {
      state.showNextButton = action.payload;
    },
    updateNextButtonStatus: (state, action) => {
      const checkValidation = (data) => {
        if (data.generate_key === generateKey.VALUE_PROPS) {
          const dataLength = state.formData[generateKey.VALUE_PROPS].length;
          if (
            (dataLength > data.min_limit || !data.min_limit) &&
            dataLength < data.max_limit + 1
          ) {
            return true;
          }
          return false;
        }
        if (data.generate_key === generateKey.INSIGHTS) {
          let generatedArray = [];
          Object.keys(state.formData[generateKey.INSIGHTS]).forEach((key) => {
            generatedArray = [
              ...state.formData[generateKey.INSIGHTS][key],
              ...generatedArray,
            ];
          });
          if (
            (generatedArray.length > data.min_limit || !data.min_limit) &&
            generatedArray.length < data.max_limit + 1
          ) {
            return true;
          }
          return false;
        }
        if (data.generate_key === generateKey.INSTRUCTION) {
          if (
            state.messageParams[data.generate_key]?.required &&
            state.formData[data.generate_key] === ""
          ) {
            return false;
          }
          return true;
        }
        if (data.generate_key === generateKey.ADDITIONAL_DETAILS) {
          if (
            state.messageParams[data.generate_key]?.required &&
            state.formData[data.generate_key] === ""
          ) {
            return false;
          }
          return true;
        }
      };

      const screenContent = state.screens[0];

      let statusFlag = false;

      for (let i = 0; i < screenContent.length; i++) {
        let status = checkValidation(screenContent[i]);
        if (!status) {
          statusFlag = false;
          break;
        } else {
          statusFlag = true;
        }
      }

      if (!statusFlag) {
        state.showNextButton = false;
      } else {
        state.showNextButton = true;
      }
    },
    updateStep2SelectedTab: (state, action) => {
      state.step2SelectedTab = action.payload;
    },
    
  },
});

export const {
  setScreens,
  setValueProps,
  setEmailCTA,
  setMessageParams,
  setFormData,
  updateFormData,
  updateInsightsFormData,
  updateCurrentMessageType,
  updateEmailCTA,
  updateWordLimit,
  updateVariant,
  updateShowNextButton,
  updateNextButtonStatus,
  updateStep2SelectedTab,
  resetFormData,
  updateInsightsData,
  updateActiveStep,
  updateSelectedFiltersValueProps,
  updateValuePropScrollHeight,
  updateInsightsDataScrollHeight,
  updateCompanyUrls,
  updateInsightsSearch,
  updateValuePropsSearch,
  updateTonality,
  updatecalledPostAndCommentsForSeedApi,
  updateNextButtonLoading
} = customMessageSlice.actions;

export default customMessageSlice.reducer;
