import { AUTH_TOKEN_KEY } from "../utils/constants";

export const TOKEN_KEY = "pintel_access_token";

export const getToken = () => localStorage.getItem(AUTH_TOKEN_KEY);

export const getAuthData = () => JSON.parse(localStorage.getItem(TOKEN_KEY));

export function isAuth() {
  try {
    const tokenChecked = localStorage.getItem(AUTH_TOKEN_KEY);
    if (tokenChecked) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
}

export function login(token) {
  localStorage.setItem(TOKEN_KEY, token);
  return true;
}

export function userData(data) {
  localStorage.setItem("pintel_user_data", data);
  return true;
}

export function setRemember(user = {}) {
  localStorage.setItem("userRemember", JSON.stringify(user || isAuth()));
  return true;
}

export function removeRemember() {
  localStorage.removeItem("userRemember");
  return true;
}

export function logout() {
  localStorage.clear()
  return true;
}

export function setAuthTokenInLocalStorage(token){
  localStorage.setItem(AUTH_TOKEN_KEY, token)
}
