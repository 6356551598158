import Lottie from "react-lottie";
import animationData from "../../../lottiesJSON/comingSoon.json"

const ComingSoon  = ({height, width}) =>{

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
        <Lottie 
          options={defaultOptions}
          height={height || 400}
          width={width || 400}
        />
    );
  
}

export default ComingSoon;