import { Box, Grid, Typography } from "@mui/material";
import SingleSelect from "../../common/SingleSelect";
import { useDispatch, useSelector } from "react-redux";
import { fieldType } from "../../../utils/constants";
import ProductSelect from "../../common/ProductSelect";
import CustomInput from "../../common/CustomInput";
import MultiSelect from "../../common/MultiSelect";
import SearchIcon from "../../../assets/search.svg";
import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  memo,
} from "react";
import {
  setFormData,
  updateSelectedFiltersValueProps,
  updateValuePropScrollHeight,
  updateValuePropsSearch,
} from "../../../redux/customMessage";
import ErrorAlert from "../../common/Alert/ErrorAlert";

const ValueProps = forwardRef(({ filterData, maxLimit }) => {
  const dispatch = useDispatch();
  const valueProps = useSelector(
    (state) => state.customMessageReducer.valueProps
  );
  const {
    formData,
    activeStep,
    selectedFiltersValueProps,
    valuePropScrollHeight,
    valuePropsSearch,
  } = useSelector((state) => state.customMessageReducer);

  const [selectedItems, setSelectedItems] = useState(formData.value_props);
  const [searchInput, setSearchInput] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  // const [selectedFilters, setSelectedFilters] = useState({});

  const handleFilterChange = (key, value) => {
    let updatedFilters = { ...selectedFiltersValueProps, [key]: value };
    dispatch(updateSelectedFiltersValueProps(updatedFilters));
  };

  const handleCheckBoxChange = (data) => {
    const value = data.value;
    const updatedSelection = selectedItems.includes(value)
      ? selectedItems.filter((item) => item !== value)
      : [...selectedItems, value];
    if (updatedSelection.length < maxLimit + 1) {
      setSelectedItems(updatedSelection);
      dispatch(setFormData({ ...formData, value_props: updatedSelection }));
    } else {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
  };

  useEffect(() => {
    if (activeStep === 3)
      dispatch(setFormData({ ...formData, value_props: selectedItems }));
  }, [activeStep]);

  useEffect(() => {
    const element = document.getElementById("valuePropmultiselectDiv");
    if (element) {
      element.scrollTop = valuePropScrollHeight;
    }
  }, []);

  const filterItems = () => {
    const filterKeys = Object.keys(selectedFiltersValueProps);

    if (filterKeys.length === 0) {
      return (
        valueProps &&
        valueProps.data.map((option) => ({
          value: option,
          label: option.value_prop,
        }))
      );
    }

    return valueProps.data
      .filter((item) => {
        return filterKeys.every((key) => {
          const filterValue = selectedFiltersValueProps[key];
          if (Array.isArray(filterValue)) {
            return filterValue.every((val) => {
              return filterValue.includes(item[key]);
            });
          }
          return item[key] === filterValue;
        });
      })
      .map((option) => ({
        value: option,
        label: option.value_prop,
      }));
  };

  const getValue = (key) => {
    const value = selectedFiltersValueProps?.[key];
    return value;
  };

  return (
    <>
      <Typography
        sx={{ fontSize: "12px", marginBottom: "-25px", fontWeight: "600" }}
      >
        Apply Filters
      </Typography>
      <Grid container rowSpacing={1} columnSpacing={1}>
        {filterData.map((data, index) =>
          data.type === fieldType.SINGLE_SELECT ? (
            <Grid item xs={4} key={data.key}>
              <SingleSelect
                list={valueProps[data.key]?.data.map((option, ind) => ({
                  value: option,
                  label: option,
                }))}
                placeholder={
                  data.key[0].toUpperCase() +
                  data.key.slice(1).replaceAll("_", " ")
                }
                value={getValue(data.key) || ""}
                onChange={(e) => handleFilterChange(data.key, e.target.value)}
              width={"200px"}
              />
            </Grid>
          ) : (
            <Grid item xs={4} key={data.key}>
              <ProductSelect
                list={valueProps[data.key]?.data?.map((option) => ({
                  value: option,
                  label: option,
                }))}
                placeholder="Product"
                maxWidth={"200px"}
                value={selectedFiltersValueProps[data.key] || []}
                onChange={(e) => handleFilterChange(data.key, e.target.value)}
                // selectedItems={selectedFiltersValueProps}
              />
            </Grid>
          )
        )}
        <Grid item xs={12}>
          <CustomInput
            id="search"
            name="search"
            placeholder="Search Value Props"
            value={valuePropsSearch}
            onChange={(e) => dispatch(updateValuePropsSearch(e.target.value))}
            icon={SearchIcon}
          />
          <div
            style={{ maxHeight: "270px", overflow: "auto" }}
            onScroll={(event) =>
              dispatch(updateValuePropScrollHeight(event.target.scrollTop))
            }
            id="valuePropmultiselectDiv"
          >
            <MultiSelect
              handleChange={handleCheckBoxChange}
              selectedItems={selectedItems}
              icon={SearchIcon}
              items={filterItems().filter((item) =>
                item.label
                  .toLowerCase()
                  .includes(valuePropsSearch.toLowerCase())
              )}
            />
          </div>
        </Grid>
      </Grid>
      {showAlert && (
        <ErrorAlert
          errorMsg={"You have exceeded the selection limit of 3 items"}
        />
      )}
    </>
  );
});

export default memo(ValueProps);
