import "./DotPulseLoader.css";

const DotPulseLoader =() =>{
    return(
        <div className="snippet" data-title="dot-pulse">
          <div className="stage">
            <div className="dot-pulse"></div>
          </div>
        </div>
    )
}
export default DotPulseLoader;