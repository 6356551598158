import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material";
import PlInput from "./common/PlInput";
import { makeStyles } from "@mui/styles";
import { isNotEmpty, isValidUrl } from "../utils/validations";
import { useSelector } from "react-redux";
import { questionTypes } from "../utils/constants";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: "65%", 
    maxWidth: "600px", 
    "&$dialogWrapper": {
      borderRadius: "10px",
    },
  },
  buttonWrapper: {
    justifyContent: "stretch",
  },
  contentWrapper: {
    marginTop: "2rem",
  },
}));

function InsightsForm({
  open,
  onClose,
  formData,
  handleQuestionClick,
  currentUrl,
  urlType,
  handlePersonaInsights,
  showDropdown,
  setShowDropdown,
  selectedCompany,
  setSelectedCompany,
}) {
  const classes = useStyles();
  const [inputValues, setInputValues] = useState({});
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState();
  const [showLoader, setLoader] = useState(false);
  const [companyUrls, setCompanyUrls] = useState([]);
  const currentQuestion = useSelector(
    (state) => state.insightReducer.currentQuestion
  );

  useEffect(() => {
    setFormValues(formData);
    const questionType = currentQuestion.key;
    setErrors({});
    let formValuesCloned = structuredClone(formData);
    if (formValuesCloned?.fields) {
      if (
        (questionType === questionTypes.PERSONA_COMPANY_INSIGHTS ||
          questionType === questionTypes.PERSONA_INSIGHTS) &&
        urlType === "profile"
      ) {
        formValuesCloned.fields[0].url = currentUrl;
      } else if (
        (questionType === questionTypes.COMPANY_INSIGHTS ||
          questionType === questionTypes.SIMILAR_CUSTOMERS ||
          questionType === questionTypes.WIN_STORY) &&
        urlType === "company" 
      ) {
        formValuesCloned.fields[0].url = currentUrl;
      } else {
        formValuesCloned.fields[0].url = "";
      }
    }

    const initialValues =
      formValuesCloned?.fields?.reduce(
        (acc, item) => ({
          ...acc,
          [item.name]: item.url || "", 
        }),
        {}
      ) || {};
    setInputValues(initialValues);
    setFormValues(formValuesCloned);
  }, [formData, currentUrl, currentQuestion]); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorMsg = "";
    const field = formValues.fields.find((f) => f.name === name);
    if (field) {
      if (field.type === "URL" && !isValidUrl(value)) {
        errorMsg = "Invalid URL";
      } else if (
        field.data_type === "text" &&
        field.reqired &&
        !isNotEmpty(value)
      ) {
        errorMsg = "This field is required";
      }
    }
    setErrors((prev) => ({ ...prev, [name]: errorMsg }));
    return errorMsg === ""; // Return if field is valid
  };

  const handleSave = async () => {
    let allValid = true;

    formValues.fields.forEach((field) => {
      // Only validate required fields
      if (field.reqired) {
        const isValid = validateField(field.name, inputValues[field.name]);
        if (!isValid) allValid = false;
      }
    });

    if (!allValid) {
      return; // Prevent form submission if any errors
    }
    await handleQuestionClick(inputValues);
    onClose();
    setSelectedCompany("");
  };

  const handleNext = async () => {
    setLoader(true);
    let allValid = true;

    formValues.fields.forEach((field) => {
      // Only validate required fields
      if (field.reqired) {
        const isValid = validateField(field.name, inputValues[field.name]);
        if (!isValid) allValid = false;
      }
    });
    if (!allValid) {
      setLoader(false);
      return;
    }

    const res = await handlePersonaInsights(inputValues);
    setLoader(false);
    if (res.status === 200) {
      setCompanyUrls(res.data.company_urls);
      setShowDropdown(true);
      setInputValues((prev) => ({
        ...prev,
        "Company Linkedin URL": "",
      }));

      setFormValues((prev) => ({
        ...prev,
        fields: [
          ...prev.fields,
          {
            name: "Company Linkedin URL",
            type: "URL",
            data_type: "text",
            reqired: true,
            placeholder: "https://www.linkedin.com/company/microsoft",
          },
        ],
      }));
    } else {
      setShowDropdown(false);
      setSelectedCompany("");
    }
  };

  const handleCompanyChange = (e) => {
    const { value } = e.target;
    setSelectedCompany(value);
    if (value === "other") {
      setInputValues((prev) => ({ ...prev, "Company Linkedin URL": "" }));
    } else {
      setInputValues((prev) => ({ ...prev, "Company Linkedin URL": value }));
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{ className: classes.dialogWrapper }}
        BackdropProps={{
          style: { backgroundColor: "#1F1651", opacity: ".8"},
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid #dedcdc",
            textTransform: "uppercase",
            color: "#2e2e2e",
            fontWeight: "600",
            fontSize: "15px",
            letterSpacing: "1px",
          }}
        >
          Get Insights
        </DialogTitle>
        {showLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "3rem",
            }}
          >
            {" "}
            <CircularProgress />
          </div>
        ) : (
          <>
            {showDropdown ? (
              <DialogContent className={classes.contentWrapper}>
                <PlInput
                  id={"companyDropdown"}
                  name={"companyDropdown"}
                  label={"Please select the Company"}
                  placeholder={"https://www.linkedin.com/company/microsoft"}
                  type="select"
                  isSelect={true}
                  list={[
                    ...companyUrls.map((option) => ({
                      label: option.Company,
                      value: option.LinkedinURL,
                    })),
                    { label: "Other", value: "other" },
                  ]}
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                  required={true}
                  showError={true}
                  isValid={!errors["Company Linkedin URL"]}
                  errorMsg={errors["Company Linkedin URL"]}
                />
                {selectedCompany === "other" && (
                  <PlInput
                    id={"Company Linkedin URL"}
                    name={"Company Linkedin URL"}
                    label={"Company Linkedin URL"}
                    placeholder={"https://www.linkedin.com/company/microsoft"}
                    type="url"
                    isSelect={false}
                    value={inputValues["Company Linkedin URL"] || ""}
                    onChange={handleChange}
                    required={true}
                    showError={true}
                    isValid={!errors["Company Linkedin URL"]}
                    errorMsg={errors["Company Linkedin URL"]}
                  />
                )}
              </DialogContent>
            ) : (
              <DialogContent
                sx={{
                  marginTop: "2rem",
                }}
              >
                {formValues?.fields?.map((field) =>
                  field.type === "SINGLE-SELECT" ? (
                    <PlInput
                      key={field.name}
                      id={field.name}
                      name={field.name}
                      label={field.name}
                      placeholder={field.placeholder}
                      type="select"
                      isSelect={true}
                      list={field.option.map((option) => ({
                        value: option,
                        label: option,
                      }))}
                      value={inputValues[field.name] || ""}
                      onChange={handleChange}
                      required={field.reqired}
                      showError={true}
                      isValid={!errors[field.name]}
                      errorMsg={errors[field.name]}
                    />
                  ) : (
                    <PlInput
                      key={field.name}
                      id={field.name}
                      name={field.name}
                      label={field.name}
                      placeholder={field.placeholder}
                      type={field.type}
                      isSelect={false}
                      value={inputValues[field.name] || ""}
                      onChange={handleChange}
                      required={field.reqired}
                      showError={true}
                      isValid={!errors[field.name]}
                      errorMsg={errors[field.name]}
                    />
                  )
                )}
              </DialogContent>
            )}
            <DialogActions className={classes.buttonWrapper}>
              <Button
                onClick={onClose}
                sx={{
                  border: "1px solid #697af8",
                  width: "100%",
                  borderRadius: "5px",
                  fontSize: "12px !important",
                }}
                color="primary"
              >
                Cancel
              </Button>
              {currentQuestion.key === questionTypes.PERSONA_COMPANY_INSIGHTS &&
              !showDropdown ? (
                <Button
                  className={classes.saveBtn}
                  onClick={handleNext}
                  sx={{
                    color: "white",
                    background: "#697af8",
                    width: "100%",
                    fontSize: "12px !important",
                    "&:hover": {
                      backgroundColor: "#697ae3",
                    },
                  }}
                  color="primary"
                >
                  Next
                </Button>
              ) : (
                <Button
                  className={classes.saveBtn}
                  onClick={handleSave}
                  sx={{
                    color: "white",
                    background: "#697af8",
                    width: "100%",
                    fontSize: "12px !important",
                    "&:hover": {
                      backgroundColor: "#697ae3",
                    },
                  }}
                  color="primary"
                >
                  Submit
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}

export default InsightsForm;
