import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Select,
  FormControl,
  FormHelperText,
  Grid,
  Box,
} from "@mui/material";
import CustomInput from "./CustomInput";
import MultiSelect from "./MultiSelect";
import SearchIcon from "../../assets/search.svg";
import SelectionIndicator from "../EmailBuilderStepper/EmailBuilderDataGeneration.js/SelectionIndicator";
import { updateSelectedFiltersValueProps } from "../../redux/customMessage";
import { useDispatch, useSelector } from "react-redux";

const StyledFormControl = styled(FormControl)(({ theme, hasValue }) => ({
  background: "#fff",
  borderRadius: "6px",
  marginTop: "20px!important",
  marginBottom: "20px !important",
  width: "100%",
  boxShadow: "none",
  border: `1px solid ${hasValue ? theme.palette.primary.main : theme.palette.light.borderColor}`,
  
  "& .MuiInputLabel-root": {
    left: "-9px",
    top: "-15px",
    color: "black",
    fontSize: "16px",
  },
  "& .MuiFormHelperText-root": {
    fontSize: "0.6rem !important",
    marginLeft: "10px",
  },
  "& .MuiSelect-select": {
    height: "auto",
    padding: "8px",
    fontSize: "12px !important",
    fontWeight: hasValue ? "bold" : "normal",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    top: 0,
    border: "none",
    boxShadow: "0 7px 64px 0 rgba(0, 0, 0, 0.07)",
  },
  "& legend": {
    display: "none",
  },
  "& .MuiSelect-select:placeholder": {
    fontSize: "12px",
    color: theme.palette.light.gray,

  },
  "&.Mui-disabled": {
    opacity: 0.5,
    pointerEvents: "none",
  }, 

}));

const menuProps = {
  PaperProps: {
    style: {
      boxShadow: "none",
      border: `1px solid #E9EBFF`,
      width: "300px",
    },
  },
};

function ProductSelect({
  id,
  value,
  onChange,
  showError,
  errorMsg,
  required,
  isValid,
  placeholder,
  variant,
  list,
  disabled,
  maxWidth,
}) {
const dispatch = useDispatch();
const [searchInput, setSearchInput] = useState("");
const {selectedFiltersValueProps} = useSelector((state) => state.customMessageReducer);

  const handleChange = (item) => {

    const updatedProducts = selectedFiltersValueProps.product || [];
    const updatedSelection = updatedProducts.includes(item.value)
      ? updatedProducts.filter((i) => i !== item.value)
      : [...updatedProducts, item.value];

    dispatch(updateSelectedFiltersValueProps(
      {...selectedFiltersValueProps, product: updatedSelection}
    ))
  };


  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <StyledFormControl
      variant={variant || "outlined"}
      error={required && !isValid && showError}
      disabled={disabled}
      hasValue={selectedFiltersValueProps?.product?.length >0}
      MultiSelect
    >
      <Select
        id={id}
        name={id}
        value={value}
        MenuProps={menuProps}
        renderValue={(selected) =>
          selected && selected.length > 0
            ? <SelectionIndicator placeholder={placeholder} number={selected.length}/>
            : placeholder
        }
        InputLabelProps={!value ? {} : { shrink: false }}
        displayEmpty={true}
        multiple={true}
      >
        <Grid container sx={{ border: "1px solid primary.main", padding: "0 6px", alignItems: "flex-start" }}>
          <Grid item xs={12}>
            <CustomInput
              id="search"
              name="search"
              placeholder="Search Product"
              value={searchInput}
              onChange={handleSearch}
              icon={SearchIcon}
            />
<Box sx={{maxHeight: "220px", overflow:"auto"}}>
            <MultiSelect
              handleChange={handleChange}
              items={
                list?.filter((item) =>
                  item.label.toLowerCase().includes(searchInput.toLowerCase())
                )
              }
              selectedItems={selectedFiltersValueProps?.product || [] }
              // maxWidth={maxWidth}
            />
            </Box>
          </Grid>
        </Grid>
      </Select>
      {required && !isValid && showError && (
        <FormHelperText>{errorMsg}</FormHelperText>
      )}
    </StyledFormControl>
  );
}

ProductSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  errorMsg: PropTypes.string,
  required: PropTypes.bool,
  isValid: PropTypes.bool,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  list: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

export default ProductSelect;
