import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
// mui
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

//icons
import likeIco from "../assets/chat_like.svg";
import disLikeIco from "../assets/chat_dislike.svg";
// components
import PlInput from "../components/common/PlInput";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2, 6),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles((theme) => ({
  topgap20: {
    display: "flex",
    justifyContent: "start",
  },
  dialogContainer: {
    "& .MuiPaper-root": {
      overflow: "visible",
    },
  },
  feedbackTypeIco: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: 20,
  },
  btnSubmit: {
    borderRadius: "6px",
    border: "1px solid #6979F8",
    cursor: "pointer",
    padding: "12px 40px",
    background: "#fff",
    color: "#6979F8",
    fontSize: "12px",
    fontWeight: "600",
    display: "inline-block",
    float: "right",
    marginBottom: "20px",
    marginTop: "20px",
  },
  checkContainer: {
    "& .MuiFormControlLabel-label": {
      fontSize: 12,
    },
  },
  actionsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "auto",
    flexWrap: "wrap",
  },
}));

function ChatFeedback(props) {
  const classes = useStyles();
  const chatFeebackPoints = [
    "This is Harmful/ Unsafe",
    "This isn’t true",
    "This isn’t helpful",
  ];
  const initialState = () => ({
    feedback: "",
    selectedOptions: [],
  });

  const [state, setState] = useState(initialState());

  const { feedback, selectedOptions } = state;

  const handleChange = (event) => {
    setState((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const onCheckChange = (e, str) => {
    e.preventDefault();
    const optIndex = selectedOptions.indexOf(str);
    let options = selectedOptions;
    if (optIndex > -1) {
      options.splice(optIndex, 1);
    } else {
      options.push(str);
    }
    setState((prev) => ({ ...prev, selectedOptions: options }));
  };

  const submitFeedback = () => {
    const obj = {
      type: props.type === "like" ? 1 : 0, // SEND 0 for dislike and 1 for like
      feedback,
      option: selectedOptions,
    };
    props.onSubmit(obj);
    setState((prev) => ({ ...prev, feedback: "" }));
  };

  return (
    <BootstrapDialog
      onClose={props.onClose}
      open={props.open}
      maxWidth="md"
      fullWidth={true}
      className={classes.dialogContainer}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: "20px 20px 10px",
          fontSize: 16,
          fontWeight: 600,
          color: (theme) => theme.palette.light.blackLight,
        }}
      >
        <img
          src={props.type === "like" ? likeIco : disLikeIco}
          width="70"
          className={classes.feedbackTypeIco}
        />
        Please Give Your Feedback
      </DialogTitle>
      <DialogContent sx={{ padding: "0 30px" }}>
        <PlInput
          id="feedback"
          value={feedback}
          onChange={handleChange}
          label=""
          placeholder={
            props.type === "like"
              ? "What do you like about the response?"
              : "What was the issue with the answer ? How could it be improved ? "
          }
          multiline={true}
          showBorder={true}
          rows={"4"}
        />
        {props.type === "dislike" ? (
          <div className="topgap20">
            {chatFeebackPoints.map((item) => (
              <div className={classes.checkContainer}>
                <FormControlLabel
                  label={item}
                  control={
                    <Checkbox
                      checked={selectedOptions.indexOf(item) > -1}
                      onChange={(e) => onCheckChange(e, item)}
                      inputProps={{ "aria-label": "controlled" }}
                      label={item}
                    />
                  }
                />
              </div>
            ))}
          </div>
        ) : undefined}
        <div className={classes.actionsWrapper}>
          <button className={classes.btnSubmit} onClick={submitFeedback}>
            Submit
          </button>
          <button className={classes.btnSubmit} onClick={props.onClose}>
            Cancel
          </button>
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default ChatFeedback;

ChatFeedback.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
