import { Accordion, Box, styled } from "@mui/material";

const DynamicAccordionParent = ({ children }) => {
  const CustomAccordionParent = styled(Accordion)(({ theme }) => ({
    border: `none`,
    width: "100%",
    boxShadow: "none",
    marginBottom: "10px",
    borderRadius: "5px",

    "& .Mui-expanded": {
      backgroundColor: "white",
    },
    "&.Mui-expanded": {
      backgroundColor: "white",
      border: `1px solid ${theme.palette.light.purpleLight}`,
    },
    "&::before": {
      content: "none",
    },
  }));

  return <CustomAccordionParent>{children}</CustomAccordionParent>;
};

export default DynamicAccordionParent;
