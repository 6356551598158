import React from "react";
import PropTypes from "prop-types";
import {
    styled,
  } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const ButtonComponent = styled(LoadingButton)(({ theme }) => ({
   "&": {
    backgroundColor: theme.palette.light.primary,
    color: theme.palette.dark.white,
    minWidth: "100px",
    textTransform: "none"
   },
   "&:hover":{
    backgroundColor: theme.palette.light.primaryFade
   },
   "&:disabled":{
    backgroundColor: theme.palette.light.disableColor,
    color: theme.palette.dark.white,
    cursor: "not-allowed"
   }
  }));


function PrimaryButton(props) {
  return (
   <ButtonComponent disabled={props.disabled} onClick={props.onClick} loading={props.loading} sx={props.sx}>
        {props.children}
  </ButtonComponent>   
  );
}

PrimaryButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default PrimaryButton;
