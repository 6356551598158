import botIcon from "../assets/pintel_chat.svg";
import { onDemandOutcomes } from "../utils/constants";
import {
  AccordionDetails,
  Grid,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import DynamicAccordionSummary from "./common/DynamicAccordion/DynamicAccordionSummary";
import DynamicAccordionParent from "./common/DynamicAccordion/DynamicAccordionParent";


function LinkRenderer(props) {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
}


const RenderOnDemandResponse = ({ messages }) => {

  const renderContent = (item, key) => {
    if (key === onDemandOutcomes.persona_challenges) {
      return (
        <DynamicAccordionParent>
          <DynamicAccordionSummary
           
          >
            <span className="title_label">
              {key[0].toUpperCase() + key.slice(1).replaceAll("_", " ")}
            </span>
          </DynamicAccordionSummary>
          <AccordionDetails sx={{ minHeight: "300px" }}>
            {item.map((element) => {
              return (
                <Grid
                  conatiner
                  xs={12}
                  sx={{
                    marginTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "1px solid #C9CFFC",
                  }}
                >
                  <Grid item>
                    <span>
                      <em>Challenge:</em>
                    </span>
                    <span className="accordion_answer">
                      {element.challenge}
                    </span>
                  </Grid>
                  <Grid item>
                    <span>
                      <em>Solution:</em>
                    </span>
                    <span className="accordion_answer">{element.solution}</span>
                  </Grid>
                </Grid>
              );
            })}
          </AccordionDetails>
        </DynamicAccordionParent>
      );
    }
    if (key === onDemandOutcomes.similar_customers) {
      return (
        <DynamicAccordionParent>
          <DynamicAccordionSummary
           
          >
            <span className="title_label">
              {key[0].toUpperCase() + key.slice(1).replaceAll("_", " ")}
            </span>
          </DynamicAccordionSummary>
          <AccordionDetails sx={{ minHeight: "300px" }}>
            {item.map((element) => {
              return (
                <div
                  style={{
                    marginTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "1px solid #C9CFFC",
                  }}
                >
                  {Object.keys(element).map((data) => {
                    return (
                      <>
                        <Grid conatiner xs={12}>
                          <Grid item>
                            <div style={{ display: "flex" }}>
                              <span>
                                <em>
                                  {data[0].toUpperCase() +
                                    data.slice(1).replaceAll("_", " ")}
                                </em>
                              </span>
                              :{" "}
                              <span className="accordion_answer">
                                {element[data]}
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
             
                </div>
              );
            })}
          </AccordionDetails>
        </DynamicAccordionParent>
      );
    }

    if (key === onDemandOutcomes.case_study) {
      return (
        <DynamicAccordionParent>
          <DynamicAccordionSummary
     
          >
            <span className="title_label">
              {key[0].toUpperCase() + key.slice(1).replaceAll("_", " ")}
            </span>
          </DynamicAccordionSummary>
          <AccordionDetails sx={{ minHeight: "300px" }}>
            {item.map((element) => {
              return (
                <Grid
                  conatiner
                  xs={12}
                  sx={{
                    marginTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "1px solid #C9CFFC",
                  }}
                >
                  <Grid item>
                    <span>
                      <em>Url:</em>
                    </span>
                    <a
                      href={element.url}
                      target="_blank"
                      className="accordion_answer"
                    >
                      {element.url}
                    </a>
                  </Grid>
                  <Grid item>
                    <span>
                      <em>Case Study:</em>
                    </span>
                    <span className="accordion_answer">
                      <ReactMarkdown
                        children={element.case_study}
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[remarkGfm]}
                        components={{ a: LinkRenderer }}
                      />
                    </span>
                  </Grid>
                </Grid>
              );
            })}
          </AccordionDetails>
        </DynamicAccordionParent>
      );
    }
  };

  return (
    <>
      <span style={{ marginBottom: "auto", marginTop: "20px" }} className={`message ${messages.sender}`}>
        {/* <img src={botIcon} alt="Bot" className="message-icon" />
        <span style={{ position: "absolute" }}>Pintel Assistant</span> */}
        <span className="message-text-custom bot_message">
          {Object.keys(messages.data || {})?.map((key) => {
           
            if (key === onDemandOutcomes.persona_challenges) {
              return <>{renderContent(messages.data[key], key)}</>;
            }
            if (key === onDemandOutcomes.case_study) {
              return <>{renderContent(messages.data[key], key)}</>;
            }
            if (key === onDemandOutcomes.similar_customers) {
              return <>{renderContent(messages.data[key], key)}</>;
            }
          })}
        </span>
      </span>
    </>
  );
};

export default RenderOnDemandResponse;
