import { Backdrop, Dialog, DialogTitle } from "@mui/material";

const CommonDialog = ({ handleClose, open, title, children }) => {
  return (
    <>
      <Backdrop
        sx={{
          zIndex: 105,
          backgroundColor: "#1F1651",
        }}
        style={{ opacity: ".8" }}
        open={open}
      />
      <Dialog onClose={handleClose} open={open} >
        <DialogTitle>{title}</DialogTitle>
        {children}
      </Dialog>
    </>
  );
};

export default CommonDialog