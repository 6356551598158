import React, { act, useEffect, useState } from "react";
import StepperModal from "../StepperModal.js";
import EmailBuilderPersonaDetails from "../EmailBuilderPersonaDetails/index.js";
import EmailBuilderDataGeneration from "../EmailBuilderDataGeneration.js/index.js";
import EmailBuilderVariantScreen from "../EmailBuilderVariantScreen/index.js";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import customMessage, {
  setEmailCTA,
  setFormData,
  setValueProps,
  updateActiveStep,
  updateCompanyUrls,
  updateCurrentMessageType,
  updateFormData,
  updateInsightsSearch,
  updateNextButtonLoading,
  updateSelectedFiltersValueProps,
  updateStep2SelectedTab,
  updateTonality,
  updateValuePropScrollHeight,
  updateValuePropsSearch,
} from "../../../redux/customMessage.js";
import {
  getEmailCTA,
  getValueProps,
  messageGenerator,
  getTonality
} from "../../../services/customMessage.js";
import {
  addMessageToCurrentList,
  resetCurrentChatList,
  updateIsFetchingInsights,
  updateNewChatActiveStatus,
} from "../../../redux/insights.js";
import {
  fieldType,
  generateKey,
  linkedinurlTypes,
  questionTypes,
  tagTypes,
  valueAccordingToFieldType,
} from "../../../utils/constants.js";
import { toast } from "react-toastify";
import { callSeedApiForCompany } from "../../../services/extractServices.js";

const steps = ["Add Profile Details", "Select Content", "Set Format"];

const EmailBuilderModal = ({
  open,
  setOpen,
  setShowQuestions,
  setShowCustomMessageLoader,
  setLoadingMessage,
  // currentUrl
}) => {
  const activeStep = useSelector(
    (state) => state.customMessageReducer.activeStep
  );
  const { currentUrl, currentUrlType } = useSelector(
    (state) => state.insightReducer
  );
  const initializeFormData = (config) => {
    let initialData = {};
    Object.values(config).forEach((step) => {
      step.forEach((item) => {
        initialData[item.generate_key] = valueAccordingToFieldType[item.type];
      });
    });

    const personUrl =
      currentUrlType === linkedinurlTypes.PROFILE ? currentUrl : "";
    initialData = {
      ...initialData,
      // person_linkedin_url: personUrl,
      person_uuid: "",
      company_linkedin_url: "",
      company_uuid: "",
    };

    dispatch(setFormData(initialData));
    dispatch(updateCurrentMessageType(""));
    dispatch(updateSelectedFiltersValueProps({}));
    dispatch(updateValuePropScrollHeight(0));
    dispatch(updateSelectedFiltersValueProps(0));
    dispatch(updateStep2SelectedTab("posts"));
    dispatch(updateCompanyUrls([]));
    dispatch(updateValuePropsSearch(""));
    dispatch(updateInsightsSearch(""));
  };

  const content = useSelector((state) => state.customMessageReducer.screens[1]);
  const screenConfig = useSelector(
    (state) => state.customMessageReducer.screens
  );
  const formData = useSelector((state) => state.customMessageReducer.formData);
  const currentMessageType = useSelector(
    (state) => state.customMessageReducer.currentMessageType
  );

  useEffect(() => {
    initializeFormData(screenConfig);
  }, [currentUrl]);

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <EmailBuilderPersonaDetails />;
      case 1:
        return <EmailBuilderDataGeneration />;
      case 2:
        return <EmailBuilderVariantScreen content={content} />;
      default:
        return (
          <Typography>An Error occurred, Please try again later</Typography>
        );
    }
  };

  const dispatch = useDispatch();

  const getValuePropsFunc = async () => {
    try {
      const data = await getValueProps();
      dispatch(setValueProps(data));
    } catch (error) {
      console.log("something went wrong");
      toast.error(
        "Error fetching data, please try again later",
        "Error",
        3000
      );
    }
  };

  const getEmailCTAFunc = async () => {
    try {
      const data = await getEmailCTA();
      dispatch(setEmailCTA(data));
      dispatch(updateFormData({ key: generateKey.EMAIL_CTA, value: data[0] }));
    } catch (error) {
      console.log("something went wrong");
      toast.error(
        "Error fetching data, please try again later",
        "Error",
        3000
      );
    }
  };

  const getTonalityList = async() =>{

    try {
      const data = await getTonality();
      dispatch(updateTonality(data));
      dispatch(updateFormData({ key: generateKey.TONALITY, value: data[0] }));
    } catch (error) {
      console.log("something went wrong");
      toast.error(
        "Error fetching data, please try again later",
        "Error",
        3000
      );
    }

  }

  const initializeState = () => {
    dispatch(updateIsFetchingInsights(true));
    localStorage.setItem("isFetching", true);

    localStorage.setItem("isActiveUser", true);
    dispatch(resetCurrentChatList());
    setShowQuestions(false);
    setShowCustomMessageLoader(true);
    // setLoadingMessage("Crafting your personalized message... Hang tight!");
    setLoadingMessage("Fetching details, just a moment...");
  };

  const currentQuestion = useSelector(
    (state) => state.insightReducer.currentQuestion
  );

  const callMessageGenerateAPI = async () => {
    initializeState();

    // const data = formData;

    const messageObject = {
      "Person linkedin Url": formData.person_linkedin_url,
      "Company Linkedin Url": formData.company_linkedin_url,
      "Message Length": formData.message_length,
      "No. of Variations": formData.variations,
    };

    dispatch(
      addMessageToCurrentList({
        data: messageObject,
        sender: "user",
        type: currentQuestion.key_mapping[currentMessageType],
        text: `I want to generate ${currentQuestion.key_mapping[currentMessageType] === questionTypes.EMAIL ? "an "+currentMessageType : "a "+currentMessageType+" Message"} `,
        currentMessageType: currentQuestion.key_mapping[currentMessageType],
      })
    );

    try {
      const messageData = await messageGenerator(
        currentQuestion.key_mapping[currentMessageType],
        formData
      );

      let messages = [];

      if (currentQuestion.key_mapping[currentMessageType] === tagTypes.EMAIL) {
        messages = messageData.response.email.map((data) => {
          return {
            question: `${data.subject}`,
            answer: [data.body],
            variant: data?.variant,
            h_id: messageData?.h_id
          };
        });
      }

      if (
        currentQuestion.key_mapping[currentMessageType] === tagTypes.LinkedIn
      ) {
        messages = messageData.response?.message?.map((data, index) => {
          return {
            question: `Message Template ${index + 1}`,
            answer: [data.message],
            variant: data?.variant,
            h_id: messageData?.h_id
          };
        });
      }

      dispatch(
        addMessageToCurrentList({
          text: "Please find the information below",
          sender: "bot",
          data: messages,
        type: currentQuestion.key_mapping[currentMessageType],
        })
      );
    } catch (error) {
      console.log(error, "error in generating messages");
      dispatch(
        addMessageToCurrentList({
          text: "Couldn't generate prompt",
          sender: "bot",
        })
      );
    } finally {
      dispatch(updateIsFetchingInsights(false));
      setShowQuestions(true);
      setShowCustomMessageLoader(false);
      setLoadingMessage("");
      dispatch(updateNewChatActiveStatus(false));
      localStorage.setItem("isFetching", false);
    }
  };

  useEffect(() => {
    getValuePropsFunc();
  }, []);

  useEffect(() => {
    getEmailCTAFunc();
    getTonalityList()
  }, []);

  const handleNext = async() => {
    if(activeStep === 0){
      dispatch(updateNextButtonLoading(true))
      try{
        await callSeedApiForCompany(formData.company_linkedin_url);
        dispatch(updateActiveStep(activeStep + 1));
      }catch(err){
        toast.error("Sorry, We are unable to fetch data for this company.")
      }finally{
      dispatch(updateNextButtonLoading(false))
      }

    }else{
      dispatch(updateActiveStep(activeStep + 1));
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      initializeFormData(screenConfig);
      dispatch(updateCompanyUrls([]));
      dispatch(updateCurrentMessageType(""));
    } else {
      dispatch(updateActiveStep(activeStep - 1));
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      dispatch(updateActiveStep(0));
      setFormData({});
    }
  };

  const handleClickFinish = () => {
    callMessageGenerateAPI();
    setOpen(false);
    dispatch(updateActiveStep(0));
  };

  const checkValuesFilledInPersonDetails = () => {
    if (currentMessageType) {
      return false;
    }
    return true;
  };

  return (
    <StepperModal
      open={open}
      setOpen={setOpen}
      steps={steps}
      renderStepContent={renderStepContent}
      callMessageGenerateAPI={callMessageGenerateAPI}
      handleClickFinish={handleClickFinish}
      handleClose={handleClose}
      handleBack={handleBack}
      handleNext={handleNext}
      checkValuesFilledInPersonDetails={checkValuesFilledInPersonDetails}
    />
  );
};

export default EmailBuilderModal;
