import { toast } from "react-toastify";
import { AUTH_TOKEN_KEY } from "../utils/constants";
import { logout } from "./auth";
const BASE_URL = process.env.REACT_APP_API || "https://api.pintel.ai/ext";
// const BASE_URL = "https://api.pintel.ai/apis";

class ApiError extends Error {
  constructor(status, message, details) {
    super(message);
    this.status = status;
    this.details = details;
  }
}

export async function makeGetRestCall(url) {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const response = await fetch(BASE_URL + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (!response.ok) {
    if(response.status === 401){
      toast.warning("Please re-login to ensure security");
      logout();
      
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    let errorDetails;
      errorDetails = await response.json();
      throw new ApiError(response.status, errorDetails.message, errorDetails.details);
  }

  return response.json();
}

export async function makePostRestCall(url, data, extractURL) {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const response = await fetch((extractURL || BASE_URL) + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });


  if (!response.ok) {
    if(response.status === 401){
      toast.warning("Please re-login to ensure security");
      logout();
      
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    let errorDetails;
      errorDetails = await response.json();
      throw new ApiError(response.status, errorDetails.message, errorDetails.details);
  }

  return response.json();
}


export async function makePutRestCall(url, data) {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const response = await fetch(BASE_URL + url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    if(response.status === 401){
      toast.warning("Please re-login to ensure security");
      logout();
      
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    let errorDetails;
      errorDetails = await response.json();
      throw new ApiError(response.status, errorDetails.message, errorDetails.details);
  }

  return response.json();
}

export async function makeDeleteRestCall(url) {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const response = await fetch(BASE_URL + url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (!response.ok) {
    if(response.status === 401){
      toast.warning("Please re-login to ensure security");
      logout();
      
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    let errorDetails;
      errorDetails = await response.json();
      throw new ApiError(response.status, errorDetails.message, errorDetails.details);
  }

  return response.json();
}