import { Grid } from "@mui/material";
import ContentDropdown from "./ContentDropdown";
import { useDispatch, useSelector } from "react-redux";
import { updateShowNextButton } from "../../../redux/customMessage";

const EmailBuilderDataGeneration = (props) => {


  const content = useSelector(
    (state) => state.customMessageReducer.screens[0]
  );

  
  return (
    <Grid container rowSpacing={1}>
      {content.map((data, index) => (
        <ContentDropdown key={data.title} title={`${index + 1}. ${data.title}`} content={data} maxLimit={data.max_limit}/>
      ))}
    </Grid>
  );
};

export default EmailBuilderDataGeneration;
