import React from "react";
import Sidebar from "../components/Sidebar";
import hamburgerMenu from "../assets/icons/hamburger.svg";
import "../css/Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCurrentChatList,
  updateNewChatActiveStatus,
} from "../redux/insights";
import PrimaryLightButton from "./buttons/primaryLightBtn";

function TopNav({ toggleSidebar, isOpen, removeSidebar }) {
  const dispatch = useDispatch();

  const handleSidebarToggle = () => {
    !isFetchingInsights && toggleSidebar();
  };

  const { isFetchingInsights } = useSelector((state) => state.insightReducer);

  const initiateNewChat = () => {
    dispatch(updateNewChatActiveStatus(true));
    dispatch(resetCurrentChatList());
    removeSidebar();
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="topNav">
        <div className="hamburger" onClick={handleSidebarToggle}>
          <img src={hamburgerMenu} alt="menu" />
        </div>

        <div className="">
          <PrimaryLightButton
            onClick={initiateNewChat}
            sx={{ padding: "2px", fontWeight: 500 }}
            disabled={isFetchingInsights}
          >
            New Chat
          </PrimaryLightButton>
        </div>
      </div>
    </>
  );
}

export default TopNav;
