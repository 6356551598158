import React, { memo } from "react";
import { Box, Dialog, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import "../../css/StepperComponent.css";
import PrimaryButton from "../common/Buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import StepperHeaderComponent from "../common/StepperHeaderComponent";

const StepperModal = ({
  open,
  steps,
  renderStepContent,
  handleBack,
  handleClose,
  handleClickFinish,
  handleNext,
  checkValuesFilledInPersonDetails,
}) => {
  const dispatch = useDispatch();
  const activeStep = useSelector(
    (state) => state.customMessageReducer.activeStep
  );

  const showNextButton = useSelector(
    (state) => state.customMessageReducer.showNextButton
  );

  const nextButtonLoading = useSelector(
    (state) => state.customMessageReducer.nextButtonLoading
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        BackdropProps={{
          style: { backgroundColor: "#1F1651", opacity: ".8" },
        }}
        className="stepper-dialog"
        sx={{
          "& .MuiDialog-paper": {
            margin: "-20px 10px 0px 10px",

            width: "100%",
            height: "100%",
            position: "relative",
            background: "transparent",
            overflowY: "hidden",
          },
        }}
      >
        <div className="cross_btn_container">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              background: "transparent",
              zIndex: "1200",
              color: "white",
              marginLeft: "auto",
              padding: 0,
            }}
          >
            <ClearIcon />
          </IconButton>
        </div>

        <div className="dialog_content_container">
          <StepperHeaderComponent
            activeStep={activeStep}
            className="stepper-header"
            steps={steps}
          />

          <Box className="stepper-box">{renderStepContent(activeStep)}</Box>

          <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            padding: "10px",
            mb: 4
          }}
        >
          {activeStep === 0 && (
            <PrimaryButton
              disabled={checkValuesFilledInPersonDetails()}
              onClick={handleBack}
            >
              CLEAR
            </PrimaryButton>
          )}
          {activeStep !== 0 && (
            <PrimaryButton disabled={activeStep === 0} onClick={handleBack}>
              BACK
            </PrimaryButton>
          )}
          <PrimaryButton
            onClick={
              activeStep === steps.length - 1 ? handleClickFinish : handleNext
            }
            disabled={!showNextButton}
            loading={nextButtonLoading}
          >
            {activeStep === steps.length - 1 ? "SUBMIT" : "NEXT"}
          </PrimaryButton>
        </Box>
        </div>
      
      </Dialog>
    </>
  );
};

export default memo(StepperModal);
