import { Grid } from "@mui/material";
import DynamicCheckBox from "../../DynamicCheckBox";
import "./ClickBoxMultiSelect.css";
import { useEffect, useState } from "react";

const ClickBoxMultiSelect = ({
  dataList,
  title,
  handleParentDataChange,
  erroMsg,
  values
}) => {
  const [selectedItems, setSelectedItems] = useState(values);

  const onChange = (e) => {
    const value = e.target.value;
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(value)) {
        return prevSelectedItems.filter((item) => item !== value);
      } else {
        return [...prevSelectedItems, value];
      }
    });
  };

  useEffect(() => {
    handleParentDataChange(selectedItems);
  }, [selectedItems]);

  return (
    <div>
      <div className="flex_title">
        <span className="title_lable">{title}</span>
      </div>
      <Grid container>
        {dataList.map((item, index) => {
          return (
            <Grid 
            item sx={{
              marginRight: "30px"
            }}>
              <DynamicCheckBox
                checked={selectedItems.includes(item.key)}
                label={item.name}
                value={item.key}
                onChange={onChange}
              />
            </Grid>
          );
        })}
      </Grid>
      <span className="error_msg">{erroMsg}</span>

    </div>
  );
};

export default ClickBoxMultiSelect;
