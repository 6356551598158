import { Checkbox, FormControlLabel } from "@mui/material";

const DynamicCheckBox = ({ label, value, checked, onChange }) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Checkbox
          sx={{
            padding: "8px",
            paddingBottom: "0",
            "& .MuiSvgIcon-root": {
              // fontSize: "22px",
            }
          }}
          checked={checked}
          onChange={onChange}
          size="small"
        />
      }
      label={label}
      sx={{
        alignItems: "center"
      }}
      componentsProps={{
        typography: {
          sx: {
            paddingTop: "8px",
            fontSize: "14px",
          },
        },
      }}
    />
  );
};

export default DynamicCheckBox;
