/**
 * get new insight for people or company
 *
 * @param {string} type - The type of entity ("person" or "company").
 * @param {string} uid - uid.
 * @param {string} param - param
 * @param {string} h_id - h_id
 * @param {string} requestId - requestId
 * 
 * 
 * @returns {Promise<any>} - A promise that resolves with the response data.
 *
 */
import api from "./api";
import { makeGetRestCall, makePostRestCall } from "./httpServices";

export const getInsightHistory = async (url) => {
  if(url){           
   const res = await api.get(url);
   return res.data
  }else{
    return makeGetRestCall( "/v1/insights/history/new");
  }
};

export const sendInsightFeedback = async (data) => {
  return makePostRestCall(`/v1/insights/feedback`, data);
};

export const getPosts = async (data) => {
  return makePostRestCall(`/v1/post`, data);
};

export const fetchNewInsight = async (type, uid, param, h_id, requestId, tag) => {
  const url = `/v1/${type}/linkedin/insights/new`;

  return makePostRestCall(url, {
    uid: uid,
    param: param,
    h_id: h_id,
    requestId: requestId,
    tag: tag
  });
};