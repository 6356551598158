import { Button, Tooltip } from "@mui/material";

const MessageBtn = ({colors, children, onClick,disabled,tooltip}) => {
  return (
    <Tooltip title={tooltip}>
      <span>
    <Button
      sx={{
        border: `1px solid ${colors.borderColor}`,
        color: colors.text,
        borderRadius: "4px",
        textTransform: "none",
        fontSize: "14px",
        backgroundColor: colors.background,
        padding: "4px 6px",
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
    </span>
    </Tooltip>
  );
};


export default MessageBtn;
