import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import PlInput from "../../common/PlInput";
import { fieldType, generateKey } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "../../common/MultiSelect";
import {
  updateEmailCTA,
  updateFormData,
  updateShowNextButton,
  updateVariant,
  updateWordLimit,
} from "../../../redux/customMessage";
import TextInput from "../../common/TextInput";
import SingleSelectDark from "../../common/SingleSelectDark";

const EmailBuilderVariantScreen = ({ content }) => {
  const dispatch = useDispatch();
  // const [selectedValue, setValue] = useState(1);
  const formData = useSelector((state) => state.customMessageReducer.formData);
  const messageParams = useSelector(
    (state) => state.customMessageReducer.messageParams
  );

  const debounceTimer = useRef(null);

  const emailCTA = useSelector((state) => state.customMessageReducer.emailCTA);

  const tonality = useSelector((state) => state.customMessageReducer.tonality);

  const handleChange = (event) => {
    dispatch(updateVariant(event.target.value));
  };

  const handleEmailCTASelectChange = (data) => {
    dispatch(updateEmailCTA(data.value));
  };

  const handleWordLimitChange = (event, generateKey) => {
    const max = messageParams[generateKey].max;
    const min = messageParams[generateKey].min;
    const messageLength = Number(event.target.value);
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    dispatch(updateWordLimit(event.target.value));

    // debounceTimer.current = setTimeout(() => {
    //   if (messageLength < min) {
    //     dispatch(updateWordLimit(min));
    //     return;
    //   }
    //   if (messageLength > max) {
    //     dispatch(updateWordLimit(max));
    //     return;
    //   }
    // }, 500);
  };

  const checkValidation = (element) => {
    if (element.generate_key === generateKey.EMAIL_CTA) {
      if (
        !messageParams[generateKey.EMAIL_CTA]?.min_limit ||
        formData[generateKey.EMAIL_CTA].length >
          messageParams[generateKey.EMAIL_CTA]?.min_limit
      ) {
        return true;
      }
      return false;
    }
    if (element.generate_key === generateKey.VARIATIONS) {
      if (formData[generateKey.VARIATIONS] !== "") {
        return true;
      }
      return false;
    }
    if (element.generate_key === generateKey.MESSAGE_LENGTH) {
      if (
        formData[generateKey.MESSAGE_LENGTH] >=
          messageParams[element.generate_key].min &&
        formData[generateKey.MESSAGE_LENGTH] <=
          messageParams[element.generate_key].max
      ) {
        return true;
      }

      return false;
    }
    return true
  };

  const handleGenericFormChange = (key, event) => {
    dispatch(updateFormData({ key: key, value: event.target.value }));
  };

  useEffect(() => {
    let status = false;
    for (let i = 0; i < content.length; i++) {
      const element = content[i];
      status = checkValidation(element);
      if (!status) {
        break;
      }
    }

    if (!status) {
      dispatch(updateShowNextButton(false));
    } else {
      dispatch(updateShowNextButton(true));
    }
  }, [formData]);

  const renderContent = (data) => {
    if (data.generate_key === generateKey.EMAIL_CTA){

      return (
        // <Grid item xs={12} sx={{ marginBottom: "20px" }}>
        //   <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
        //     {data.title}
        //   </Typography>
        //   <Box display="flex" justifyContent="flex-start" alignItems="center">
        //     <Grid item xs={12}>
        //       <MultiSelect
        //         items={emailCTA.map((item) => ({
        //           value: item,
        //           label: item,
        //         }))}
        //         selectedItems={formData[data.generate_key]}
        //         handleChange={handleEmailCTASelectChange}
        //       />
        //     </Grid>
        //   </Box>
        // </Grid>
        <Grid item xs={12} sx={{ marginBottom: "20px" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
            {data.title}
          </Typography>
          <Grid item xs={12}>
          
             <SingleSelectDark
                list={emailCTA.map((item) => ({
                  value: item,
                  label: item,
                }))}
                value={formData[data.generate_key]}
                onChange={(e) =>  handleGenericFormChange(data.generate_key, e)}
                />
          </Grid>
        </Grid>
      );
    }

    if (data.generate_key === generateKey.MESSAGE_LENGTH) {
      return (
        <Grid item xs={12} sx={{ marginBottom: "20px" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
            {data.title}
          </Typography>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Grid item xs={7}>
              <Typography sx={{ fontSize: "12px" }}>
                {messageParams[data.generate_key].sub_title}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <TextInput
                type={"number"}
                width={"50px"}
                min={messageParams[data.generate_key].min}
                max={messageParams[data.generate_key].max}
                onChange={(e) => handleWordLimitChange(e, data.generate_key)}
                value={formData[data.generate_key]}
              />
            </Grid>
          </Box>
        </Grid>
      );
    }

    if (data.generate_key === generateKey.VARIATIONS) {
      return (
        <Grid item xs={12} sx={{ marginBottom: "20px" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
            {data.title}
          </Typography>
          <RadioGroup
            value={formData[data.generate_key]}
            onChange={handleChange}
            sx={{ flexDirection: "row" }}
          >
            {data.options.map((item) => (
              <FormControlLabel
                key={item}
                value={item}
                control={<Radio />}
                label={item}
                componentsProps={{
                  typography: { fontSize: "12px" },
                }}
              />
            ))}
          </RadioGroup>
        </Grid>
      );
    }

    if (data.type === fieldType.DROPDOWN) {
      return (
        <Grid item xs={12} sx={{ marginBottom: "20px" }}>
           <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
            {data.title}
          </Typography>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
                <SingleSelectDark
                isSelect={true} 
                list={tonality.map((item) => ({
                  value: item,
                  label: item.name,
                }))}
                value={formData[data.generate_key]}
                onChange={(e) =>  handleGenericFormChange(data.generate_key, e)}
                />
            </Grid>
          </Box>
        </Grid>
      );
    }

    if (data.type === fieldType.RADIO_SELECT) {
      <Grid item xs={12} sx={{ marginBottom: "20px" }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
          {data.title}
        </Typography>
        <RadioGroup
          value={formData[data.generate_key]}
          onChange={(e) => handleGenericFormChange(data.generate_key, e)}
          sx={{ flexDirection: "row" }}
        >
          {data.options.map((item) => (
            <FormControlLabel value={item} control={<Radio />} label={item} />
          ))}
        </RadioGroup>
      </Grid>;
    }

    if (data.fieldType === fieldType.FORM_INPUT) {
      return (
        <Grid item xs={12} sx={{ marginBottom: "20px" }}>
          <strong>{data.title}</strong>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Grid item xs={5}>
              <Typography sx={{ fontSize: "12px" }}>
                {messageParams[data.generate_key].sub_title}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <PlInput
                type={"number"}
                width={"50px"}
                min={messageParams[data.generate_key].min}
                max={messageParams[data.generate_key].max}
                onChange={(e) => handleGenericFormChange(data.generate_key, e)}
                value={formData[data.generate_key]}
              />
            </Grid>
          </Box>
        </Grid>
      );
    }
    if (data.fieldType === fieldType.MULTI_SELECT)
      return (
        <Grid item xs={12} sx={{ marginBottom: "20px" }}>
          <strong>{data.title}</strong>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
              <MultiSelect
                items={emailCTA.map((item) => ({
                  value: item,
                  label: item,
                }))}
                selectedItems={formData[data.generate_key]}
                handleChange={handleEmailCTASelectChange}
              />
            </Grid>
          </Box>
        </Grid>
      );

  };

  return (
    <Grid container rowSpacing={2}>
      {content.map((data) => renderContent(data))}
    </Grid>
  );
};

export default EmailBuilderVariantScreen;
