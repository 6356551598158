import mixpanel from "mixpanel-browser"
import { store } from "../../redux/store"
import { getAuthData } from "../../services/auth"
import { eventNames } from "./constants"

export const sendCopyMessageEventData = (questionData) =>{
    
    const data = {
        clientId: getAuthData()?.client.id || "",
        userId: getAuthData()?.id || "",
        questionData: questionData
    }    
   mixpanel.track(eventNames.COPY_MESSAGE, data)
}