import React, { useState } from "react";
import { Box, Link, Table, Typography } from "@mui/material";
import CheckboxComponent from "../common/CheckboxComponent";
import "../../css/InsightsData.css";
import LinkImage from "../../assets/link.svg";

const CaseStudyItem = ({ data, keyMapping, checked, handleChange }) => {
  const [expandedItems, setExpandedItems] = useState({});
  const [expandedDescription, setExpandedDescription] = useState(false);

  const handleExpandClick = (key) => {
    setExpandedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleExpandDescriptionClick = () => {
    setExpandedDescription(!expandedDescription);
  };

  const keyMappingValues = ["title", "short_description", "url", "uid"];

  const renderContent = (key) => {
    if (Array.isArray(data[key])) {
      if (data[key].length > 3) {
        const visibleItems = expandedItems[key]
          ? data[key]
          : data[key].slice(0, 3);
        const hiddenItemCount = data[key].length - visibleItems.length;
        return (
          <>
            {visibleItems.join(", ")}
            {hiddenItemCount > 0 && (
              <Link
                component="button"
                onClick={() => handleExpandClick(key)}
                sx={{
                  fontSize: "12px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                {`+${hiddenItemCount} more`}
              </Link>
            )}
            {expandedItems[key] && (
              <Link
                component="button"
                onClick={() => handleExpandClick(key)}
                sx={{
                  fontSize: "12px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                View Less
              </Link>
            )}
          </>
        );
      } else {
        return data[key].join(", ");
      }
    }
    return data[key];
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 , padding: "8px 0"}}>
      <CheckboxComponent
        checked={checked}
        handleChange={handleChange}
        label={
          <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
            <strong style={{marginRight: "20px"}}>{data.title}</strong>
            <a href={data.url} target="_blank" rel="noopener noreferrer">
              <img src={LinkImage} alt="Link" />
            </a>
            <Table sx={{margin: "16px 0"}}>
              <tbody>
                {Object.keys(keyMapping).map((key, index) => {
                  return (
                    !keyMappingValues.includes(key) &&
                    data[key] && (
                      <tr key={index}>
                        <td className="case_study_key">{keyMapping[key]}:</td>
                        <td className="case_study_value">
                          {renderContent(key)}
                        </td>
                      </tr>
                    )
                  );
                })}
              </tbody>
            </Table>
            {data.short_description.length > 100 ? (
              <>
                <span>
                  {expandedDescription
                    ? data.short_description
                    : `${data.short_description.slice(0, 97)}...`}
                </span>
                <Link
                  component="button"
                  onClick={handleExpandDescriptionClick}
                  sx={{
                    ml: 1,
                    fontSize: "12px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    textDecoration: "none",
                  }}
                >
                  {expandedDescription ? "View less" : "View more"}
                </Link>
              </>
            ) : (
              <span>{data.short_description}</span>
            )}
          </Typography>
        }
      />
    </Box>
  );
};

export default CaseStudyItem;
