import React, { memo } from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel, styled } from "@mui/material";
import { CheckBox } from "@mui/icons-material";

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.light.primary,
  height: ".4em",
  fontSize: "10px",
  paddingTop: "9px",
  "& .MuiSvgIcon-root": {
    borderRadius: "85px",
  },
}));

function CheckBoxComponent(props) {
  return (
    <FormControlLabel
      sx={{
        alignItems: "flex-start",
      }}
      control={
        <CustomCheckbox
          checked={props.checked}
          onChange={props.handleChange}
          sx={{ borderRadius: "30px" }}
        />
      }
      label={props.label}
    />
  );
}

CheckBox.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
  label: PropTypes.any
};

export default memo(CheckBoxComponent);
