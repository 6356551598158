import CommonDialog from "./CommonDialog";
import ExitIcon from "../../../assets/exit.svg";
import { useTheme } from "@mui/styles";
import { Box, Grid } from "@mui/material";
import PrimaryButton from "../Buttons/PrimaryButton";
import WhiteButton from "../Buttons/WhiteButton";
import { logout } from "../../../services/auth";

const ExitDialog = ({ open, handleClose }) => {
  const theme = useTheme();

  const handleLogout = () =>{
    logout();
    window.location.reload()
  }

  return (
    <CommonDialog open={open} handleClose={handleClose}>
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.light.purpleLight}`,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          padding: "16px",
        }}
      >
        <Grid xs={12}>
          <img src={ExitIcon} alt="exit" />
        </Grid>
        <Grid xs={12}>
          <span>Oh no! you are leaving...</span>
        </Grid>
        <Grid xs={12}>
          <span>Are you sure?</span>
        </Grid>
      </Box>

      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          padding: 4
        }}
      >
        <Grid container gap={2}>
          <Grid item xs={12}>
            <PrimaryButton onClick={handleClose} sx={{ width: "100%" }}>
              Naah, Just Kidding
            </PrimaryButton>
          </Grid>
          <Grid item xs={12}>
            <WhiteButton
              sx={{
                width: "100%",
                border: `1px solid ${theme.palette.light.primary}`,
                fontWeight: 500,
              }}
              onClick={handleLogout}
            >
              Yes, Log Me Out
            </WhiteButton>
          </Grid>
        </Grid>
      </Box>
    </CommonDialog>
  );
};

export default ExitDialog;
