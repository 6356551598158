import { makeGetRestCall, makePostRestCall } from "./httpServices";

export const getValueProps = async ( url) => {
    return makeGetRestCall(`/v1/valueprops`);
 };

 export const getEmailCTA = async()=>{
    return makeGetRestCall("/v1/email/cta")
 }

 export const getMessageParamsFunc = async(data)=>{
   return makePostRestCall("/v1/message/params", data)
 }

 export const messageGenerator = async(param, data)=>{
   return makePostRestCall(`/v1/content/generator/${param}`, data)
 }

 export const getCaseStudy = async(data)=>{
  return makePostRestCall(`/v1/casestudy`, data)
 }

 export const getTonality = async()=>{
  return makeGetRestCall("/v1/tone")
}
