import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "../../../assets/dropdown.svg";
import PropTypes from "prop-types";
import { fieldType, generateKey } from "../../../utils/constants";
import ValueProps from "./ValueProps";
import InsightsData from "./InsightsData";
import Instructions from "./Instructions";
import { useDispatch, useSelector } from "react-redux";
import {
  getCaseStudy,
  getMessageParamsFunc,
} from "../../../services/customMessage";
import {
  setMessageParams,
  updateFormData,
  updateNextButtonStatus,
} from "../../../redux/customMessage";
import {
  extractLinkedInUID,
  fetchSimilarCustomer,
} from "../../../services/extractServices";
import { v4 as uuidv4 } from "uuid";
import { fetchNewInsight, getPosts } from "../../../services/insightServices";
import { questionTypes, tagTypes } from "../../../utils/constants";
import { getAuthData } from "../../../services/auth";

const AccordionParent = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.light.borderColor}`,
  width: "100%",
  boxShadow: "none",
  marginBottom: "10px",
  borderRadius: "4px",
}));

const ContentDropdown = ({ content, title, generate_key, maxLimit }) => {
  const messageParams = useSelector(
    (state) => state.customMessageReducer.messageParams
  );

  const formData = useSelector((state) => state.customMessageReducer.formData);
  const currentMessageType = useSelector(
    (state) => state.customMessageReducer.currentMessageType
  );

  const calledPostAndCommentsForSeedApi = useSelector(
    (state) => state.customMessageReducer.calledPostAndCommentsForSeedApi
  );



  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateNextButtonStatus());
  }, [formData.value_props]);

  const getMessageParams = async () => {
    const res = await getMessageParamsFunc({
      key: currentMessageType,
    });

    Object.keys(res).map((key) => {
      if (res[key].default && formData[key] === "") {
        dispatch(updateFormData({ key: key, value: res[key].default }));
      }
    });
    dispatch(setMessageParams(res));
  };

  useEffect(() => {
    getMessageParams();
  }, []);

  const [insights, setInsights] = useState({
    persona_insights: [],
    company_insights: [],
    posts: [],
    similar_customers: [],
    case_study: [],
  });

  const [dataFetched, setDataFetched] = useState(false);

  const questions = useSelector((state) => state.questionReducer.questions);

  const fetchCompanyInsights = useCallback(
    async (personaInsights, companyInsights) => {
      if (calledPostAndCommentsForSeedApi) {
        try {
          const uidPeople = await extractLinkedInUID(
            "people",
            formData.person_linkedin_url
          );
          const uidCompany = await extractLinkedInUID(
            "company",
            formData.company_linkedin_url
          );

          dispatch(updateFormData({ key: "person_uuid", value: uidPeople }));
          dispatch(updateFormData({ key: "company_uuid", value: uidCompany }));

          const promiseList = [];

          // console.log(
          //   getAuthData().client,
          //   process.env.REACT_APP_LAMBDATEST_ID,
          //   "hello"
          // );
          if (getAuthData().client.id == process.env.REACT_APP_LAMBDATEST_ID) {
            fetchSimilarCustomer(uidCompany)
              .then((res) => {
                // console.log(res, "similar_customers");
                const messages = res.response?.map((customer, index) => {
                  const dealNotes = customer.notes.deal_notes
                    .map((note) => `<li> ${note}</li>`)
                    .join("\n");
                  const data = `<li> Website: ${customer.customer_website}</li>
                  <li> Country: ${customer.customer_country}</li>
                  <li> LinkedIn: ${customer.customer_linkedin_url}</li>
                  <li> Industry: ${customer.customer_industry}</li>
                  <li> Company Size: ${customer.customer_company_size}</li>`;
                  const content = `\n\n **Basic Info:**\n${data}
                  \n\n **Deal Notes:**\n ${dealNotes}
                  `;
                  return {
                    title: `Customer ${index + 1} - ${
                      customer.customer_website
                    }`,
                    content: [content],
                    type: questionTypes.COMPANY_INSIGHTS,
                    similar_customers: "This is a similar customer",
                  };
                });
                setInsights((prev) => ({
                  ...prev,
                  similar_customers: messages,
                }));
              })
              .catch((e) => {
                setInsights((prev) => ({
                  ...prev,
                  similar_customers: [],
                }));
              });
          }

          getCaseStudy({
            person_uuid: uidPeople,
            company_uuid: uidCompany,
          })
            .then((response) => {
              setInsights((prevInsights) => ({
                ...prevInsights,
                case_study: {
                  ...response,
                  data: response.data.map((item) => ({
                    ...item,
                    type: questionTypes.CASE_STUDY,
                  })),
                },
              }));
            })
            .catch((error) => {
              setInsights((prevInsights) => ({
                ...prevInsights,
                case_study: {},
              }));
            });

          getPosts({
            key: "people",
            uid: uidPeople,
          }).then((res) => {
            setInsights((prev) => ({
              ...prev,
              posts: res,
            }));
          });

          const h_idPerson = uuidv4();
          Object.keys(personaInsights?.email_params).forEach((key) => {
            const requestId = uuidv4();

            const currentPromise = fetchNewInsight(
              "people",
              uidPeople,
              key,
              h_idPerson,
              requestId,
              tagTypes.PEOPLE_INSIGHTS
            ).then((res) => {
              const newAnswer =
                res.response.length > 0 ? [res.response[0].content] : [];
              setInsights((prev) => ({
                ...prev,
                persona_insights: prev.persona_insights.map((item) =>
                  item.key === key
                    ? { ...item, content: newAnswer, isLoading: false }
                    : item
                ),
              }));
            });
            promiseList.push(currentPromise);
          });

          const h_idCompany = uuidv4();
          Object.keys(companyInsights?.email_params).forEach((key) => {
            const requestId = uuidv4();
            const currentPromise = fetchNewInsight(
              "company",
              uidCompany,
              key,
              h_idCompany,
              requestId,
              tagTypes.COMPANY_INSIGHTS
            ).then((res) => {
              const newAnswer =
                res.response.length > 0 ? [res.response[0].content] : [];
              setInsights((prev) => ({
                ...prev,
                company_insights: prev.company_insights.map((item) =>
                  item.key === key
                    ? { ...item, content: newAnswer, isLoading: false }
                    : item
                ),
              }));
            });
            promiseList.push(currentPromise);
          });

          Promise.all(promiseList)
            .then((res) => {
              setDataFetched(true);
            })
            .catch((e) => {
              console.error("Error fetching insights", e);
            });
        } catch (error) {
          console.log(error);
        }
      }
    },
    [calledPostAndCommentsForSeedApi]
  );

  useEffect(() => {
    if (!dataFetched && content.generate_key === generateKey.INSIGHTS) {
      const personaInsights = questions.find(
        (item) => item.key === "persona_insights"
      );
      const companyInsights = questions.find(
        (item) => item.key === "company_insights"
      );

      const initialPersonaQA = Object.keys(personaInsights.email_params).map(
        (key) => ({
          title: personaInsights.email_params[key],
          content: [],
          key: key,
          isLoading: true,
          type: questionTypes.PERSONA_INSIGHTS,
        })
      );

      const initialCompanyQA = Object.keys(companyInsights.email_params).map(
        (key) => ({
          title: companyInsights.email_params[key],
          content: [],
          key: key,
          isLoading: true,
          type: questionTypes.COMPANY_INSIGHTS,
        })
      );

      setInsights({
        persona_insights: initialPersonaQA,
        company_insights: initialCompanyQA,
        posts: Array.from({ length: 10 }, () => ({
          isLoading: true,
        })),
        similar_customers: Array.from({ length: 10 }, () => ({
          isLoading: true,
        })),
        case_study: Array.from({ length: 10 }, () => ({
          isLoading: true,
        })),
      });

      fetchCompanyInsights(personaInsights, companyInsights);
    }
  }, []);

  const ContentForm = () => {
    if (
      content.generate_key === generateKey.VALUE_PROPS ||
      content.type === fieldType.FILTER_MULTI_SELECT
    ) {
      return <ValueProps filterData={content.filters} maxLimit={maxLimit} />;
    }

    if (
      content.generate_key === generateKey.INSIGHTS ||
      content.type === fieldType.AI_INSIGHTS
    ) {
      return (
        <InsightsData
          sequence={content.sequence}
          insights={insights}
          maxLimit={maxLimit}
        />
      );
    }

    if (
      content.generate_key === generateKey.INSTRUCTION ||
      content.type === fieldType.TEXT_FORM
    ) {
      return (
        <Instructions
          content={content}
          messageParams={messageParams[content.generate_key]}
          generate_key={content.generate_key}
        />
      );
    }

    return null;
  };

  const generateLength = () => {
    let contentLength = [];
    if (content.generate_key === "insights") {
      Object.keys(formData[content.generate_key]).forEach((key) => {
        contentLength = [
          ...formData[content.generate_key][key],
          ...contentLength,
        ];
      });
    } else {
      contentLength = formData[content.generate_key];
    }
    return contentLength.length;
  };

  return (
    <>
      <AccordionParent>
        <AccordionSummary
          expandIcon={<img src={ExpandMoreIcon} alt="Expand" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid
            xs={12}
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid xs={8} item>
              <Typography sx={{ fontSize: "14px" }}>{title}</Typography>
            </Grid>
            <Grid xs={4} item>
              {maxLimit && (
                <Typography sx={{ fontSize: "10px" }}>
                  Selected Options {generateLength()}/{maxLimit}
                </Typography>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ minHeight: "300px" }}>
          <ContentForm />
        </AccordionDetails>
      </AccordionParent>
    </>
  );
};

ContentDropdown.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ContentDropdown;
