import React, { useState } from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { epochToDate } from "../../utils/commonFunctions";
import CheckboxComponent from "../common/CheckboxComponent";
import LinkImage from "../../assets/link.svg";

function LinkRenderer(props) {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
}

function ParagraphRenderer({ node, ...props }) {
  return <p {...props}>{props.children}</p>;
}

const PostItem = ({ data, checked, handleChange }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  const getPostTypeText = () => {
    if (data.post) {
      return "Posted On";
    }
    if (data.comment) {
      return "Commented On";
    }
    if (data.is_repost) {
      return "Reposted On";
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
      <CheckboxComponent
        checked={checked}
        handleChange={handleChange}
        label={
          <Grid container xs={12}>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                {`${getPostTypeText()} ${
                  epochToDate(data.post_date) || "N/A"
                } `}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <a href={data.post_url} target="_blank" rel="noopener noreferrer">
                <img src={LinkImage} alt="Link" />
              </a>
            </Grid>

            <Grid item xs={12}>
            <Typography sx={{ fontSize: "12px", paddingRight: "8px" }}>
              {expanded || data.post_text.length <= 220 ? (
                <ReactMarkdown
                  children={data.post_text}
                  rehypePlugins={[rehypeRaw]}
                  remarkPlugins={[remarkGfm]}
                  components={{ a: LinkRenderer, p: ParagraphRenderer }}
                />
              ) : (
                <ReactMarkdown
                  children={`${data.post_text.slice(0, 217)}...`}
                  rehypePlugins={[rehypeRaw]}
                  remarkPlugins={[remarkGfm]}
                  components={{ a: LinkRenderer, p: ParagraphRenderer }}
                />
              )}
              {data.post_text.length > 220 && (
                <Link
                  component="button"
                  onClick={handleExpandClick}
                  sx={{
                    ml: 1,
                    fontSize: "12px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    textDecoration: "none",
                  }}
                >
                  {expanded ? "View less" : "View more"}
                </Link>
              )}
            </Typography>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default PostItem;
