import { Box, Divider, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  callSeedApiForCompany,
  callSeedApiForPeople,
  extractCompanyUrls,
} from "../../../services/extractServices";
import PlInput from "../../common/PlInput";
import CustomInput from "../../common/CustomInput";
import PrimaryButton from "../../common/Buttons/PrimaryButton";
import {
  updateCompanyUrls,
  updateCurrentMessageType,
  updateFormData,
  updateShowNextButton,
  updatecalledPostAndCommentsForSeedApi,
} from "../../../redux/customMessage";
import { isNotEmpty, isValidUrl } from "../../../utils/validations";
import { fieldType, linkedinurlTypes } from "../../../utils/constants";
import { toast } from "react-toastify";
// import { linkedinurlTypes } from "../../../utils/constants";

const EmailBuilderPersonaDetails = () => {
  // const {
  //   // formData,
  //   // inputValues,
  //   // errors,
  //   // handleChange,
  //   // setInputValues,
  //   // setFormData,
  // } = useFormData(formDataValues, mappedInputValues);

  const formDataValues = useSelector(
    (state) => state.insightReducer.currentQuestion.fields
  );

  const { currentUrl, currentUrlType } = useSelector(
    (state) => state.insightReducer
  );

  const reduxFormValues = useSelector(
    (state) => state.customMessageReducer.formData
  );

  const companyUrls = useSelector(
    (state) => state.customMessageReducer.companyUrls
  );
  const currentMessageType = useSelector(
    (state) => state.customMessageReducer.currentMessageType
  );

  const fieldToReduxKeyMapping = {
    "Person Linkedin URL": "person_linkedin_url",
    "Company Name": "company_linkedin_url",
  };

  const checkAllValuesValid = () => {
    let allValid = true;

    formData.forEach((field) => {
      if (field.reqired) {
        const isValid = validateField(field.name, inputValues[field.name]);
        if (!isValid) allValid = false;
      }
    });
    return allValid;
  };

  const getPersonUrl = () => {
    if (reduxFormValues?.person_linkedin_url) {
      return reduxFormValues?.person_linkedin_url;
    }
    if (currentUrlType === linkedinurlTypes.PROFILE) {
      return currentUrl;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
    validateField(name, value);
  };

  const [formData, setFormData] = useState(formDataValues);
  const [inputValues, setInputValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const mappedInputValues = {
      "Person Linkedin URL": getPersonUrl(),
      "Company Name": reduxFormValues?.company_linkedin_url || "",
      "Message Type": currentMessageType || "",
    };
    setInputValues(mappedInputValues);
    return () => {
      setInputValues({});
    };
  }, []);

  const dispatch = useDispatch();
  const [showCompanyInput, setShowCompanyInput] = useState(false);
  // const [companyUrls, setCompanyUrls] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [applyButtonLoad, setApplyButtonLoading] = useState(false);

  const person_linkedin_url = reduxFormValues.person_linkedin_url;
  const company_linkedin_url = reduxFormValues.company_linkedin_url;
  const validateField = (name, value) => {
    let errorMsg = "";
    const field = formData.find((f) => f.name === name);
    if (field) {
      if (field.type === fieldType.URL && !isValidUrl(value)) {
        errorMsg = "Invalid URL";
      } else if (field.reqired && !isNotEmpty(value)) {
        errorMsg = "This field is required";
      }
    }
    setErrors((prev) => ({ ...prev, [name]: errorMsg }));
    return errorMsg === "";
  };
  useEffect(() => {
    if (
      person_linkedin_url !== "" &&
      company_linkedin_url !== "" &&
      currentMessageType !== ""
    ) {
      if (!selectedCompany) {
        let contained = false;
        companyUrls.forEach((element) => {
          if (element.LinkedinURL === company_linkedin_url) {
            contained = true;
          }
        });
        if (contained) {
          setSelectedCompany(company_linkedin_url);
        } else {
          setSelectedCompany("other");
          setShowCompanyInput(true);
        }
      }

      dispatch(updateShowNextButton(true));
    } else {
      dispatch(updateShowNextButton(false));
    }
    if (!company_linkedin_url && !currentMessageType) {
      setShowCompanyInput(false);
      setInputValues((prev) => ({
        ...prev,
        "Company Name": "",
        "Message Type": currentMessageType,
      }));
    }
  }, [person_linkedin_url, company_linkedin_url, currentMessageType]);

  const handlePersonaData = async () => {
    const linkedInUrl = inputValues["Person Linkedin URL"];
    dispatch(updatecalledPostAndCommentsForSeedApi(false));
    try {
      const extractResponse = await callSeedApiForPeople(linkedInUrl);
      return extractResponse;
    } catch (error) {
      if (error.status == 401) {
        // handleFetchError(error);
        //to do handle 401 error in interceptors
      } else {
        // toast.error(
        //   "Error fetching data, please try again later",
        //   "Error",
        //   3000
        // );
      }
      return error;
    }
  };

  useEffect(() => {
    for (const [field, value] of Object.entries(inputValues)) {
      const reduxKey = fieldToReduxKeyMapping[field];
      if (reduxKey) {
        dispatch(updateFormData({ key: reduxKey, value }));
      } else if (field === "Message Type") {
        dispatch(updateCurrentMessageType(value));
      }
    }
  }, [inputValues]);

  useEffect(() => {
    if (reduxFormValues?.company_linkedin_url) {
      setShowCompanyInput(true);
    }
    // setInputValues(mappedInputValues);
  }, []);

  const onClickApply = async () => {
    if (checkAllValuesValid()) {
      setApplyButtonLoading(true);
      let res;
      try {
        res = await handlePersonaData();
        if (res.company_urls.length > 0) {

          dispatch(updateCompanyUrls(res.company_urls || []));
          setInputValues((prev) => ({
            ...prev,
            "Company Name": "",
          }));

          setFormData((prev) => [
            ...prev,
            {
              name: "Company Name",
              type: "URL",
              data_type: "text",
              required: true,
              placeholder: "https://www.linkedin.com/company/microsoft",
              disable: true,
            },
          ]);
          const companyUrlsList = res.company_urls || [];

          setInputValues((prev) => ({
            ...prev,
            "Company Name": companyUrlsList[0]?.LinkedinURL,
          }));
          dispatch(
            updateFormData({
              key: "company_linkedin_url",
              value: companyUrlsList[0]?.LinkedinURL || "",
            })
          );

          if (companyUrlsList.length > 0) {
            setSelectedCompany(companyUrlsList[0]?.LinkedinURL);
          } else {
            setSelectedCompany("other");
          }

          setShowCompanyInput(true);
        }else{
          throw new Error()
        }
      
      } catch (e) {
        console.log(e);
        toast.error("Sorry, we could not fetch data for this profile!");
      } finally {
        setApplyButtonLoading(false);
      }
    }
  };

  const handleCompanyChange = (e) => {
    setSelectedCompany(e.target.value);

    setInputValues((prev) => ({
      ...prev,
      "Company Name": e.target.value !== "other" ? e.target.value : "",
    }));
    dispatch(
      updateFormData({
        key: "company_linkedin_url",
        value: e.target.value !== "other" ? e.target.value : "",
      })
    );
  };

  useEffect(() => {
    if (currentUrlType === linkedinurlTypes.PROFILE)
      setInputValues((prev) => ({
        ...prev,
        "Person Linkedin URL": currentUrl,
      }));
  }, [currentUrl]);

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        {formData.map(
          (field) =>
            !field.disable &&
            (field.type === "SINGLE-SELECT" ? (
              <PlInput
                key={field.name}
                id={field.name}
                name={field.name}
                label={field.name}
                placeholder={field.placeholder}
                type="select"
                isSelect={true}
                list={field.option.map((option) => ({
                  value: option,
                  label: option,
                }))}
                value={inputValues[field.name] || ""}
                onChange={handleChange}
                required={field.reqired}
                showError={true}
                isValid={!errors[field.name]}
                errorMsg={errors[field.name]}
              />
            ) : (
              <PlInput
                key={field.name}
                id={field.name}
                name={field.name}
                label={field.name}
                placeholder={field.placeholder}
                type={field.type}
                value={inputValues[field.name] || ""}
                onChange={handleChange}
                required={field.reqired}
                showError={true}
                isValid={!errors[field.name]}
                errorMsg={errors[field.name]}
              />
            ))
        )}
      </Grid>
      {showCompanyInput && (
        <>
          <Grid item xs={12}>
            <Divider
              sx={{
                borderStyle: "dashed",
                borderColor: (theme) => theme.palette.primary.dark.disableColor,
                marginBottom: "10px",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <PlInput
              id={"companyDropdown"}
              name={"companyDropdown"}
              label={"Company Name"}
              placeholder={"https://www.linkedin.com/company/microsoft"}
              type="select"
              isSelect={true}
              list={[
                ...companyUrls.map((option) => ({
                  label: option.Company,
                  value: option.LinkedinURL,
                })),
                { label: "Other", value: "other" },
              ]}
              value={selectedCompany}
              onChange={handleCompanyChange}
              required={true}
              showError={true}
              isValid={!errors["Company Name"]}
              errorMsg={errors["Company Name"]}
            />
            {selectedCompany === "other" && (
              <CustomInput
                id={"Company Name"}
                name={"Company Name"}
                label={"Company Name"}
                placeholder={"Write Company LinkedIn URL"}
                type="URL"
                isSelect={false}
                value={inputValues["Company Name"] || ""}
                onChange={handleChange}
                required={true}
                showError={true}
                isValid={!errors["Company Name"]}
                errorMsg={errors["Company Name"]}
                variant="standard"
                showBorder={false}
                showIcon={false}
                // icon={SearchIcon}
              />
            )}
          </Grid>
        </>
      )}
      {!showCompanyInput && currentMessageType && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <PrimaryButton onClick={onClickApply} loading={applyButtonLoad}>
              GET COMPANY
            </PrimaryButton>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default EmailBuilderPersonaDetails;
