import {
  Box,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import LoaderSkeleton from "./LoadingSkeleton";
import CheckboxComponent from "./CheckboxComponent";
import ContentItem from "../EmailBuilderStepper/ContentItemInInsights";
import PostItem from "../EmailBuilderStepper/PostItemInInsights";
import { containsObject } from "../../utils/commonFunctions";
import { memo } from "react";
import PropTypes from "prop-types";
import CaseStudyItem from "../EmailBuilderStepper/CaseStudyInInsights";
import NoData from "./LottieAnimations/NoData";

const MultiSelect = ({
  items,
  selectedItems,
  handleChange,
  showMandateSwitch,
  maxWidth,
}) => {
  const NoDataResults = () => {
    return (
      <Box
        sx={{
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px"
        }}
      >
        <NoData height={120} width={120} text={"No Results Found"}/>
      </Box>
    );
  };
  if (!Array.isArray(items)) {
    return (
      <Box
        sx={{
          overflowY: "scroll",
        }}
      >
        {items?.data?.length > 0 ? (
          items.data.map((data, ind) => (
            <MenuItem
              key={ind}
              sx={{
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                padding: ".2rem 0",
                maxWidth: maxWidth ? maxWidth : "100%",
                textWrap: "wrap",
                minHeight: "30px",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
              >
                {data.isLoading ? (
                  <LoaderSkeleton />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      width: "100%",
                      flexGrow: 1,
                    }}
                  >
                    <CaseStudyItem
                      data={data}
                      checked={containsObject(data, selectedItems)}
                      keyMapping={items.mapping}
                      handleChange={() => handleChange(data)}
                    />
                  </Box>
                )}
              </Box>
            </MenuItem>
          ))
        ) : (
          <NoDataResults/>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        overflowY: "scroll",
      }}
    >
      {items.length > 0 ? (
        items.map((data, ind) => (
          <MenuItem
            key={ind}
            sx={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: ".2rem 0",
              maxWidth: maxWidth ? maxWidth : "100%",
              textWrap: "wrap",
              minHeight: "30px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
              {data.isLoading ? (
                <LoaderSkeleton />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    flexGrow: 1,
                  }}
                >
                  {data.label ? (
                    <>
                      <CheckboxComponent
                        checked={
                          selectedItems &&
                          selectedItems.indexOf(data.value) > -1
                        }
                        handleChange={() => handleChange(data)}
                        label={
                          <Typography sx={{ fontSize: "13px" }}>
                            {data.label}
                          </Typography>
                        }
                      />
                    </>
                  ) : data.content && data.content.length > 0 ? (
                    <ContentItem
                      title={data.title}
                      content={data.content}
                      checked={containsObject(data, selectedItems)}
                      handleChange={() => handleChange(data)}
                    />
                  ) : data.post_url ? (
                    <PostItem
                      data={data}
                      checked={containsObject(data, selectedItems)}
                      handleChange={() => handleChange(data)}
                    />
                  ) : data.mapping ? (
                    <CaseStudyItem
                      data={data}
                      checked={containsObject(data, selectedItems)}
                      handleChange={() => handleChange(data.data)}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              )}
              {selectedItems.indexOf(data) > -1 && showMandateSwitch && (
                <FormControlLabel
                  control={<Switch size="small" sx={{ cursor: "pointer" }} />}
                  label={
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: (theme) => theme.palette.light.fontGreyLight,
                      }}
                    >
                      Mandatory to include in output
                    </Typography>
                  }
                  sx={{ marginTop: "8px", marginLeft: "0" }}
                />
              )}
            </Box>
          </MenuItem>
        ))
      ) : (
        <NoDataResults/>
      )}
    </Box>
  );
};

MultiSelect.propTypes = {
  items: PropTypes.array || PropTypes.object,
  selectedItems: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  showMandateSwitch: PropTypes.bool,
  maxWidth: PropTypes.string,
};

export default memo(MultiSelect);
