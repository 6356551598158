import React, { useEffect, useMemo, useState } from "react";
// mui
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
// components
import OverlayLoader from "../components/OverlayLoader"
// icons
import GoogleIcon from "../assets/google.svg";
// services
import { isAuth, login, setAuthTokenInLocalStorage } from "../services/auth";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";
import '../css/NewUser.css';
import Pintel_Logo from '../assets/pintel_light.svg'
import axios from "axios";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() =>
  createStyles({
    pintelForm:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        height:'70vh',
        margin:'auto !important'
    },
    topgap20: {
      marginTop: "20px!important",
    },
    plBtn: {
      padding: "8px 16px",
      width: "100%",
      fontSize: 14,
      fontWeight: 600,
    },
    btnGoogle: {
      height: 40,
      width: "100%",
      backgroundColor: "#fff",
      fontSize: 15,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid",
      cursor: "pointer",
      borderRadius: 4,
      "& img": {
        marginRight: 13,
        width: 15,
      },
    },
  })
);
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
function LogIn({setIsLoggedIn}) {
  const classes = useStyles();

  const [state, setState] = useState({
    email: "",
    password: "",
    showError: false,
    snackbarMessage: "",
    showSnackbar: false,
    loading: false,
  });
  const {
    email,
    password,
    showError,
    snackbarMessage,
    showSnackbar,
    keepMeLoggedIn,
    loading,
  } = state;

  const handleSnackbarClose = () => {
    setState((prev) => ({ ...prev, showSnackbar: false }));
  };

  const googleSignIn = useGoogleLogin({
    onSuccess: (codeResponse) => googleAuth(codeResponse.access_token),
    onError: (error) => console.log("Login Failed:", error),
  });

  const googleAuth = (access_token) => {
    axios
      .post("https://api.pintel.ai/apis/v1/google/signin", {
        access_token,
        source:'extension'
      })
      .then(function (res) {
        setState((prev) => ({
          ...prev,
          showSnackbar: true,
          snackbarMessage: "Success",
        }));
        login(
          JSON.stringify({ ...res.data.user, token: res.headers.auth_token })
        );
        setAuthTokenInLocalStorage(res.headers.auth_token)
        setIsLoggedIn(true)
      })
      .catch(function (error) {
        setState((prev) => ({
          ...prev,
          showSnackbar: true,
          snackbarMessage: "Error",
        }));
        console.log(error, "err");
      });
  };

  // useEffect(() => {
  //     setLoadingUrl(true)
  //     const urlParam = query.get('url');
  //     const typeParam = query.get('type');
  
  //     if (urlParam) setCurrentUrl(decodeURIComponent(urlParam));
  //     if (typeParam) setType(typeParam);
  //     setLoadingUrl(false)
  // }, [query]);

  return (
    <div className="auth-container">
      <OverlayLoader show={loading} />
      <img src={Pintel_Logo} height={35} style={{margin:'10px'}}/>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showSnackbar}
        autoHideDuration={4000}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
      <Grid container className="auth-wrapper">
        <Grid item md={4}>
          &nbsp;
        </Grid>
        <Grid item md={4} className={classes.pintelForm}>
          <Grid container>
            <Grid item md={1}></Grid>
            <Grid item md={10}>
            <div>
                <h1 className='title'>Hello,</h1>
                <div className='subtitle'>Please sign in to explore the insigths</div>
            </div>
              <div className="topgap10">
                <button onClick={googleSignIn} className={classes.btnGoogle}>
                  <img src={GoogleIcon} />
                  Sign in with Google
                </button>
              </div>
            </Grid>
            <Grid item md={1}></Grid>
          </Grid>
          <div className="topgap50">&nbsp;</div>
        </Grid>
        <Grid item md={4}>
          &nbsp;
        </Grid>
      </Grid>
    </div>
  );
}

export default LogIn;
