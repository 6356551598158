import React from "react";
import { Autocomplete, Paper, TextField, styled } from "@mui/material";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: "100%",
  borderRadius: "7px",
  border: "1px solid #D0C9D6",
  boxShadow: "0px 1px 9px -5px gray !important",
  padding: "0",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  fontSize: "12px",
  "& .MuiInputBase-root": {
    height: "auto",
    fontSize: "12px",
    padding: "2px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    top: 0,
    border: "none",
    boxShadow: "0 7px 64px 0 rgba(0, 0, 0, 0.07)",
  },
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.light.borderColor}`,
  maxHeight: "250px",
  overflowY: "scroll",
  "& .MuiAutocomplete-option": {
    fontSize: "12px",
    padding: "0 10px",
    height: "20px",
  },
}));

const ClearableSingleSelect = ({ options, placeholder, value, onChange }) => {
  const defaultProps = {
    options: options,
    getOptionLabel: (option) => option.name,
    placeholder: placeholder,
  };

  return (
    <StyledAutocomplete
      {...defaultProps}
      value={value}
      PaperComponent={(props) => <CustomPaper {...props} />}
      renderInput={(params) => (
        <StyledTextField {...params} placeholder={placeholder} />
      )}
      onChange={onChange}
    />
  );
};

export default ClearableSingleSelect;
