import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import AutoCompleteMultiSelect from "../../AutoCompleteMultiSelect/AutoCompleteMultiSelect";
import TextInput from "../../TextInput";

const MultiDropdownMultiSelect = ({
  itemsList,
  title,
  erroMsg,
  handleParentDataChange,
  values,
}) => {
  const [selectedItems, setSelectedItems] = useState({});

  // useEffect(() => {
  //   setSelectedItems(values);
  // }, [values]);

  const handleChange = (key) => (event, newValue) => {
    const updatedItems = {
      ...selectedItems,
      [key]: newValue,
    };
    setSelectedItems(updatedItems);
    handleParentDataChange(updatedItems);
  };

  const handleInputChange = (key) => (event) => {
    const updatedItems = {
      ...selectedItems,
      [key]: event.target.value,
    };
    setSelectedItems(updatedItems);
    handleParentDataChange(updatedItems);
  };

  return (
    <>
      <div className="flex_title">
        <span className="title_lable">{title}</span>
      </div>
      {itemsList.map((item) => (
        <React.Fragment key={item.key}>
          <Grid container xs={12} sx={{ alignItems: "center" }}>
            <Grid
              item
              xs={4}
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                paddingTop: "0!important",
              }}
            >
              <span style={{ padding: 0 }}>{item.name}</span>
            </Grid>
            <Grid item xs={8}>
              <AutoCompleteMultiSelect
                placeholder={item.placeholder}
                onChange={handleChange(item.key)}
                value={selectedItems[item.key] || []}
                currentKey={item.key}
              />
            </Grid>
          </Grid>

          {item.child && selectedItems[item.key]?.length > 0 && (
            <Grid container xs={12} sx={{ alignItems: "center" }}>
              <Grid
                item
                xs={4}
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingTop: "0!important",
                }}
              >
                <span style={{ padding: 0 }}>{item.child.name}</span>
              </Grid>
              <Grid item xs={8}>
                <TextInput
                  placeholder={item.child.placeholder}
                  onChange={handleInputChange(item.child.key)}
                  value={selectedItems[item.child.key] || ""}
                  currentKey={item.child.key}
                />
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      ))}
        <span className="error_msg">{erroMsg}</span>

    </>
  );
};

export default MultiDropdownMultiSelect;
