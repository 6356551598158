import "./home.css";
import PintelLogo from "../../../assets/icons/logo_small.svg";
import { Divider, useTheme } from "@mui/material";
import { dataObj, lambdatestBtns } from "./constants";
import MessageBtn from "../../buttons/messageBtn";
import { getAuthData } from "../../../services/auth";

const Home = ({ dataObj, questions, handleQuestionClick }) => {
  const theme = useTheme();

  const getColors = (index) => {
    if (index % 3 === 0) {
      return {
        borderColor: theme.palette.messageBtns.primaryStroke,
        background: theme.palette.messageBtns.primaryBackground,
        text: theme.palette.messageBtns.primaryText,
      };
    }
    if (index % 3 === 1) {
      return {
        borderColor: theme.palette.messageBtns.secondaryStroke,
        background: theme.palette.messageBtns.secondaryBackground,
        text: theme.palette.messageBtns.secondaryText,
      };
    }
    if (index % 3 === 2) {
      return {
        borderColor: theme.palette.messageBtns.tertiaryStroke,
        background: theme.palette.messageBtns.tertiaryBackground,
        text: theme.palette.messageBtns.tertiaryText,
      };
    }
  };

  const onQuestionClick = (item) => {
    let content = {};
    questions?.map((data, index) => {
      if (data.key === item.key) {
        content = data;
        return;
      }
    });
    if (!content.key) {
      handleQuestionClick(item, item.outcome);
      return;
    }
    handleQuestionClick(content, content.outcome);
  };

  return (
    <div className="home_body">
      <div className="home_pintel_message">
        <img src={PintelLogo} alt="pintel" />
        <span>
          Hey {getAuthData()?.first_name ?? ""}, I'm your AI sidekick. How can I
          assist you?
        </span>
      </div>
      <Divider sx={{ padding: "8px" }} />
      <div className="home_message_options">
        {getAuthData().client.id == process.env.REACT_APP_LAMBDATEST_ID && (
          <div key={5}>
            <span>Create Automated Messages</span>
            <div>
              <div className="home_btns_container">
                {lambdatestBtns.map((item) => {
                  return (
                    <MessageBtn
                      colors={getColors(2)}
                      onClick={() => onQuestionClick(item)}
                    >
                      {item.name}
                    </MessageBtn>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {dataObj &&
          dataObj?.length &&
          dataObj?.map((data, index) => {
            return (
              <div key={index}>
                <span>{data.title}</span>
                <div>
                  <div className="home_btns_container">
                    {data.buttons?.map((item) => {
                      return (
                        <MessageBtn
                          colors={getColors(index)}
                          onClick={() => onQuestionClick(item)}
                        >
                          {item.name}
                        </MessageBtn>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}

        <div key={6}>
          <span>
            Find high intent prospect and accounts{" "}
            <em style={{ color: theme.palette.primary.light }}>
              Coming Soon...
            </em>
          </span>
          <div>
            <div className="home_btns_container">
              <>
                <MessageBtn colors={getColors(3)}>
                  Find decision makers
                </MessageBtn>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
