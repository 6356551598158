export const USER_DATA_KEY = "pintel_access_token";
export const AUTH_TOKEN_KEY = "auth_token";

export const linkedinurlTypes = {
  PROFILE: "profile",
  COMPANY: "company",
  PEOPLE: "people"
};
export const questionTypes = {
  PERSONA_INSIGHTS: "persona_insights",
  COMPANY_INSIGHTS: "company_insights",
  PERSONA_COMPANY_INSIGHTS: "persona_company_insights",
  WIN_STORY: "win_story",
  SIMILAR_CUSTOMERS: "similar_customers",
  POST_EVENT_EMAIL: "post_event_email",
  CUSTOM_MESSAGE: "custom_message",
  ON_DEMAND_INSIGHTS: "on_demand_insights",
  CASE_STUDY: "case_study",
  LINKEDIN: "linkedin",
  EMAIL: "email",
  AUTOMATED_MESSAGE: "automated_message",
  AUTOMATED_EMAIL: "automated_email"
};

export const tagTypes = {
  PEOPLE_COMPANY_INSIGHTS: "people_company_insights",
  COMPANY_INSIGHTS: "company_insights",
  PEOPLE_INSIGHTS: "people_insights",
  WIN_STORY: "win_story",
  SIMILAR_CUSTOMERS: "similar_customer",
  POST_EVENT_EMAIL: "post_event_email",
  EMAIL: "email",
  LinkedIn: "linkedin",
  ON_DEMAND: "on-demand",
  AUTOMATED_MESSAGE: "automated_message",
  AUTOMATED_EMAIL: "automated_email"
};

export const fieldType = {
  URL: "URL",
  SINGLE_SELECT: "SINGLE-SELECT",
  MULTI_SELECT: "multi_select",
  FORM_INPUT: "form_input",
  RADIO_SELECT: "radio_select",
  FILTER_MULTI_SELECT: "filter_multi_select",
  AI_INSIGHTS: "ai_insights",
  TEXT_FORM: "text_form",
  CLICK_BOX_MULTI_SELECT: "CLICK-BOX-MULTI-SELECT",
  MULTI_DROP_DOWN_SINGLE_SELECT: "MULTI-DROP-DOWN-SINGLE-SELECT",
  URL: "URL",
  DROPDOWN: "single_select"
};

export const valueAccordingToFieldType = {
  URL: "URL",
  "SINGLE-SELECT": "",
  multi_select: [],
  form_input: "",
  radio_select: "",
  filter_multi_select: [],
  ai_insights: {},
  text_form: "",
  single_select: {}
};

export const generateKey = {
  VALUE_PROPS: "value_props",
  INSIGHTS: "insights",
  ADDITIONAL_DETAILS: "additional_details",
  INSTRUCTION: "instruction",
  EMAIL_CTA: "email_cta",
  MESSAGE_LENGTH: "message_length",
  VARIATIONS: "variations",
  TONALITY :"tone"
  // MESSAGE_LENGTH :""
};

export const onDemandOutcomes = {
  persona_challenges: "persona_challenges",
  case_study: "case_study",
  similar_customers: "similar_customers",
};

export const debounceTimerValue = 100;
