import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { MenuItem, Select } from "@mui/material";

const StyledTextField = styled(Select)(({ theme, hasValue }) => ({
  background: "#fff",
  borderRadius: "6px",
  marginBottom: "0 !important",
  width: "100%",
  border: "1px solid #D0C9D6",
  boxShadow: "0px 1px 9px -5px gray !important",
  "& .MuiInputLabel-root": {
    left: "-9px",
    top: "-15px",
    color: "black",
    fontSize: "16px",
  },
  "& .MuiFormHelperText-root": {
    fontSize: "0.6rem !important",
    marginLeft: "10px",
  },
  "& .MuiInputBase-input": {
    height: "auto",
    padding: "8px",
    fontSize: "13px !important",
    fontWeight: "normal",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    top: 0,
    border: "none",
    boxShadow: "0 7px 64px 0 rgba(0, 0, 0, 0.07)",
  },
  "& legend": {
    display: "none",
  },
  "& input::placeholder": {
    fontSize: "13px",
    color: theme.palette.light.gray,
  },
  "&.Mui-disabled": {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));

const menuProps = {
  PaperProps: {
    style: {
      boxShadow: "none",
      maxHeight: "265px",
      border: "none",
      maxWidth: "270px",
      overflowY: "scroll",
      boxShadow:
        " 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0,0,.1)",
    },
  },
};

function SingleSelectDark({
  id,
  label,
  value,
  onChange,
  showError,
  errorMsg,
  required,
  isValid,
  placeholder,
  variant,
  list,
  disabled,
  isDarkVariant,
}) {
  return (
    <StyledTextField
      select
      id={id}
      name={id}
      hiddenLabel={value ? true : false}
      variant={variant || "outlined"}
      InputLabelProps={!value ? {} : { shrink: true }}
      value={value}
      // label={label}
      onChange={onChange}
      required={required}
      error={required && !isValid && showError}
      helperText={required && !isValid && showError ? errorMsg : ""}
      disabled={disabled}
      MenuProps={menuProps}
      hasValue={Boolean(value)}
      isDarkVariant={isDarkVariant}
    >
      <MenuItem value="" sx={{ display: "none" }}>
        {placeholder}
      </MenuItem>
      {list &&
        list.map((option) => (
          <MenuItem
            selected={String(option.value) === String(value)}
            key={option.value}
            value={option.value}
            sx={{ fontSize: "13px", whiteSpace: "wrap" }}
          >
            {option.label}
          </MenuItem>
        ))}
    </StyledTextField>
  );
}

SingleSelectDark.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  errorMsg: PropTypes.string,
  required: PropTypes.bool,
  isValid: PropTypes.bool,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  list: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

export default SingleSelectDark;
