import { TextField, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { isValidUrl } from "../../../../utils/validations";

const LinkedinURLInput = ({
  url,
  label,
  handleParentDataChange,
  erroMsg,
  urlType,
}) => {
  const CustomLinkedinURLInput = styled(TextField)(({ theme }) => ({
    background: "#fff",
    borderRadius: "6px",
    width: "100%",
    border: "1px solid #D0C9D6",
    boxShadow: "0px 1px 9px -5px gray !important",
    marginTop: "5px",
    "& .Mui-focused": {
      color: "black",
    },
    "& .MuiInputLabel-root": {
      left: "-15px",
      top: "-15px",
      color: "black",
      fontSize: "15px",
      fontWeight: "500",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "0.6rem !important",
      marginLeft: "10px",
    },
    "& .MuiInputBase-input": {
      height: "auto",
      padding: "6px",
      fontSize: "12px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      top: 0,
      border: "none",
      boxShadow: "0 7px 64px 0 rgba(0, 0, 0, 0.07)",
    },
    "& input::placeholder": {
      fontSize: "12px",
      color: theme.palette.light.gray,
    },
    "&.Mui-disabled": {
      opacity: 0.5,
      pointerEvents: "none",
    },
  }));

  useEffect(() => {
    const handleLinkedinUrlChange = (event) => {
      setLocalUrl(event.data.url)
    };

    const urlListener = window.addEventListener(
      "message",
      handleLinkedinUrlChange
    );
    window.top.postMessage("getParentURL", "*");

    return () => {
      window.removeEventListener("message", handleLinkedinUrlChange);
    };
  }, []);

  const [localUrl, setLocalUrl] = useState();

  useEffect(() => {
    handleParentDataChange(localUrl);
  }, [localUrl]);

  const handleInputChange = (e) => {
    const newUrl = e.target.value;
    setLocalUrl(newUrl);
  };

  return (
    <>
      <div className="flex_title">
        <span
          className="title_label"
          style={{ fontSize: "14px", fontWeight: "500" }}
        >
          {label}
        </span>
      </div>
      <CustomLinkedinURLInput
        value={localUrl}
        onChange={handleInputChange}
        InputLabelProps={{
          shrink: true,
        }}
        autoFocus
      />
        <span className="error_msg">{erroMsg}</span>

    </>
  );
};

export default LinkedinURLInput;
