import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  overlayBg: {
    backgroundColor: "rgba(0,0,0,0.6)",
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: 9999999,
    left: 0,
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function OverlayLoader(props) {
  const classes = useStyles();
  return props.show ? (
    <div className={classes.overlayBg}>
      <CircularProgress />
    </div>
  ) : undefined;
}

OverlayLoader.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default OverlayLoader;
