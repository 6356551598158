import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../redux/customMessage";
import TextInput from "../../common/TextInput";

const Instructions = ({ messageParams, generate_key }) => {
  const formData = useSelector((state) => state.customMessageReducer.formData);
  const dispatch = useDispatch();

  const [inputvalue, setInputvalue] = useState("");

  const handleChange = (e) => {
    const newValue = e.target.value;
    const currentWords = inputvalue?.trim().split(/\s+/) || 0;
    const newWords = newValue.trim().split(/\s+/);
    const wordCount = newWords.length;
  
    if (messageParams.max_length) {
      if (wordCount <= messageParams.max_length) {
        setInputvalue(newValue);
      } else if (currentWords.length < messageParams.max_length) {
        const allowedWords = newWords.slice(0, messageParams.max_length);
        setInputvalue(allowedWords.join(' '));
      }
    } else {
      setInputvalue(newValue);
    }
  };
  

  const updateValueInRedux = () => {
    dispatch(updateFormData({ key: generate_key, value: inputvalue }));
  };

  useEffect(() => {
    const formValue = formData[generate_key];
    setInputvalue(formValue);
  }, []);
  return (
    <>
      {messageParams && (
        <TextInput
          multiline={true}
          label={messageParams.sub_title}
          placeholder={messageParams.placeholder}
          rows={12}
          required={messageParams.required}
          // maxLength={messageParams.max_length}
          // value={formData[generate_key] }
          value={inputvalue}
          onChange={handleChange}
          onBlur={updateValueInRedux}
        />
      )}
    </>
  );
};

export default memo(Instructions);
