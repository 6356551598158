import React, { useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import CheckboxComponent from "../common/CheckboxComponent";

function LinkRenderer(props) {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
}

const ContentItem = ({ title, content, checked, handleChange }) => {
  const [expandedItems, setExpandedItems] = useState({});

  const handleExpandClick = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
      <CheckboxComponent checked={checked} handleChange={handleChange}
      label={
        <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
        <strong>{title}</strong>
        {content.map((item, index) => (
          <div key={index}>
            {expandedItems[index] || item.length <= 80 ? (
              <ReactMarkdown
                children={item}
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                components={{ a: LinkRenderer }}
              />
            ) : (
              <ReactMarkdown
                children={`${item.slice(0, 80)}...`}
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                components={{ a: LinkRenderer }}
              />
            )}
            {item.length > 80 && (
              <Link
                component="button"
                onClick={() => handleExpandClick(index)}
                sx={{
                  ml: 1,
                  fontSize: "12px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                {expandedItems[index] ? "View less" : "View more"}
              </Link>
            )}
          </div>
        ))}
      </Typography>
      } />

    </Box>
  );
};

export default ContentItem;
