import { useDispatch, useSelector } from "react-redux";
import MultiDropdownMultiSelect from "../common/DynamicComponents/MultiDropdownMultiSelect";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import {
  updateProcessingState,
  updateStep2Data,
  updateValidationResult,
} from "../../redux/onDemand";

const OnDemandStep2 = ({ fields, submitOnAllValid }) => {
  const dispatch = useDispatch();
  const { step2Data, step1Data } = useSelector(
    (state) => state.onDemandReducer
  );
  const { currentStep, isValid, isProcessing, validationResult } = useSelector(
    (state) => state.onDemandReducer.steps
  );

  const [stepData, setStepData] = useState([]);

  const handleParentDataChange = (updatedValues) => {
    const newData = stepData.map((field) => {
      const newField = { ...field };

      if (updatedValues[field.key]) {
        newField.values = updatedValues[field.key]
      }

      if (field.child && updatedValues[field.child.key]) {
        newField.child = {
          ...field.child,
          values: updatedValues[field.child.key],
        };
      }

      return newField;
    });

    dispatch(updateStep2Data(newData));
    setStepData(newData);
  };

  useEffect(() => {
    if (currentStep === 1) {
      const priorityArray = [];

      const relevanceLogicSelection = step1Data.find((element) => {
        return element.name === "Relevance logic selection";
      });

      if (relevanceLogicSelection && relevanceLogicSelection.values) {
        for (
          let i = 0;
          i < relevanceLogicSelection.selection_name.length;
          i++
        ) {
          const priorityKey = relevanceLogicSelection.selection_name[i];
          if (relevanceLogicSelection.values[priorityKey]) {
            priorityArray.push(relevanceLogicSelection.values[priorityKey]);
          }
        }
      }

      dispatch(updateStep2Data(priorityArray));
      setStepData(priorityArray);
    }
  }, [currentStep, step1Data, dispatch]);

  const getValidationResult = () => {
    const validationResult = {};
    let isValid = false;

    if (stepData && Array.isArray(stepData)) {
      isValid = stepData.some(
        (item) => Array.isArray(item.values) && item.values.length > 0
      );
    }

    validationResult.isValid = isValid;
    validationResult.errorMsg = isValid ? "" : "Please select at least one value";
    return validationResult;
  };

  useEffect(() => {
    const processStepData = () => {
      if (isProcessing && currentStep === 1) {
        dispatch(updateStep2Data(stepData));

        const validationResult = getValidationResult();
        const isFormValid = validationResult.isValid;

        dispatch(updateValidationResult({ validationResult, isFormValid }));

        if (isFormValid) {
          submitOnAllValid();
        }

        dispatch(updateProcessingState(false));
      }
    };

    processStepData();
  }, [isProcessing, isValid, dispatch, currentStep, stepData, submitOnAllValid]);

  return (
    <>
      <Grid xs={12} container sx={{ gap: "20px", alignContent: "flex-start" }}>
        <MultiDropdownMultiSelect
          itemsList={stepData || []}
          title={fields.title}
          erroMsg={validationResult.errorMsg}
          handleParentDataChange={handleParentDataChange}
          values={stepData.reduce((acc, item) => {
            acc[item.key] = item.values || [];
            if (item.child) {
              acc[item.child.key] = item.child.values || "";
            }
            return acc;
          }, {})}
        />
      </Grid>
    </>
  );
};

export default OnDemandStep2;
