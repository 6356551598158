import { Step, StepLabel, Stepper, styled } from "@mui/material";

const StepperHeaderComponent = ({ steps, activeStep }) => {
  const StepperHeader = styled(Stepper)(({ theme }) => ({
    width: "100%",
    "& .MuiStepLabel-alternativeLabel": {
      fontSize: "12px",
      marginTop: ".1rem!important",
    },
    "&": {
      borderBottom: "2px solid #EAEEFF",
      padding: ".4rem",
    },
    "& .MuiStep-root": {
      flexGrow: "2",
    },
    "& .MuiStepLabel-labelContainer": {
      width: "max-content",
    },
    "& .MuiStepLabel-root": {
      justifyContent: "center",
    },
    "& .MuiStep-horizontal.Mui-active": {
      borderBottom: `2px solid ${theme.palette.light.primary}`,
    },
    "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line, & .MuiStepConnector-root.Mui-completed .MuiStepConnector-line":
      {
        border: `1px solid ${theme.palette.light.primary}`,
      },
    "& .MuiStepConnector-root.Mui-disabled .MuiStepConnector-line, .MuiStepConnector-line":
      {
        border: `1px solid ${theme.palette.dark.grey}`,
      },
    // "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line, & .MuiStepConnector-root.Mui-completed .MuiStepConnector-line":
    //   {
    //     borderColor: theme.palette.light.primary,
    //     display: "block",
    //     margin: "auto",
    //     width: "8px",
    //     height: "8px",
    //     borderTop: `2px solid ${theme.palette.light.primary}`,
    //     borderLeft: `2px solid ${theme.palette.light.primary}`,
    //     transform: "rotate(135deg)",
    //     flexGrow: ".4",
    //   },
    // "& .MuiStepConnector-root.Mui-disabled .MuiStepConnector-line, .MuiStepConnector-line":
    //   {
    //     borderColor: theme.palette.light.primary,
    //     display: "block",
    //     margin: "auto",
    //     width: "8px",
    //     height: "8px",
    //     borderTop: `2px solid ${theme.palette.dark.grey}`,
    //     borderLeft: `2px solid ${theme.palette.dark.grey}`,
    //     transform: "rotate(135deg)",
    //     flexGrow: ".4",
    //   },
  }));
  return (
    <StepperHeader
      alternativeLabel
      activeStep={activeStep}
      className="stepper-header"
    >
      {steps.map((label, index) => (
        <Step key={index} alternativeLabel>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </StepperHeader>
  );
};

export default StepperHeaderComponent;
