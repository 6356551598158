import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "&": {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.light.primary}`,
    marginBottom: "5px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
    "&::after": {
      borderBottom: `2px solid ${theme.palette.light.primary}`,
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: `2px solid ${theme.palette.light.primary.main}`,
    },
  },
  "& .MuiInputBase-input": {
    padding: "5px",
    fontSize: "14px",
  },
  "& .MuiInputBase-root": {
    paddingLeft: "6px",
  },
  "& .MuiInputAdornment-root": {
    marginRight: "8px",
  },
  "& .MuiFormLabel-root": {
    fontSize: "16px",
    paddingLeft: "5px",
  },
}));

function CustomInput({
  id,
  name,
  placeholder,
  value,
  onChange,
  required,
  isValid,
  showError,
  errorMsg,
  icon,
}) {
  return (
    <CustomTextField
      id={id}
      name={name}
      placeholder={placeholder}
      InputProps={{
        startAdornment: icon ? (
          <InputAdornment position="start" sx={{ paddingLeft: "2px" }}>
            <img src={icon} alt={`${name} icon`} />
          </InputAdornment>
        ) : null,
      }}
      value={value}
      onChange={onChange}
      error={required && !isValid && showError}
      helperText={required && !isValid && showError ? errorMsg : ""}
    />
  );
}

CustomInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default CustomInput;
