import { Alert } from "@mui/material";

const ErrorAlert = ({errorMsg}) => {
  return (
    <Alert
      sx={{
        width: "100%",
        padding: "0",
        borderRadius: "20px",
        textAlign: "center",
        justifyContent: "center",
      }}
      severity="error"
      variant="outlined"
    >
      {errorMsg}
    </Alert>
  );
};

export default ErrorAlert