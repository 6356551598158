import { AccordionSummary, Box, styled } from "@mui/material";
import ExpandMoreIcon from "../../../assets/expandAccordion.svg";
import CollapseIcon from "../../../assets/collapseAccordion.svg";

const DynamicAccordionSummary = ({ children }) => {
  const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    borderRadius: "4px",
    backgroundColor: `${theme.palette.light.purpleLight}`,
  }));

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        <div className="collapsIconWrapper">
          <img src={ExpandMoreIcon} alt="expand" />
        </div>
        <div
          className="expandIconWrapper"
          style={{ transform: "rotate(180deg)" }}
        >
          <img src={CollapseIcon} alt="collapse" />
        </div>
      </Box>
    );
  };

  return (
    <CustomAccordionSummary
      expandIcon={<CustomExpandIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      {children}
    </CustomAccordionSummary>
  );
};

export default DynamicAccordionSummary;
