import { questionTypes } from "./constants"

export const formatTagtype = (tag)=>{
    if(tag.includes("company")){ 
        return questionTypes.COMPANY_INSIGHTS
    }else if(tag.includes("people")){
        return questionTypes.PERSONA_INSIGHTS
    }
}

export function flatten(obj) {
    const result = {};
    for (const key of Object.keys(obj)) {
      if (typeof obj[key] === 'object') {
        const nested = flatten(obj[key]);
        for (const nestedKey of Object.keys(nested)) {
          result[`${key}.${nestedKey}`] = nested[nestedKey];
        }
      } else {
        result[key] = obj[key];
      }
    }
    return result;
  }