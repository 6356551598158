import { Grid } from "@mui/material";
import "../../css/LoaderSkeleton.css";

const LoaderSkeleton = ({})=>{
    return(
       <Grid sx={12}>
        <div className="skeleton-placeholder"style={{width: "90%"}}>
          <em>Data Loading...</em>
        </div>
        <div className="skeleton-placeholder" style={{width: "50%"}}/>
      </Grid>
    )
}

export default LoaderSkeleton