import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";

const theme = createTheme({
  spacing: 5,
  palette: {
    primary: { main: "#6979f8", danger: "#e54040", backDrop : "#1F1651" },
    light: {
      primary: "#6979f8",
      primary100: "#f2f3ff",
      primary200 : "#C2D3FF",
      secondary: "#A5AFFB",
      white: "#fff",
      black: "#1a051d",
      blackLight: "#3f3356",
      grey: "#6b6c6f",
      placeholder: "#d0c9d6",
      lightGrey: "#9ca5b4",
      bgGrey: "#e4e8f0",
      backgroundGrey: "#f4f6fc",
      purpleLight: "#eaeeff",
      borderGrey: "#e5e7fa",
      fontGreyLight: "#8798ad",
      greenLight: "#ade610",
      redLight: "#fc7070",
      greenMedium: "#10e6e3",
      primaryFade: "#697ae3",
      disableColor: "#CACFFA",
      borderColor: "#C9CFFC",
    },
    messageBtns: {
      primaryStroke: "#C7D7FE",
      primaryBackground: "#EEF4FF",
      primaryText: "#3538CD",
      secondaryStroke: "#D9D6FE",
      secondaryBackground: "#F4F3FF",
      secondaryText: "#5925DC",
      tertiaryStroke: "#E9D7FE",
      tertiaryBackground: "#F9F5FF",
      tertiaryText: "#6941C6"
    },
    basic: {
      black: "#344054",
      white: "#fff",
      grey100: "#F9FAFB",
      grey200: "#EAECF0"
    },
    dark: { primary: "#6979f8", white: "#fff", black: "#000", grey: "#d0c9d6" },
  },
  
  overrides: {
    
  },
});

const PlThemeProvider = (props) => <ThemeProvider theme={theme} {...props} />;

export default PlThemeProvider;
