import { configureStore } from "@reduxjs/toolkit";
import questionSlice from "./questionSlice";
import  insightSlice  from "./insights";
import customMessageSlice from "./customMessage";
import onDemandSlice from "./onDemand"

export const store = configureStore({
  reducer: {
    questionReducer: questionSlice ,
    insightReducer: insightSlice,
    customMessageReducer: customMessageSlice,
    onDemandReducer: onDemandSlice
  },
});
