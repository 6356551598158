import userIcon from "../assets/user_icon.svg";
import botIcon from "../assets/icons/logo_small.svg";
import likeIco from "../assets/chat_like.svg";
import disLikeIco from "../assets/chat_dislike.svg";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import AccordianComponent from "../components/Accordian";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { questionTypes } from "../utils/constants";
import RenderUserInputCustomMessage from "./EmailBuilderStepper/RenderUserInput";
import RenderOnDemandResponse from "./RenderOndemandResponse";
import CustomMessageLoader from "./common/CustomMessageLoader.js/CustomMessageLoader";
import MessageTemplateTabs from "./common/MessageTemplateTabs/MessageTemplateTabs";

const AccordianContainer = ({
  messages,
  showLoader,
  showQuestions,
  openFeedback,
  questions,
  handleDialogOpen,
  loadingMessage,
  showCustomMessageLoader,
}) => {
  const divRef = useRef(null);
  const scrollToBottom = () => {
    divRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const useStyles = makeStyles((theme) => ({
    userAction: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "& img": {
        cursor: "pointer",
        marginLeft: "12px",
      },
    },
  }));
  const classes = useStyles();
  const [expandedPerson, setExpandedPerson] = useState(true);
  const [expandedCompany, setExpandedCompany] = useState(true);
  const [expandedPersonAndCompany, setExpandedPersonAndCompany] =
    useState(true);

  const handleExpansionPerson = () => {
    setExpandedPerson((prevExpanded) => !prevExpanded);
  };
  const handleExpansionCompany = () => {
    setExpandedCompany((prevExpanded) => !prevExpanded);
  };
  const handleExpansionPersonAndCompany = () => {
    setExpandedPersonAndCompany((prevExpanded) => !prevExpanded);
  };

  useEffect(() => {
    scrollToBottom();
  });  

  return (
    <div>
      <div className="chat-interface">
        <div className="messages">
          {messages &&
            Array.isArray(messages) &&
            messages.map((msg, index) => {
              return (
                msg && (
                  <div key={index} className={`message ${msg.sender}`}>
                    {msg.sender === "user" ? (
                      <>
                        {/* {(msg.type && ( msg.type === questionTypes.ON_DEMAND_INSIGHTS )) ? (
                        <RenderUserInputCustomMessage userMessage={msg}/>
                        ) : ( */}
                          <span className="message-user">{msg.text}</span>
                        {/* )} */}

                        {/* {msg.data && (
                        <span className="message-data">
                          <ReactMarkdown
                            children={msg.data}
                            rehypePlugins={[rehypeRaw]}
                            remarkPlugins={[remarkGfm]}
                            className={styles.reactMarkDown}
                          />
                        </span>
                      )} */}
                        {/* <img
                          src={userIcon}
                          alt="User"
                          className="message-icon"
                        /> */}
                      </>
                    ) : (
                      <>
                        {
                          <>
                            {" "}
                            <span style={{ marginBottom: "auto" }}>
                              <img
                                src={botIcon}
                                alt="Bot"
                                className="message-icon"
                              />
                              <span style={{ position: "absolute" }}>
                              Please find the information below
                              </span>
                            </span>
                            {msg.type === questionTypes.ON_DEMAND_INSIGHTS ? (
                              <span className="message-text bot_message">
                                {/* {msg.data && (
                                  <p>Please find the information below</p>
                                )} */}
                              <RenderOnDemandResponse messages={msg} />
                              {showQuestions && !showLoader && (
                                  <div className={classes.userAction}>
                                    <div>Did you find this helpful?</div>
                                    <div>
                                      <img
                                        src={likeIco}
                                        onClick={(e) =>
                                          openFeedback(e, "like", msg.h_id)
                                        }
                                      />
                                      <img
                                        src={disLikeIco}
                                        onClick={(e) =>
                                          openFeedback(e, "dislike", msg.h_id)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </span>
                            ) : (
                              <span className="message-text bot_message">
                                {/* {msg.data && (
                                  <p>Please find the information below</p>
                                )} */}
                                {msg.data ? (
                                  msg.type &&
                                  msg.type ===
                                    questionTypes.PERSONA_COMPANY_INSIGHTS ? (
                                    <>
                                      <Accordion
                                        expanded={expandedPersonAndCompany}
                                        onChange={
                                          handleExpansionPersonAndCompany
                                        }
                                        style={{ margin: "2rem 0" }}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1-content"
                                          id="panel1-header"
                                          className="accordion-header-summary"
                                        >
                                          Persona & Company Insights
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          {msg.data.filter(
                                            (item) =>
                                              item.type === "persona_insights"
                                          ).length > 0 && (
                                            <Accordion
                                              expanded={expandedPerson}
                                              onChange={handleExpansionPerson}
                                              style={{ margin: "2rem 0" }}
                                            >
                                              <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                className="accordion-summary"
                                              >
                                                Persona Insights
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                <AccordianComponent
                                                  faqData={msg.data.filter(
                                                    (item) =>
                                                      item.type ===
                                                      "persona_insights"
                                                  )}
                                                />
                                              </AccordionDetails>
                                            </Accordion>
                                          )}
                                          {msg.data.filter(
                                            (item) =>
                                              item.type === "company_insights"
                                          ).length > 0 && (
                                            <Accordion
                                              expanded={expandedCompany}
                                              onChange={handleExpansionCompany}
                                              style={{ margin: "2rem 0" }}
                                            >
                                              <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                className="accordion-summary"
                                              >
                                                Company Insights
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                <AccordianComponent
                                                  faqData={msg.data.filter(
                                                    (item) =>
                                                      item.type ===
                                                      "company_insights"
                                                  )}
                                                />
                                              </AccordionDetails>
                                            </Accordion>
                                          )}
                                        </AccordionDetails>
                                      </Accordion>
                                    </>
                                  ) : (
                                    msg.type === questionTypes.EMAIL || msg.type === questionTypes.LINKEDIN ? <>
                                    <MessageTemplateTabs messages={msg}/>
                                    </>
                                    :
                                    <AccordianComponent faqData={msg.data} />
                                  )
                                ) : (
                                  <p>
                                    Couldn't generate insights please try again
                                  </p>
                                )}
                                {showQuestions && !showLoader && (
                                  <div className={classes.userAction}>
                                    <div>Did you find this helpful?</div>
                                    <div>
                                      <img
                                        src={likeIco}
                                        onClick={(e) =>
                                          openFeedback(e, "like", msg.h_id)
                                        }
                                      />
                                      <img
                                        src={disLikeIco}
                                        onClick={(e) =>
                                          openFeedback(e, "dislike", msg.h_id)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </span>
                            )}
                          </>
                        }
                      </>
                    )}
                  </div>
                )
              );
            })}

          {showLoader && loadingMessage !== "" && (
            <div className="loading-message">{loadingMessage}</div>
          )}

          {showCustomMessageLoader && <CustomMessageLoader />}
        </div>
        {/* {!showLoader && showQuestions && (
          <div className="chat-interface" ref={divRef}>
            <div className="messages newUser">
              <div className={`message bot`}>
                <span style={{ marginBottom: "auto" }}>
                  <img src={botIcon} alt="Bot" className="message-icon" />
                  <span style={{ position: "absolute" }}>Pintel Assistant</span>
                </span>
                <span className="message-text bot_message">
                  <div style={{ margin: "10px 0 10px 0" }}>
                    How can we help?
                  </div>
                  {questions.length > 0 &&
                    questions?.map(
                      (question, index) =>
                        question.is_active && (
                          <button
                            className="chat_options"
                            key={index}
                            onClick={() =>
                              handleDialogOpen(question, question.outcome)
                            }
                          >
                            {question.outcome}
                          </button>
                        )
                    )}
                </span>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};
export default AccordianContainer;
