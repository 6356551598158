import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { MenuItem, Select } from "@mui/material";

const StyledTextField = styled(Select)(({ theme, hasValue, width}) => ({
  background: "#fff",   
  borderRadius: "6px",
  marginTop: "20px!important",
  marginBottom: "0 !important",
  width: `${width ? width : "100%"}`,
  boxShadow: "none",
  border: `1px solid ${hasValue ? theme.palette.primary.main : theme.palette.light.borderColor}`,
  "& .MuiInputLabel-root": {
    left: "-9px",
    top: "-15px",
    color: "black",
    fontSize: "16px",
  },
  "& .MuiFormHelperText-root": {
    fontSize: "0.6rem !important",
    marginLeft: "10px",
  },
  "& .MuiInputBase-input": {
    height: "auto",
    padding: "8px",
    fontSize: "13px !important",
    fontWeight: hasValue ? "bold" : "normal",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    top: 0,
    border: "none",
    boxShadow: "0 7px 64px 0 rgba(0, 0, 0, 0.07)",
  },
  "& legend": {
    display: "none",
  },
  "& input::placeholder": {
    fontSize: "13px",
    color: theme.palette.light.gray,
  },
  "&.Mui-disabled": {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));



function SingleSelect({
  id,
  label,
  value,
  onChange,
  showError,
  errorMsg,
  required,
  isValid,
  placeholder,
  variant,
  list,
  disabled,
  defaultValue,
  isDarkVariant,
  width
}) {
  const menuProps = {
    PaperProps: {
      style: {
        boxShadow: "none",
        border: `1px solid #C9CFFC`,
        maxHeight: "265px",
         maxWidth: `${width?  width: "100%"}`
      },
    },
  };
  
  return (
    <StyledTextField
      select
      id={id}
      name={id}
      label={!value ? label : null}
      hiddenLabel={value ? true : false}
      variant={variant || "outlined"}
      InputLabelProps={!value ? {} : { shrink: true }}
      value={value}
      onChange={onChange}
      required={required}
      error={required && !isValid && showError}
      helperText={required && !isValid && showError ? errorMsg : ""}
      disabled={disabled}
      MenuProps={menuProps}
      displayEmpty={true}
      hasValue={Boolean(value)} 
      defaultValue={defaultValue}
      isDarkVariant={isDarkVariant}
    >
      <MenuItem value="" sx={{display: "none",   textWrap: "wrap",}}>
        {placeholder}
      </MenuItem>
      {list && list.map((option) => (
        <MenuItem key={option.value} value={option.value} sx={{fontSize: "13px",   textWrap: "wrap",}}>
          {option.label}
        </MenuItem>
      ))}
    </StyledTextField>
  );
}

SingleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  errorMsg: PropTypes.string,
  required: PropTypes.bool,
  isValid: PropTypes.bool,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  list: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.any
};

export default SingleSelect;
