import React from 'react';
import { Box, Typography } from '@mui/material';

const SelectionIndicator = ({ placeholder, number }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <Typography variant="body2" sx={{ fontSize: '13px', fontWeight: "bold" }}>
        {placeholder}
      </Typography>
      <Box
        sx={{
          backgroundColor: '#6979F8',
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          color: '#fff',
          ml: 1, 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '13px', 
        }}
      >
        {number}
      </Box>
    </Box>
  );
};

export default SelectionIndicator;
