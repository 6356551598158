import { Grid } from "@mui/material";
import LinkedinURLInput from "../common/DynamicComponents/LinkedinURLInput";
import ClickBoxMultiSelect from "../common/DynamicComponents/ClickBoxMultiSelect";
import MultiDropDownSingleSelect from "../common/DynamicComponents/MultiDropDownSingleSelect";
import { fieldType, linkedinurlTypes } from "../../utils/constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrentStep,
  updateProcessingState,
  updateStep1Data,
  updateValidationResult,
  updateOnDemandFormdata,
} from "../../redux/onDemand";
import { isEmptyValue, isValidUrl } from "../../utils/validations";
import { extractLinkedInUID } from "../../services/extractServices";

const OnDemandStep1 = ({ fields }) => {
  const dispatch = useDispatch();

  const { step1Data, formData } = useSelector((state) => state.onDemandReducer);

  const { isProcessing, currentStep, isValid, validationResult } = useSelector(
    (state) => state.onDemandReducer.steps
  );

  const { currentUrl } = useSelector((state) => state.insightReducer);

  const [stepData, setStepData] = useState(step1Data);

  const handleParentDataChange = (item) => (value) => {
    setStepData((prevState) => {
      return prevState.map((field) =>
        field.name === item.name ? { ...field, values: value } : field
      );
    });
    if (item.name === "Person Linkedin URL") {
      dispatch(updateOnDemandFormdata({ key: "person_linkedin_url", value }));
    }
    if (item.name === "Insights you want") {
      dispatch(updateOnDemandFormdata({ key: "outcomes", value }));
    }
  };

  const getValidationResult = () => {
    let usedValues = new Set();
    return stepData.reduce((result, item) => {
      let errorMsg = "";
      let isValid = true;
      if (item.reqired) {
        if (item.type === fieldType.CLICK_BOX_MULTI_SELECT) {
          if (isEmptyValue(item.values)) {
            isValid = false;
            errorMsg = "Please select a value";
          }
        } else if (item.type === fieldType.MULTI_DROP_DOWN_SINGLE_SELECT) {
          if (isEmptyValue(item.values)) {
            isValid = false;
            errorMsg = "Please select a value";
          } else {
            const valuesArray = Object.values(item.values);
            for (let value of valuesArray) {
              const uniqueIdentifier =
                typeof value === "object" ? value?.key : value;
              if (usedValues.has(uniqueIdentifier)) {
                isValid = false;
                errorMsg = "Please select unique values";
                break;
              }
              usedValues.add(uniqueIdentifier);
            }
          }
        } else if (item.type === fieldType.URL) {
          if (!isValidUrl(item.values)) {
            isValid = false;
            errorMsg = "Invalid URL";
          }
        } else {
          if (isEmptyValue(item.values)) {
            isValid = false;
            errorMsg = "Please enter a valid url";
          }
        }
      }
      result[item.name] = { isValid, errorMsg };
      return result;
    }, {});
  };

  useEffect(() => {
    const processStepData = async () => {
      if (isProcessing && currentStep === 0) {
        dispatch(updateStep1Data(stepData));

        const validationResult = getValidationResult();
        const isFormValid = Object.values(validationResult).every(
          (validation) => validation.isValid
        );

        dispatch(updateValidationResult({ validationResult, isFormValid }));

        if (isFormValid) {
          extractLinkedInUID("people", formData.person_linkedin_url).then(
            (res) => {
              dispatch(
                updateOnDemandFormdata({ key: "person_uuid", value: res })
              );
            }
          );
          dispatch(updateCurrentStep(1));
        }

        dispatch(updateProcessingState(false));
      }
    };

    processStepData();
  }, [isProcessing, isValid]);

  useEffect(() => {
    setStepData((prevState) => {
      return prevState.map((field) =>
        field.name === "Person Linkedin URL"
          ? { ...field, values: field.value || currentUrl }
          : field
      );
    });
  }, [currentUrl]);

  const renderContent = (item) => {
    if (item.type === fieldType.URL) {
      return (
        <Grid xs={12} item>
          <LinkedinURLInput
            urlType={linkedinurlTypes.PROFILE}
            label={item.name}
            handleParentDataChange={handleParentDataChange(item)}
            erroMsg={validationResult[item.name]?.errorMsg}
            url={item.values}
          />
        </Grid>
      );
    }
    if (item.type === fieldType.CLICK_BOX_MULTI_SELECT) {
      return (
        <Grid xs={12} item>
          <ClickBoxMultiSelect
            dataList={item.option}
            // checkedValues={dummyCheckedValues}
            title={item.name}
            handleParentDataChange={handleParentDataChange(item)}
            erroMsg={validationResult[item.name]?.errorMsg}
            values={item?.values || []}
          />
        </Grid>
      );
    }
    if (item.type === fieldType.MULTI_DROP_DOWN_SINGLE_SELECT) {
      return (
        <Grid xs={12} item>
          <MultiDropDownSingleSelect
            items={item.selection_name}
            dropdownOptions={item.selection_options}
            title={item.name}
            handleParentDataChange={handleParentDataChange(item)}
            erroMsg={validationResult[item.name]?.errorMsg}
            values={item?.values || {}}
          />
        </Grid>
      );
    }
  };

  return (
    <Grid xs={12} container sx={{ gap: "20px" }}>
      {stepData.map((item) => renderContent(item))}
    </Grid>
  );
};

export default OnDemandStep1;
