import { Box, Dialog, Grid, IconButton } from "@mui/material";
import "../../css/StepperComponent.css";
import StepperHeaderComponent from "../common/StepperHeaderComponent";
import PrimaryButton from "../common/Buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import PrioritySelectionScreen from "./OnDemandStep1";
import OnDemandStep2 from "./OnDemandStep2";
import {
  updateCurrentStep,
  updateProcessingState,
} from "../../redux/onDemand";
import {
  addMessageToCurrentList,
  resetCurrentChatList,
  updateIsFetchingInsights,
  updateNewChatActiveStatus,
} from "../../redux/insights";
import { getOnDemandInsights } from "../../services/onDemandInsights";
import { onDemandOutcomes } from "../../utils/constants";
import ClearIcon from "@mui/icons-material/Clear";


const OnDemandInsights = ({
  open,
  setOpen,
  setShowloader,
  setShowQuestions,
  setLoadingMessage,
}) => {
  const dispatch = useDispatch();

  const { currentQuestion } = useSelector((state) => state.insightReducer);
  const { currentStep, isProcessing, isValid } = useSelector(
    (state) => state.onDemandReducer.steps
  );
  const { formData, step1Data, step2Data } = useSelector(
    (state) => state.onDemandReducer
  );
  const handleClose = () => {
    setOpen(false);
    dispatch(updateCurrentStep(0));
  };

  const handleClickFinish = async () => {
    dispatch(updateProcessingState(true));
  };

  const submitOnAllValid = async () => {
    fetchInsightsData();
    handleClose();
  };

  const initializeState = () => {
    dispatch(updateIsFetchingInsights(true));
    localStorage.setItem("isFetching", true);
    localStorage.setItem("isActiveUser", true);
    dispatch(resetCurrentChatList());
    setShowQuestions(false);
    setShowloader(true);
    setLoadingMessage("Fetching details, just a moment...");
  };

  const assignPriorities = (data) => {
    const tempPriority = [];
  
    data.forEach((itm) => {
      let tp = {
        priority: tempPriority.length,
        name: itm.name,
        key: itm.key,
        values: itm.values?.map((el) => el.data) || [],
      };
  
      if (tp.values.length > 0) tempPriority.push(tp);
  
      if (itm.child && itm.child.values) {
        let childTp = {
          priority: tempPriority.length,
          name: itm.child.name,
          key: itm.child.key,
          values: itm.child.values instanceof Array ? itm.child.values : [itm.child.values],
        };
  
        if (childTp.values.length > 0) tempPriority.push(childTp);
      }
    });
  
    return tempPriority;
  };

  const fetchInsightsData = async () => {
    initializeState();

    const messageObject = {
      "Person linkedin Url": formData.person_linkedin_url,
    };

    dispatch(
      addMessageToCurrentList({
        data: messageObject,
        sender: "user",
        type: "customMessage",
        text: "Generate Insights"
      })
    );

    try {
      const tempPriority = assignPriorities(step2Data);

      const insightsParams = {
        person_linkedin_url: formData.person_linkedin_url,
        person_uuid: formData.person_uuid,
        outcomes: formData.outcomes,
        priorities: tempPriority,
      };

      const data = await getOnDemandInsights(insightsParams);

      let messageData;
      if (formData.outcomes.includes(onDemandOutcomes.persona_challenges)) {
        messageData =
          data.response.persona_challenges?.map((item, index) => {
            return {
              question: item.challenge,
              answer: [item.solution],
            };
          }) || [];

      }

      if (formData.outcomes.includes(onDemandOutcomes.case_study)) {
        messageData =
          data.response.case_study?.map((item, index) => {
            return {
              question: item.url,
              answer: [item.case_study],
            };
          }) || [];

     
      }

      if (formData.outcomes.includes(onDemandOutcomes.similar_customers)) {
        messageData =
          data.response.similar_customers?.map((item, index) => {
            return {
              question: item.customer_name,
              answer: [
                item.customer_applications,
                item.customer_geography,
                item.customer_industry,
              ],
            };
          }) || [];

     
      }

      dispatch(
        addMessageToCurrentList({
          text: "On demand insights",
          sender: "bot",
          data: data.response,
          type: currentQuestion.key,
        })
      );

    } catch (error) {
      console.log(error, "error in generating messages");
      dispatch(
        addMessageToCurrentList({
          text: "Couldn't generate prompt",
          sender: "bot",
        })
      );
    } finally {
      dispatch(updateIsFetchingInsights(false));
      setShowQuestions(true);
      setShowloader(false);
      setLoadingMessage("");
      dispatch(updateNewChatActiveStatus(false));
      localStorage.setItem("isFetching", false);
    }
  };

  const handleNext = () => {
    dispatch(updateProcessingState(true));
  };

  const handleBack = () => {
    dispatch(updateCurrentStep(currentStep - 1));
  };

  const steps = ["Step 1", "Step 2"];

  return (
    <>
   
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: { backgroundColor: "#1F1651", opacity: ".8" },
      }}
      className="stepper-dialog"
      sx={{
        "& .MuiDialog-paper": {
          margin: "10px 10px 10px 10px",
          width: "100%",
          height: "100%",
          position: "relative",
          background: "transparent",
          overflowY: "hidden",
          display: "flex",
          alignItems: "flex-end",
        },
      }}
     
    >
       <div className="cross_btn_container">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              background: "transparent",
              zIndex: "1200",
              color: "white",
              marginLeft: "auto"
            }}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <div className="dialog_content_container">
      <StepperHeaderComponent activeStep={currentStep} steps={steps} />
      <div className="stepper-box">
        {currentStep === 0 && <PrioritySelectionScreen />}
        {currentStep === 1 && (
          <OnDemandStep2
            fields={currentQuestion.screens[0]}
            submitOnAllValid={submitOnAllValid}
          />
        )}

     
      </div>
      <Box   sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 2,
                gap: "10px",
                padding: "10px"
              }}>
      <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
            gap: "10px",
          }}
        >
          {currentStep !== 0 && (
            <PrimaryButton disabled={currentStep === 0} onClick={handleBack}>
              BACK
            </PrimaryButton>
          )}
          <PrimaryButton
            loading={isProcessing}
            onClick={
              currentStep === steps.length - 1 ? handleClickFinish : handleNext
            }
          >
            {currentStep === steps.length - 1 ? "SUBMIT" : "NEXT"}
          </PrimaryButton>
        </Grid>
        </Box>
      </div>
    </Dialog>
    </>
  );
};


export default OnDemandInsights;
